const data = [
    {
        name: "校管理员",
    },
    {
        name: "校负责人",
    },
    {
        name: "院管理员",
    },
    {
        name: "院负责人",
    },
    {
        name: "系管理员",
    },
    {
        name: "系负责人",
    },
    {
        name: "实验室管理员",
    },
    {
        name: "实验室负责人",
    },
];
export default data;
