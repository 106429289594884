<template>
  <el-card>
    <el-tabs v-model="activeName">
      <el-tab-pane label="角色列表" name="1">
        <el-table
          :data="tableData"
          border
          row-key="index"
          default-expand-all
          :tree-props="{ children: 'chr' }"
        >
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="操作" align="center" v-if="$checkArr('sys:role:oper')">
            <template slot-scope="scope">
              <el-button type="warning" size="mini" @click="modify(scope.row)"
                >修改</el-button
              >
              <el-button type="danger" size="mini" @click="remove(scope.row)"
                >删除</el-button
              >
              <!-- <el-button type="danger" size="mini" @click="remove(scope.row, true)"
                >物理删除</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          class="mt-15"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page.index"
          :page-sizes="page.sizes"
          :page-size.sync="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('sys:role:oper')">
        <el-col :span="12">
          <el-form
            ref="form"
            :model="formData"
            :rules="{
              name: [{ required: true, message: '该项目不能为空', trigger: 'blur' }],
              dataScope: [{ required: true, message: '该项目不能为空', trigger: 'blur' }],
            }"
            label-position="right"
            label-width="150px"
          >
            <el-form-item label="名称：" prop="name">
              <el-input v-model="formData.name"></el-input>
            </el-form-item>
            <el-form-item label="权限等级：" prop="dataScope">
              <el-select v-model="formData.dataScope" placeholder="" style="width: 100%">
                <el-option
                  v-for="(v, k) in dataScopeArr"
                  :key="'权限等级' + k"
                  :label="v.label"
                  :value="v.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="角色授权：">
              <div class="menuBox">
                <el-tree
                  ref="tree"
                  :key="treeKey"
                  :data="treeData"
                  :props="defaultProps"
                  :default-expanded-keys="treeExpandIds"
                  :default-checked-keys="treeCheckedIds"
                  node-key="id"
                  show-checkbox
                  @check="check"
                ></el-tree>
              </div>
            </el-form-item>
            <el-form-item label="" label-width="90px">
              <el-button
                type="primary"
                @click="
                  $refs.form
                    .validate()
                    .then(() => {
                      save();
                    })
                    .catch(() => {
                      $wMsg('请填写完整信息');
                    })
                "
                >保存</el-button
              >
              <el-button type="info" @click="back()">返回</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import {data} from "../../../data/home";
import roleData from "../../../data/role";

export default {
  name: "roleSettings",
  mounted() {
    if (this.$checkArr("sys:role:view")) {
      this.roleGageList();
      this.menuGetMenuTree();
      this.dictList();
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      tableData: roleData,
      treeKey: 1,
      treeExpandIds: [],
      treeCheckedIds: [],
      formTitle: "添加角色",
      formData: {},
      dataScopeArr: [], // 角色权限等级数组
      treeData: data,
      defaultProps: {
        children: "children",
        label: "name",
      },
      page: {
        index: 1,
        sizes: [10, 20, 30, 40],
        size: 10,
        total: 40,
      },
    };
  },
  methods: {
    // 重置form
    reData() {
      this.formData = {};
      this.treeExpandIds = [];
      this.treeCheckedIds = [];
      this.treeKey++;
    },

    // 获取所有菜单--树形结构
    menuGetMenuTree() {
      this.$api.menuGetMenuTree().then((res) => {
        this.treeData = res.data.data;
      });
    },

    // 查询角色列表--分页
    roleGageList() {
      this.$api
        .roleGageList({
          form: {
            dataScope: "",
            delFlag: "",
            name: "",
            useable: "",
          },
          pageIndex: this.page.index,
          pageSize: this.page.size,
        })
        .then((res) => {
          this.tableData = [];
          this.page.total = res.data.data.total;
          this.tableData = res.data.data.list;
        });
    },

    // 获取权限等级字典
    dictList() {
      this.$api
        .dictList({
          description: "",
          label: "",
          menuId: "",
          sort: "",
          type: "sys_dict_role_scope",
          value: "",
        })
        .then((res) => {
          this.dataScopeArr = res.data.data;
        });
    },

    // 删除
    remove(v, falg) {
      this.$wTx(
        "此操作将" + (falg ? "永久" : "") + "删除角色, 是否继续?",
        () => {
          this.$OL("正在删除角色...");
          this.$api[falg ? "roleRelDel" : "roleDel"]({ id: v.id }).then((res) => {
            this.$CL();
            this.$sMsg("删除成功");
            this.back();
          });
        },
        () => {
          this.$iMsg("已取消删除");
        }
      );
    },

    modify(v) {
      this.$OL("获取角色信息中...");
      this.$api.roleGetMenuIds({ roleId: v.id }).then((res) => {
        let exparr = [];
        let checkedarr = [];

        let x = (arr, v) => {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].id == v.id) {
              if (arr[i].children != null) {
                let f1 = x(arr[i].children, v);
                if (f1) {
                  return f1;
                }
              } else {
                return true;
              }
            } else {
              if (arr[i].children) {
                let f2 = x(arr[i].children, v);
                if (f2) {
                  return f2;
                }
              }
            }
          }
        };

        res.data.data.forEach((v) => {
          if (x(this.treeData, v) === true) {
            checkedarr.push(v.id);
          } else {
            exparr.push(v.id);
          }
        });

        this.treeExpandIds = exparr;
        this.treeCheckedIds = checkedarr;
        this.formData = v;
        // this.formData.menuIds = checkedarr;
        this.formTitle = "修改角色";
        this.activeName = "2";
        this.$CL();
      });
    },

    save() {
      this.$OL("正在保存角色...");
      this.$api
        .roleUpDate({
          id: this.formTitle == "添加角色" ? "" : this.formData.id,
          menuIds: this.formData.menuIds,
          name: this.formData.name,
          dataScope: this.formData.dataScope,
        })
        .then((res) => {
          this.$CL();
          this.$sMsg(this.formTitle == "添加角色" ? "添加成功" : "修改成功");
          this.back();
        });
    },

    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加角色";
      this.roleGageList();
    },

    // 树图复选框选中事件
    check(v, cArr) {
      this.formData.menuIds = cArr.halfCheckedKeys.concat(cArr.checkedKeys);
    },

    // 切换页码
    handleCurrentChange(index) {
      this.roleGageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.roleGageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加角色";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menuBox {
  position: relative;
  top: 9px;
}
</style>
