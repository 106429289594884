import axios from "axios";
import codeErrMsg from "./codeErrMsg";
import {getCK} from "../storage";
import {CL} from "../loading"; // 警告信息 s成功 w警告 e错误 (msgtext)

const request = axios.create({
//   baseURL: 'http://192.168.124.201:8187'
//   baseURL: 'http://127.0.0.1:8187/'
   baseURL: 'https://boss-api.yaku.online/'
//   baseURL: '/yaku'
//   timeout: 120000,
  // transformRequest: [function(data, headers) {
  //   return qs.stringify(data, { arrayFormat: 'repeat' }, { indices: false })
  // }]
})

request.interceptors.response.use(
    function (res) {
      // 获取数据后的拦截器
      // 对响应数据做点什么
      // 这里的状态码可根据实际情况来修改
      if (res.data.code) {
        if (res.data.code == "200") {
          return Promise.resolve(res);
        } else {
          codeErrMsg(`code${res.data.code}`, res.data.msg);
          CL();
          return Promise.reject(res);
        }
      } else {
        return Promise.resolve(res);
      }
    },
    function (error) {
      // 对响应错误做点什么
      codeErrMsg(`codeError`, res.data.msg);
      CL();
      return Promise.reject(error);
    }
);

// 用于登录的无token的post请求
export const postNotoken = (url, params) => {
  return request.post(
      url,
      {},
      {
        params,
      }
  );
};

// 用于一般场景下的post请求（可编辑接口，非other）
export const postType = (url, query, body) => {
  return request.post(url, body, {
    params: query,
    headers: {
      token: getCK("token"),
    },
  });
};

// 用于一般场景下的post请求（可编辑接口，非other）
export const postRequest = (url, params) => {
  return request.post(
      url,
      {},
      {
        params,
        headers: {
          token: getCK("token"),
        },
      }
  );
};

// 用于body传参的post方法
export const postBody = (url, params) => {
  return request.post(url, params, {
    headers: {
      token: getCK("token"),
    },
  });
};

// 用于上传文件场景的post上传
export const filesRequest = (url, params) => {
  return request.post(url, params, {
    headers: {
      token: getCK("token"),
      "Content-Type": "multipart/form-data",
    },
  });
};

// path（连接参数）类型的post请求
export const postPath = (url, params) => {
  let data = "";
  for (const key in params) {
    data = params[key];
  }
  return request.post(
      url + "/" + data,
      {},
      {
        headers: {
          token: getCK("token"),
        },
      }
  );
};

// 常规get请求
export const getRequest = (url, params) => {
  return request.get(url, {
    params,
    headers: {
      token: getCK("token"),
    },
  });
};

// path（连接参数）类型的get请求
export const getPath = (url, params) => {
  let data = "";
  for (const key in params) {
    data = params[key];
  }
  return request.get(url + "/" + data, {
    headers: {
      token: getCK("token"),
    },
  });
};

// get请求文件流
export const getBlob = (url, params) => {
  return request.get(url, {
    params,
    headers: {
      token: getCK("token"),
    },
    responseType: "blob",
  });
};
