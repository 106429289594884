<template>
  <div class="card">
    <el-row class="mb-15">
      <el-input v-model="ss.userId" class="w200 mr-15" size="small" placeholder="请输入用户id" clearable />
      <el-input v-model="ss.otherId" class="w200 mr-15" size="small" placeholder="请输入消费对象id" clearable />
      <el-input v-model="ss.anchorId" class="w200 mr-15" size="small" placeholder="请输入主播id" clearable />
      <el-input v-model="ss.txId" class="w250 mr-15" size="small" placeholder="请输入商店订单id" clearable />
      <el-input v-model="ss.no" class="w250 mr-15" size="small" placeholder="请输入充值订单号" clearable />
      <el-select v-model="ss.way" size="small" placeholder="请选择支付方式" clearable>
        <el-option
          v-for="item in payTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      &nbsp;&nbsp;
      <el-select v-model="ss.status" size="small" placeholder="请选择订单状态" clearable>
        <el-option label="未支付" value="0"></el-option>
        <el-option label="已支付" value="1"></el-option>
        <el-option label="电子支票" value="2"></el-option>
        <el-option label="退款" value="3"></el-option>
      </el-select>
      &nbsp;&nbsp;
      <el-select v-model="ss.service" size="small" placeholder="请选择充值类型" clearable>
        <el-option label="购买金币" value="gold"></el-option>
        <el-option label="蓝钻" value="vip"></el-option>
        <el-option label="翻译" value="trans"></el-option>
        <el-option label="语音包" value="tick"></el-option>
      </el-select>
      &nbsp;&nbsp;
      <el-date-picker
        v-model="selectTime"
        type="datetimerange"
        size="small"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="timestamp"
        :picker-options="pickerOptions"
      />
      &nbsp;
      <!-- <el-date-picker v-model="ss.startTime" class="mr-15" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
        default-time="00:00:00" size="small" placeholder="选择支付开始日期">
      </el-date-picker>

      <el-date-picker v-model="ss.endTime" class="mr-15" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
        default-time="00:00:00" size="small" placeholder="选择支付结束日期">
      </el-date-picker> -->
      <el-select v-model="ss.country" size="small" placeholder="选择国家" filterable style="width: 180px" clearable>
        <el-option v-for="(v, k) in dataScopeArr" :label="v.sc" :value="v.locale" :key="'国家' + k"></el-option>
      </el-select>
      &nbsp;&nbsp;
      <el-button type="primary" size="small" @click="query" style="margin-top: 15px">搜索</el-button>
      <el-button type="success" size="small" @click="downloadOrder()" :disabled="exportDisabled"
        v-if="$checkArr('order:downloadOrder')">导出</el-button>
    </el-row>
    <span>订单收益：{{ totalPayment }}</span>
    <div class="table210">
      <el-table :data="tableData" border height="100%" v-loading="loading">
        <el-table-column label="序号" type="index" width="50" align="center" fixed />
        <el-table-column label="用户名" prop="userName" align="center" />
        <el-table-column label="用户id" prop="userId" align="center" />
        <el-table-column label="消费对象id" prop="otherId" align="center" />
        <el-table-column label="国家" prop="country" align="center">
          <template slot-scope="scope">
            <div v-for="(item, key, index) in dataScopeArr" :key="index">
              <span v-if="scope.row.country == item.locale">{{ item.sc }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="所属推广" prop="belongAnchor" align="center"></el-table-column>
        <el-table-column label="所属推广姓名" prop="belongAnchorName" align="center"></el-table-column>
        <el-table-column label="充值类型" prop="service" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.service == 'gold'">购买金币</span>
            <span v-if="scope.row.service == 'vip'">蓝钻</span>
            <span v-if="scope.row.service == 'svip'">皇冠</span>
            <span v-if="scope.row.service == 'trans'">翻译</span>
            <span v-if="scope.row.service == 'tick'">语音包</span>
          </template>
        </el-table-column>
        <el-table-column label="充值数量" prop="amount" align="center"></el-table-column>
        <el-table-column label="充值金额" prop="total" align="center"></el-table-column>
        <el-table-column label="充值净额" prop="profit" align="center"></el-table-column>
        <el-table-column label="支付方式" prop="way" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.way | payTypeFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column label="充值订单" prop="no" align="center"></el-table-column>
        <el-table-column label="三方订单号" prop="txId" align="center"></el-table-column>
        <el-table-column label="订单状态" prop="status" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == -1">全部订单</span>
            <span v-if="scope.row.status == 0">未支付</span>
            <span v-if="scope.row.status == 1">已支付</span>
            <span v-if="scope.row.status == 2">电子支票</span>
            <span v-if="scope.row.status == 3">退款</span>
          </template>
        </el-table-column>
        <el-table-column label="下单时间(CST/UTC)" prop="insertTime" width="170" aline="center">
          <template v-if="scope.row.insertTime" slot-scope="scope">
            <div class="table_time">{{ scope.row.insertTime | TM2CST }}</div>
            <div class="table_time">{{ scope.row.insertTime | TM2UTC }}</div>
          </template>
        </el-table-column>
        <el-table-column label="支付完成时间(CST/UTC)" prop="paymentDate" width="190" aline="center">
          <template v-if="scope.row.paymentDate" slot-scope="scope">
            <div class="table_time">{{ scope.row.paymentDate | TM2CST }}</div>
            <div class="table_time">{{ scope.row.paymentDate | TM2UTC }}</div>
          </template>
        </el-table-column>
        <el-table-column label="是否测试" prop="sandbox">
          <template slot-scope="scope">
            <span v-if="scope.row.sandbox == 1">测试</span>
            <span v-else>正式</span>
          </template>
        </el-table-column>
        <el-table-column label="提成" prop="commission"></el-table-column>
        <!--          <el-table-column-->
        <!--            label="操作"-->
        <!--            align="center"-->
        <!--          >-->
        <!--            <template slot-scope="scope">-->
        <!--              <el-button-->
        <!--                type="warning"-->
        <!--                size="mini"-->
        <!--                @click="modify(scope.row)"-->
        <!--                v-if="$checkArr('sys:user:oper')"-->
        <!--                >编辑</el-button-->
        <!--              >-->
        <!--            </template>-->
        <!--          </el-table-column>-->
      </el-table>
    </div>
    <el-pagination
      class="mt-15"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pageObj.pageIndex"
      :page-sizes="page.sizes"
      :page-size.sync="pageObj.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
    />
  </div>
</template>

<script>
import { TM2UTC } from '@/components/js/date.js'
import { payTypeList } from '@/components/js/payType.js'
export default {
  mounted() {
    if (this.$checkArr("order:pageList")) {
      this.userPageList("初始化");
      this.orderTotalInfo("初始化");
      this.countryCodeList();
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      totalPayment: '--',
      activeName: "1",

      // 查询
      selectTime: null,
      payTypeList: [],
      ss: {
        userId: '',
        anchorId: '',
        txId: '',
        startTime: '',
        endTime: '',
        way: '',
        status: '',
        country: '',
        service: '',
        no: ''
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20
      },
      // 表格
      loading: false,
      tableData: [],

      formData: {},
      formTitle: "添加人员",
      exportDisabled: false,
      dataScopeArr: [],

      pickerOptions: {
        shortcuts: [{
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setHours(0);
            end.setMinutes(0);
            end.setSeconds(0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setTime(start.getTime());
            end.setHours(0);
            end.setMinutes(0);
            end.setSeconds(0);
            end.setTime(end.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            end.setHours(0);
            end.setMinutes(0);
            end.setSeconds(0);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  created() {
    this.payTypeList = payTypeList
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询用户列表--分页
    userPageList(type) {
      this.loading = true
      this.$api.orderList({
        form: {...this.ss},
        ...this.pageObj
      }).then((res) => {
        this.page.total = res.data.data.total;
        this.tableData = res.data.data.list;
        if (type == "初始化") {
        }
        this.loading = false
      })
    },
    // 统计充值金额
    orderTotalInfo(type) {
      this.totalPayment = '--'
      this.$api.orderTotalInfo({
        ...this.ss
          // userId: this.ss.userId,
          // startTime: this.ss.startTime,
          // endTime: this.ss.endTime,
          // way: this.ss.way,
          // country: this.ss.country,
          // anchorId: this.ss.anchorId,
          // service: this.ss.service,
          // txId: this.ss.txId
        }).then((res) => {
          this.totalPayment = res.data.data.totalPayment;
          if (type == "初始化") {
          }
        })
    },
    // 查询
    query() {
      this.getTimes()
      this.pageObj.pageIndex = 1
      this.userPageList()
      this.orderTotalInfo()
    },
    getTimes() {
      this.ss.startTime = this.selectTime ? TM2UTC(this.selectTime[0]) : ''
      this.ss.endTime = this.selectTime ? TM2UTC(this.selectTime[1]) : ''
    },

    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.activeName = "2";
      this.formTitle = "修改人员";
    },


    

    // 查询用户列表--分页
    searchOrder() {
      this.userPageList();
      this.orderTotalInfo();
    },

    // 导出列表
    downloadOrder() {
      this.getTimes()
      let user = this.$getCK("user");
      var officeId = user.currentOfficeId;
      var sysUserId = user.cUserId;

      this.exportDisabled = true
      let startTime = ""
      let endTime = ""
      if (this.ss.startTime !== undefined && this.ss.startTime.length > 0) {
        startTime = this.ss.startTime;
      }
      if (this.ss.endTime !== undefined && this.ss.endTime.length > 0) {
        endTime = this.ss.endTime;
      }
      window.location.href = 'https://boss-api.yaku.online/rpc/admin/downloadOrder?startTime=' + startTime + "&endTime=" + endTime + "&officeId=" + officeId + "&sysUserId=" + sysUserId
      // window.location.href = 'http://127.0.0.1:8187/rpc/admin/downloadOrder?startTime=' + startTime + "&endTime=" + endTime + "&officeId=" + officeId + "&sysUserId=" + sysUserId
      // alert(dz_url)
      this.exportDisabled = false
    },

    // 查询国家代码
    countryCodeList() {
      this.$api
        .regionList()
        .then((res) => {
          this.dataScopeArr = res.data.data;
        });
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加人员";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  height: calc(100% - 40px);
}
</style>
