<template>
  <el-card>
    <el-row :gutter="20">
      <el-col v-show="dividerFalg" :span="6">
        <div class="flex c-p" @click="dividerFalg = false">
          <el-divider content-position="center">组织结构</el-divider>
        </div>
        <el-tree
          :data="treeData1"
          :props="defaultProps1"
          :default-expanded-keys="treeExpandIds1"
          node-key="id"
          :expand-on-click-node="false"
          @node-click="handleNodeClick1"
          @node-expand="nodeExpand1"
          @node-collapse="nodeCollapse1"
        ></el-tree>
      </el-col>
      <el-col v-show="!dividerFalg" :span="1">
        <div class="flex fd-c jc-fs c-p" @click="dividerFalg = true">
          <el-divider
            style="height: 90px"
            direction="vertical"
            content-position="center"
          ></el-divider>
          <span>组</span>
          <span>织</span>
          <span>机</span>
          <span>构</span>
          <el-divider
            style="height: 90px"
            direction="vertical"
            content-position="center"
          ></el-divider>
        </div>
      </el-col>
      <el-col :span="dividerFalg ? 18 : 23">
        <el-row>
          <el-divider content-position="center">{{ activeObj.name }}</el-divider>
        </el-row>
        <el-tabs v-model="activeName">
          <el-tab-pane label="组织结构管理" name="1">
            <el-table
              :data="tableData"
              border
              row-key="id"
              default-expand-all
              :tree-props="{ children: 'chr' }"
            >
              <el-table-column prop="name" label="组织名称"></el-table-column>
              <el-table-column prop="level" label="组织层级"></el-table-column>

              <el-table-column
                label="操作"
                align="center"
                width="425xp"
                v-if="$checkArr('sys:role:oper')"
              >
                <template slot-scope="scope">
                  <el-button size="mini" type="success" @click="check(scope.row)"
                    >查看详情</el-button
                  >
                  <el-button size="mini" type="warning" @click="modify(scope.row)"
                    >修改</el-button
                  >
                  <el-button size="mini" type="danger" @click="remove(scope.row)"
                    >删除</el-button
                  >
                  <!-- <el-button size="mini" type="danger" @click="remove(scope.row, true)"
                    >物理删除</el-button
                  > -->
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('sys:role:oper')">
            <el-col :span="12">
              <el-form
                ref="form"
                :model="formData"
                :rules="{
                  parent: [
                    {
                      required: true,
                      message: '该项目不能为空',
                      trigger: 'change',
                    },
                  ],
                  name: [
                    {
                      required: true,
                      message: '该项目不能为空',
                      trigger: 'blur',
                    },
                  ],
                  sort: [
                    {
                      required: true,
                      message: '该项目不能为空',
                      trigger: 'blur',
                    },
                  ],
                }"
                label-position="right"
                label-width="150px"
              >
                <el-form-item label="父机构" prop="parent">
                  <div class="searchMenu" @click="searchMenu"></div>
                  <el-input v-model="formData.parent" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="名称：" prop="name">
                  <el-input v-model="formData.name" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                  <el-input v-model="formData.sort" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="备注：">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 5 }"
                    v-model="formData.remark"
                    placeholder=""
                  ></el-input>
                </el-form-item>

                <el-form-item label="">
                  <el-button
                    type="primary"
                    @click="
                      $refs.form
                        .validate()
                        .then(() => {
                          save();
                        })
                        .catch(() => {
                          $wMsg('请填写完整信息');
                        })
                    "
                    >保存</el-button
                  >
                  <el-button type="info" @click="back()">返回</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>

    <!-- 选择菜单对话框 -->
    <el-dialog
      title="选择菜单"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div>
        <el-tree
          :data="treeData2"
          :props="defaultProps2"
          @node-click="handleNodeClick2"
        ></el-tree>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog
      :v-loading="detailsLoad"
      title="详细信息"
      :visible.sync="detailsDialogVisible"
      width="75%"
      :close-on-click-modal="false"
    >
      <el-descriptions title="" direction="vertical" :column="3" border>
        <el-descriptions-item label="单位名称">{{
          detailsData.name
        }}</el-descriptions-item>

        <el-descriptions-item label="备注">{{
          detailsData.remark
        }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "structureSettings",
  mounted() {
    if (this.$checkArr("sys:role:view")) {
      this.officeGetTreeOfCurrentUser("初始化");
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      treeData1: [],
      treeExpandIds1: [],
      dividerFalg: true, // 分割线切换
      activeObj: { name: "未选中" },
      defaultProps1: {
        children: "children",
        label: "name",
      },
      activeName: "1",
      tableData: [],
      formTitle: "添加组织结构",
      formData: {},
      dialogVisible: false,
      treeData2: [],
      defaultProps2: {
        children: "children",
        label: "name",
      },
      detailsLoad: true,
      detailsDialogVisible: false,
      detailsData: {},
    };
  },
  methods: {
    // 重置数据
    reData() {
      this.formData = {};
    },

    // 获取所有机构--树形结构
    officeGetTreeOfCurrentUser(type) {
      this.$api.officeGetTreeOfCurrentUser().then((res) => {
        this.treeData1 = res.data.data;
        this.treeData2 = res.data.data;
        if (type == "初始化") {
          this.activeObj = res.data.data[0];
          this.officeGetChildList(this.activeObj);
        }
      });
    },

    // 获取子机构
    officeGetChildList(v) {
      this.$api.officeGetChildList({ id: v.id }).then((res) => {
        this.tableData = res.data.data;
      });
    },

    // 打开选择菜单
    searchMenu() {
      this.dialogVisible = true;
    },

    // 查看
    check(v) {
      this.detailsDialogVisible = true;
      this.detailsData = JSON.parse(JSON.stringify(v));
      this.detailsLoad = false;
    },

    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.formTitle = "修改组织结构";
      this.activeName = "2";
      let x = (arr) => {
        for (let i = 0; i < arr.length; i++) {
          let v = arr[i];
          if (v.id == obj.parentId) {
            this.formData.parent = v.name;
            break;
          } else if (v.children) {
            x(v.children);
          }
        }
      };
      x(this.treeData2);
    },

    // 删除
    remove(v, falg) {
      this.$wTx(
        "该操作将" + (falg ? "永久" : "") + "删除当前组织结构条目，确认继续吗？",
        () => {
          this.$OL("删除组织结构条目中...");
          this.$api[falg ? "officeRelDel" : "officeDel"]({ id: v.id }).then((res) => {
            this.$sMsg("删除成功");
            this.back();
            this.$CL();
          });
        },
        () => {
          this.$iMsg("已取消删除");
        }
      );
    },

    // 保存
    save() {
      this.$OL("正在保存...");
      this.$api
        .officeUpdate({
          id: this.formTitle == "添加组织结构" ? "" : this.formData.id,
          parentId: this.formData.parentId,
          name: this.formData.name,
          sort: this.formData.sort,
          remark: this.formData.remark,
        })
        .then((res) => {
          this.activeName = "1";
          this.$sMsg(this.formTitle == "添加组织结构" ? "添加成功" : "修改成功");
          this.back();
          this.$CL();
        });
    },

    // 返回
    back() {
      this.officeGetTreeOfCurrentUser();
      this.officeGetChildList(this.activeObj);
      this.formTitle = "添加组织结构";
      this.activeName = "1";
      this.reData();
    },

    // 选中菜单
    handleNodeClick1(v) {
      if (v.level < 3) {
        this.activeObj = JSON.parse(JSON.stringify(v));
        this.officeGetChildList(this.activeObj);
        if (this.formTitle == "添加组织结构") {
          this.$set(this.formData, "parent", this.activeObj.name);
          this.formData.parentId = this.activeObj.id;
        }
      } else {
        this.$wMsg("无下属层级");
      }
    },

    // 节点展开
    nodeExpand1(v, node, dom) {
      this.treeExpandIds1.push(v.id);
    },

    // 节点关闭
    nodeCollapse1(v, node, dom) {
      this.treeExpandIds1.splice(
        this.treeExpandIds1.findIndex((rv) => {
          rv.id == v.id;
        }),
        1
      );
    },

    // 选中菜单
    handleNodeClick2(v) {
      if (this.treeDbClick) {
        this.formData.parent = v.name;
        this.formData.parentId = v.id;
        this.dialogVisible = false;
        clearTimeout(this.treeDbClick);
        this.treeDbClick = null;
      } else {
        this.treeDbClick = setTimeout(() => {
          clearTimeout(this.treeDbClick);
          this.treeDbClick = null;
        }, 300);
      }
    },
  },
  watch: {
    activeName() {
      if (this.activeName == 1) {
        this.reData();
        this.formTitle = "添加组织结构";
      } else if (this.activeName == "2" && this.formTitle == "添加组织结构") {
        this.$set(this.formData, "parent", this.activeObj.name);
        this.formData.parentId = this.activeObj.id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.searchMenu {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  cursor: text;
}
</style>
