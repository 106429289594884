<template>
  <div class="homeBox">
    <el-container>
      <el-aside width="200px" class="aside" ref="aside">
        <!-- class="logo flex jc-se fd-c" -->
        <!-- <div
          class="logo"
          @click="
            () => {
              this.$bus.$emit('刷新菜单');
              this.$router.push({ path: '/home' });
            }
          "
        > -->
        <div class="logo">
          <img src="../../assets/home/logo.svg" alt=""/>
          <span class="logoText">羽 锐 科 技</span>
        </div>
        <!-- 监听bosSize -->
        <onSize @onSize="changeSize($event)" />
        <myMenu ref="menuRef" />
      </el-aside>
      <!-- <el-container
        :style="'height:' + this.$store.state.maxH + 'px;minHeight:' + minHeight + 'px'"
      > -->
      <el-container>
        <el-header class="header flex">
          <div class="topBox flex jc-sb">
            <div style="margin-left: 20px">
              <el-breadcrumb separator-class="el-icon-arrow-right" v-if="breadcrumbFalg">
                <el-breadcrumb-item
                  
                  v-for="(v, k) in $route.meta.data"
                  :key="'面包屑' + k"
                  :to="v.to"
                  >{{ v.text }}</el-breadcrumb-item
                >
              </el-breadcrumb>
            </div>
            <div class="topRight">
              <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ $getCK("user").name }}
                  <!-- $getCK("user").currentOfficeName
                      ? "(" + $getCK("user").currentOfficeName + ")"
                      : "(" + $getCK("user").currentRoleName + ")" -->
                  {{
                    "(" +
                    ($getCK("userInfo").dataScope == 0
                      ? "超管"
                      : $getCK("userInfo").dataScope == 1
                      ? "管理"
                      : $getCK("userInfo").dataScope == 2
                      ? "代理"
                      : $getCK("userInfo").dataScope == 3
                      ? "工会"
                      : $getCK("userInfo").dataScope == 4
                      ? "主播"
                      : "") +
                    ")"
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item :command="1" style="width: 95px" class="flex"
                    >个人中心</el-dropdown-item
                  > -->
                  <el-dropdown-item :command="2" style="width: 95px" class="flex"
                    >修改密码</el-dropdown-item
                  >
                  <el-dropdown-item :command="3" style="width: 95px" class="flex"
                    >切换角色</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <span class="back" @click="back()">退出</span>
            </div>
          </div>
        </el-header>
        <el-main class="main">
          <keep-alive
            :include="[
              'patrolDaily',
              'patrolOwn',
              'patrolSpecial',
              'myWorkPatrolDaily',
              'myWorkPatrolOwn',
              'myWorkPatrolSpecial',
              'danger',
              'dangerTwo',
              'reportList',
              'reportExamine',
              'programmeLIst',
            ]"
          >
            <router-view></router-view>
          </keep-alive>
          <el-button
            class="mt-15"
            type="info"
            v-if="
              $route.path == '/patrolSpecialResult' ||
              $route.path == '/patrolSpecialResultDetails' ||
              $route.path == '/patrolDailyResult' ||
              $route.path == '/patrolDailyResultDetails' ||
              $route.path == '/patrolOwnResultTime' ||
              $route.path == '/patrolOwnResultLaboratory' ||
              $route.path == '/patrolOwnResult'
            "
            @click="$router.go(-1)"
            >返回</el-button
          >
        </el-main>
      </el-container>
    </el-container>

    <el-dialog
      title="修改密码"
      :visible.sync="pwdDialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="pwdFormData"
        :rules="{
          oldPwd: [
            {
              required: true,
              message: '该项目不能为空',
              trigger: 'blur',
            },
          ],
          newPwd: [
            {
              required: true,
              message: '该项目不能为空',
              trigger: 'blur',
            },
          ],
          cofPwd: [
            {
              required: true,
              message: '该项目不能为空',
              trigger: 'blur',
            },
          ],
        }"
        label-width="150px"
        label-position="left"
      >
        <el-form-item label="旧密码：" prop="oldPwd">
          <el-input v-model="pwdFormData.oldPwd" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="新密码：" prop="newPwd">
          <el-input v-model="pwdFormData.newPwd" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="cofPwd">
          <el-input v-model="pwdFormData.cofPwd" placeholder=""></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            $refs.form
              .validate()
              .then(() => {
                userModifyPwd();
              })
              .catch(() => {
                $wMsg('请填写完整信息');
              })
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="选择角色"
      :visible.sync="roleDialogVisible"
      width="75%"
      :close-on-click-modal="false"
    >
      <el-table :data="roleTableData" border>
        <el-table-column label="所属组织" prop="officeName"></el-table-column>
        <el-table-column label="角色" prop="roleName"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="switchOffice(scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import myMenu from "./myMenu";
import onSize from "../element/onSize";

export default {
  name: "home",
  components: {
    myMenu,
    onSize,
  },
  mounted() {
  },
  data() {
    return {
      minHeight: 0,
      pwdDialogVisible: false,
      pwdFormData: {
        oldPwd: "",
        newPwd: "",
        cofPwd: "",
      },
      roleDialogVisible: false,
      roleTableData: [],
      breadcrumbFalg: true, // 面包屑组件刷新用
    };
  },
  methods: {
    // routerTo(v) {
    //   console.log(321);
    //   if(v.to === '/home') {
    //     console.log(123456);
    //     window.localStorage.setItem('activePage', '')
    //     this.$refs.menuRef.activePage = ''
    //   }
    // },
    // boxSize变化
    changeSize(v) {
      this.minHeight = v.h;
    },

    // 触发下拉框指令
    handleCommand(v) {
      switch (v) {
        case 1:
          // 跳转到个人中心
          this.$router.push("info");
          break;
        case 2:
          // 修改密码
          this.pwdDialogVisible = true;
          break;
        case 3:
          // 切换角色
          this.$api.menu().then((res) => {
            this.roleTableData = res.data.data;
          });
          this.roleDialogVisible = true;
          break;
      }
    },

    // 修改密码
    userModifyPwd() {
      if (this.pwdFormData.newPwd == this.pwdFormData.cofPwd) {
        this.$OL("修改密码中...");
        this.$api.userModifyPwd({newPwd: this.pwdFormData.newPwd, oldPwd: this.pwdFormData.oldPwd}).then((res) => {
          this.$CL();
          this.$sMsg("修改密码成功");
          this.pwdDialogVisible = false;
          this.back();
        });
      } else {
        this.$wMsg("新密码与确认密码不一致!");
      }
    },

    // 切换组织(切换角色)
    switchOffice(v) {
      this.$OL("切换角色中...");
      this.$api.switchOffice({ officeId: v.officeId, roleId: v.roleId }).then((res) => {
        this.roleTableData = [];
        if (this.$route.path != "/home") {
          this.$router.push("/home");
        }
        let user = this.$getCK("user");
        user.currentOfficeId = v.officeId;
        user.currentOfficeName = v.officeName;
        user.currentRoleName = v.roleName;
        this.$setCK("user", user);
        this.$setCK("token", res.data.data.token);
        this.$api.loginGetLevelOfCurrentRole().then((res) => {
          this.$setCK("userInfo", JSON.stringify(res.data.data));
          // 保存权限集合
          this.$api.getPermissions().then((res) => {
            this.$setCK("r", JSON.parse(JSON.stringify(res.data.data))); // 权限集合
            this.$sMsg("切换角色成功");
            this.$bus.$emit("刷新菜单");
            this.roleDialogVisible = false;
            this.$router.push({ path: "empty", query: { path: "/home" } });
            this.$CL();
          });
        });
        // // 保存权限集合
        // this.$api.getPermissions().then((res) => {
        //   this.$setCK("r", JSON.parse(JSON.stringify(res.data.data))); // 权限集合
        //   // this.$router.push({
        //   //   path: "/home",
        //   // });
        // });
        // this.$sMsg("切换角色成功");
        // this.$bus.$emit("刷新菜单");
        // this.roleDialogVisible = false;
        // this.$router.push({ path: "empty", query: { path: "/home" } });
        // this.$CL();
      });
    },

    // 退出
    back() {
      this.$OL("正在退出当前账号...");
      this.$api.logout().then((res) => {
        this.$router.push("/");
        this.$delCK("token");
        this.$delCK("user");
        this.$CL();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.homeBox {
  height: 100%;
  .header {
    padding: 0;
    background-color: #ffffff;
    .topBox {
      width: 100%;
      height: 100%;
      .topRight {
        margin-right: 15px;
        ::v-deep .el-dropdown-link {
          cursor: pointer;
        }
        .back {
          cursor: pointer;
          font-size: 14px;
          margin-left: 15px;
        }
      }
    }
  }
  .el-container {
    height: 100%;
  }
  ::v-deep .aside {
    height: 100%;
    position: relative;
    background-color: #050350;
    .logo {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 10px 20px 10px 0;
      // height: 200px;
      cursor: pointer;
      text-align: center;
      color: #ffffff;
      font-size: 16px;
      img {
        width: 50px;
      }
      .logoText {
        font-size: 20px;
        font-family: FZHZGBJW--GB1-0, FZHZGBJW--GB1;
        font-weight: normal;
        color: #ffffff;
        line-height: 19px;
        letter-spacing: 1px;
      }
    }
    .el-menu {
      border: 0;
    }
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
  .main {
    height: 100%;
    background-color: #eef4fa;
    //padding: 25px 50px;
    padding: 0;
  }
}
.mt-15 {
  margin-top: 15px;
}
</style>
