<template>
  <div class="card">
    <el-tabs v-model="activeName" style="height: 100%">
      <el-tab-pane label="人员列表" name="1" style="height: 100%">
        <el-row class="mb-15">
          <el-input v-model="ss.name" class="w200 mr-15" size="small" placeholder="请输入姓名" clearable></el-input>
          <el-input v-model="ss.loginName" class="w200 mr-15" size="small" placeholder="请输入账号" clearable></el-input>
          <el-button type="primary" size="small" @click="userPageList()">搜索</el-button>
        </el-row>
        <div class="table150">
          <el-table :data="tableData" height="100%" border v-loading="loading">
            <el-table-column label="序号" type="index" align="center" width="50" />
            <el-table-column label="姓名" prop="name"  align="center" />
            <el-table-column label="账号" prop="loginName"  align="center" />
            <el-table-column label="邮箱" prop="email"  align="center" />
            <el-table-column label="电话" prop="phone"  align="center" />
            <el-table-column label="角色" prop="roleText"  align="center" />
            <el-table-column label="最后登录时间(CST/UTC)" prop="loginDate" width="180" align="center">
              <template v-if="scope.row.loginDate" slot-scope="scope">
                <div class="table_time">{{scope.row.loginDate | TM2CST }}</div>
                <div class="table_time">{{ scope.row.loginDate | TM2UTC }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="350" v-if="$checkArr('sys:user:oper') || $checkArr('重置密码')">
              <template slot-scope="scope">
                <el-button type="warning" size="mini" @click="modify(scope.row)" v-if="$checkArr('sys:user:oper')">修改
                </el-button>
                <el-button type="danger" size="mini" @click="rePwd(scope.row)" v-if="$checkArr('重置密码')">重置密码</el-button>
                <el-button type="danger" size="mini" @click="remove(scope.row)" v-if="$checkArr('sys:user:oper')">删除
                </el-button>

                <el-button type="primary" size="mini" @click="modifyRole(scope.row)" v-if="$checkArr('sys:user:oper')">
                  分配角色</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination class="mt-15" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="page.index" :page-sizes="page.sizes" :page-size.sync="page.size"
          layout="total, sizes, prev, pager, next, jumper" :total="page.total">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('sys:user:oper')">
        <el-row>
          <el-col :span="12">
            <el-form ref="form" :model="formData" label-width="150px" label-position="right">
              <el-form-item label="姓名" prop="name">
                <el-input v-model="formData.name" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="账号" prop="loginName">
                <el-input v-model="formData.loginName" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="formData.email" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="微信" prop="wxOpenId">
                <el-input v-model="formData.wxOpenId" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="电话" prop="phone">
                <el-input v-model="formData.phone" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-button type="success" @click="
                  $refs.form
                    .validate()
                    .then(() => {
                      save();
                    })
                    .catch(() => {
                      $wMsg('请填写完整信息');
                    })
                ">保存</el-button>
                <el-button type="info" @click="back()">返回</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <el-dialog title="分配角色" :visible.sync="roleVisible" width="30%" :before-close="handleClose">
      <el-form ref="form" :model="formData" :rules="{
        name: [
          {
            required: true,
            message: '该项不能为空',
            trigger: ['change'],
          },
        ],
        loginName: [
          {
            required: true,
            message: '该项不能为空',
            trigger: ['change'],
          },
        ],
        roleIds: [
          {
            required: true,
            message: '该项不能为空',
            trigger: 'change',
          },
        ],
      }" label-position="right" label-width="90px">
        <el-form-item label="姓名：" prop="name">
          <div class="searchMenu"></div>
          <el-input v-model="formData.name" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="账号：" prop="loginName">
          <div class="searchMenu"></div>
          <el-input v-model="formData.loginName" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="角色：" prop="roleIds">
          <el-select v-model="formData.roleIds" multiple placeholder="" style="width: 100%">
            <el-option v-for="(v, k) in roleArr" :key="'角色数组' + k" :label="v.name" :value="v.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveRole()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import roleData from "@/data/role";
import { data } from "@/data/home";

export default {
  mounted() {
    if (this.$checkArr("sys:menu:view")) {
      this.userPageList("初始化");
      this.getRoleList();
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      // 表格
      loading: false,
      tableData: [],
      ss: {
        name: "",
        loginName: "",
      },
      page: {
        index: 1,
        sizes: [10, 20, 30, 40],
        size: 20,
        total: 40,
      },
      formData: {},
      formTitle: "添加人员",
      roleVisible: false,
      roleArr: [],
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询用户列表--分页
    userPageList(type) {
      this.loading = true
      this.$api
        .userPageList({
          form: {
            name: this.ss.name,
            loginName: this.ss.loginName,
          },
          pageIndex: this.page.index,
          pageSize: this.page.size,
        })
        .then((res) => {
          this.page.total = res.data.data.total;
          // this.tableData = res.data.data.list;
          let data = res.data.data.list;
          data.map((v) => {
            v.roleIds = [];
            v.roleText = [];
            if (v.roles != null) {
              v.roles.forEach((rv) => {
                v.roleIds.push(rv.id);
                v.roleText.push(rv.name);
              });
            }

            v.roleText = v.roleText.join(",");
          });
          this.tableData = data;
          this.loading = false
        });
    },

    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.activeName = "2";
      this.formTitle = "修改人员";
    },

    // 删除
    remove(v, falg) {
      this.$wTx(
        "该操作将" + (falg ? "永久" : "") + "删除当前用户条目，确认继续吗？",
        () => {
          this.$OL("正在删除...");
          this.$api[falg ? "userRelDel" : "userDel"]({ id: v.id }).then((res) => {
            this.back();
            this.$CL();
            this.$sMsg("删除成功");
          });
        },
        () => {
          this.$iMsg("已取消删除");
        }
      );
    },

    // 重置密码
    rePwd(v) {
      this.$wTx(
        "该操作将重置用户：" + v.name + "的登录密码，确认继续吗？",
        () => {
          this.$OL("正在重置密码...");
          this.$api.userResetPassword({ id: v.id }).then((res) => {
            this.$CL();
            this.$sMsg("密码重置成功");
            this.userPageList();
          });
        },
        () => {
          this.$iMsg("已取消重置密码操作");
        }
      );
    },

    // 分配角色弹框
    modifyRole(v) {
      this.roleVisible = true;
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
    },

    // 查询角色列表
    getRoleList() {
      this.$api
        .getRoleByLoginUserOffice()
        .then((res) => {
          this.roleArr = res.data.data;
        });
    },

    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {
        });
    },

    // 保存
    saveRole() {
      this.$OL("保存中...");
      this.$api
        .officeUserUpdate({
          officeId: this.formData.officeId,
          userId: this.formData.id,
          roleIds: this.formData.roleIds,
        })
        .then((res) => {
          debugger;
          this.$CL();
          this.$sMsg(res.data.msg);
          this.roleVisible = false;
          this.userPageList();
        });
    },

    // 保存
    save() {
      // this.$OL("正在保存...");
      // this.$api
      //   .userGetUserByUserName({
      //     userName: this.formData.loginName,
      //   })
      //   .then((res) => {
      //     if (res.data.data == null) {
      //       this.$api
      //         .userUpdate({
      //           id: this.formTitle == "添加人员" ? "" : this.formData.id,
      //           name: this.formData.name,
      //           loginName: this.formData.loginName,
      //           email: this.formData.email,
      //           wxOpenId: this.formData.wxOpenId,
      //           phone: this.formData.phone,
      //         })
      //         .then((ress) => {
      //           this.$CL();
      //           this.$sMsg(this.formTitle == "添加人员" ? "添加成功" : "修改成功");
      //           this.back();
      //         });
      //     } else {
      //       this.$eMsg("该账号已存在！");
      //     }
      //   });


      this.$api
        .userUpdate({
          id: this.formTitle == "添加人员" ? "" : this.formData.id,
          name: this.formData.name,
          loginName: this.formData.loginName,
          email: this.formData.email,
          wxOpenId: this.formData.wxOpenId,
          phone: this.formData.phone,
        })
        .then((ress) => {
          this.$CL();
          this.$sMsg(ress.data.msg);
          this.back();
        });
    },

    // 下载人员信息模板
    getTemplate() {
      let a = document.createElement("a");
      a.href = "/api/laboratory/用户导入.xlsx";
      a.download = "人员信息模板";
      a.click();
      a.remove();
    },

    // 导入基本人员信息
    setPersonnelTable() {
      let _this = this;
      let ipt = document.createElement("input");
      ipt.type = "file";
      ipt.click();
      ipt.onchange = function (e) {
        _this.upFile(this);
      };
    },

    // 上传
    upFile(dom) {
      this.$OL("正在上传文件...");
      let form = new FormData();
      form.append("file", dom.files[0]);
      this.$api
        .userModifyPwd(form)
        .then((res) => {
          this.userPageList();
          this.$CL();
          dom.remove();
          this.sMsg("导入成功");
        })
        .catch(() => {
          this.$CL();
          dom.remove();
        });
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加人员";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
