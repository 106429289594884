<template>
  <div class="card">
    <el-row class="mb-15">
      <el-input
        v-model="ss.userId"
        class="w200 mr-15"
        size="small"
        placeholder="请输入用户id"
        clearable
      ></el-input>
      <el-button type="primary" size="small" @click="query">搜索</el-button>
    </el-row>
    <div class="table150">
      <el-table :data="tableData" border height="100%" v-loading="loading">
        <el-table-column label="序号" type="index" width="50" align="center" fixed />
        <el-table-column label="用户昵称" prop="userName" align="center"></el-table-column>
        <el-table-column label="用户id" prop="userId" align="center"></el-table-column>
        <el-table-column label="主播昵称" prop="otherName" align="center"></el-table-column>
        <el-table-column label="主播id" prop="otherId" align="center"></el-table-column>
        <el-table-column label="消费时长(秒)" prop="secondNum" align="center"></el-table-column>
        <el-table-column label="开始时间(CST/UTC)" prop="startTime" width="170" align="center">
          <template v-if="scope.row.startTime" slot-scope="scope">
            <div>{{ scope.row.startTime | TM2CST }}</div>
            <div>{{ scope.row.startTime | TM2UTC }}</div>
          </template>
        </el-table-column>
        <el-table-column label="结束时间(CST/UTC)" prop="endTime" width="170" align="center">
          <template v-if="scope.row.endTime" slot-scope="scope">
            <div>{{ scope.row.endTime | TM2CST }}</div>
            <div>{{ scope.row.endTime | TM2UTC }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="mt-15"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pageObj.pageIndex"
      :page-sizes="page.sizes"
      :page-size.sync="pageObj.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.$checkArr("videoCall:pageList")) {
      this.userPageList("初始化");
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      // 查询
      ss: {
        userId: ""
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20
      },
      // 表格
      loading: false,
      tableData: [],

      formData: {},
      formTitle: "添加人员",
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询用户列表--分页
    userPageList(type) {
      this.loading = true
      this.$api
        .videoCallList({
          form: {...this.ss},
          ...this.pageObj
        })
        .then((res) => {
          this.page.total = res.data.data.total;
          this.tableData = res.data.data.list;
          if (type == "初始化") {
          }
          this.loading = false
        });
    },
    // 查询
    query() {
      this.pageObj.pageIndex = 1
      this.userPageList()
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加人员";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
