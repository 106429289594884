import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueParticles from "vue-particles"; // 粒子效果背景图
// 富文本
import tinymce from "tinymce";
import VueTinymce from "@packy-tang/vue-tinymce";
// 手动添加的插件
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueCookies from "vue-cookies"; // cookie操作
import axios from "axios";
// 引入自定义工具
import bus from "./Tools/bus"; // 事件总线
import getB64 from "./Tools/getB64"; // 图片转B64数据 (imageFile, callback, errorCallback)
import getTime from "./Tools/getTime"; // 时间戳转换 (v)
import {eMsg, iMsg, sMsg, wMsg} from "./Tools/msg"; // 警告信息 s成功 w警告 e错误 (msgtext)
import {wTx} from "./Tools/tx"; // 警告信息 s成功 w警告 e错误 (msgtext)
import {delCK, getCK, getLS, getSS, isCK, setCK, setLS, setSS,} from "./Tools/storage"; // 本地储存LS与服务器储存SS
import {api} from "./interface"; // 接口api
import getMaxH from "./Tools/getMaxH"; // 获取浏览器高度
import {CL, OL} from "./Tools/loading"; // 开启/关闭加载图
import {loginWS, loginWSC} from "./Tools/loginWebsocket"; // 创建Websocket服务
import checkArr from "./Tools/checkArr"; // 检索权限数组
import draw from "./Tools/verificationCode";
import {payTypeList} from "./components/js/payType";


Vue.use(VueParticles); // 粒子效果背景图

Vue.prototype.$tinymce = tinymce;
Vue.use(VueTinymce);

// 安装插件
Vue.use(ElementUI);
Vue.use(VueCookies);

// 杂项
getMaxH(); // 获取当前窗口的高度 储存在VueX中

// 声明工具变量
// var websocket = null; //判断当前浏览器是否支持WebSocket

// 注册工具
Vue.prototype.$store = store;
Vue.prototype.$ip = "/api"; // 通用ip 应尽量避免调用
// Vue.prototype.$ip = "http://192.168.1.110:8091/"; // 通用ip 应尽量避免调用
Vue.prototype.$axios = axios; // axios本体
Vue.prototype.$bus = bus; // 事件总线
Vue.prototype.$api = api; // 接口列表
Vue.prototype.$getB64 = getB64; // 图片转换b64数据
Vue.prototype.$getTime = getTime; // 转换时间戳
Vue.prototype.$sMsg = sMsg; // 成功信息
Vue.prototype.$wMsg = wMsg; // 警告信息
Vue.prototype.$eMsg = eMsg; // 错误信息
Vue.prototype.$iMsg = iMsg; // 提示信息
Vue.prototype.$wTx = wTx; // 警报提示窗
Vue.prototype.$setSS = setSS; // 存储服务器缓存
Vue.prototype.$getSS = getSS; // 获取服务器缓存
Vue.prototype.$setLS = setLS; // 存储本地缓存
Vue.prototype.$getLS = getLS; // 获取本地缓存
Vue.prototype.$setCK = setCK; // 写入cookies
Vue.prototype.$getCK = getCK; // 获取cookies
Vue.prototype.$delCK = delCK; // 删除cookies
Vue.prototype.$isCK = isCK; // 判断cookies是否存在
Vue.prototype.$OL = OL; // 开启loading效果
Vue.prototype.$CL = CL; // 关闭loading效果
Vue.prototype.$loginWS = loginWS; // 开启WS
Vue.prototype.$loginWSC = loginWSC; // 关闭WS
Vue.prototype.$draw = draw; // 渲染验证码的方法
Vue.prototype.$checkArr = checkArr; // 检索权限数组
Vue.prototype.$imageUrl = "https://images.yaku.online/"; // 图片地址
// Vue.prototype.$imageUrl = "http://192.168.124.27:8185/"; // 图片地址

Vue.config.productionTip = false;

Vue.filter('TM2CST', val =>{
  if(!val) {
    return ''
  }
  const date = new Date(val)
  const yyyy = date.getFullYear()
  const MM = date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
  const dd = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
  const hh = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
  const mm = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
  const ss = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()
  return yyyy + '-' + MM + '-' + dd + ' ' + hh + ':' + mm + ':' + ss
})
Vue.filter('TM2UTC', val =>{
  if(!val) {
    return ''
  }
  const date = new Date(val)
  const yyyy = date.getUTCFullYear()
  const MM = date.getUTCMonth() > 8 ? date.getUTCMonth() + 1 : '0' + (date.getUTCMonth() + 1)
  const dd = date.getUTCDate() > 9 ? date.getUTCDate() : '0' + date.getUTCDate()
  const hh = date.getUTCHours() > 9 ? date.getUTCHours() : '0' + date.getUTCHours()
  const mm = date.getUTCMinutes() > 9 ? date.getUTCMinutes() : '0' + date.getUTCMinutes()
  const ss = date.getUTCSeconds() > 9 ? date.getUTCSeconds() : '0' + date.getUTCSeconds()
  return yyyy + '-' + MM + '-' + dd + ' ' + hh + ':' + mm + ':' + ss
})
Vue.filter('payTypeFormat', val =>{
  // if(!val) {
  //   return ''
  // }
  const index = payTypeList.findIndex(i => i.value == val)
  return payTypeList[index].label
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");


import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)

Viewer.setDefaults({
  Options: {
    'inline': true,
    'button': true,
    'navbar': true,
    'title': true,
    'toolbar': true,
    'tooltip': true,
    'movable': true,
    'zoomable': true,
    'rotatable': true,
    'scalable': true,
    'transition': true,
    'fullscreen': true,
    'keyboard': true,
    'url': 'data-source'
  }
})

//
// import VideoPlayer from 'vue-video-player'
// require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
// Vue.use(VideoPlayer)