<template>
  <div class="card">
    <el-tabs v-model="activeName" @tab-click="tabChenge" style="height: 100%">
      <el-tab-pane label="主播列表" name="1" style="height: 100%">
        <el-row class="mb-15">
          <el-input
              v-model="ss.anchorId"
              class="w200 mr-15"
              size="small"
              placeholder="请输入主播ID"
              clearable
          />
          <el-select v-model="ss.guildId" size="small" placeholder="请选择公会" filterable style="width: 200px" clearable>
            <el-option
                v-for="(v, k) in dataGuild"
                :label="v.name"
                :value="v.id"
                :key="'公会' + k"
            />
          </el-select>
          &nbsp;
          <el-date-picker
            v-model="selectTime"
            type="datetimerange"
            size="small"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          />
          &nbsp;
          <el-button type="primary" size="small" @click="query">搜索</el-button>
        </el-row>
        <div class="table150">
          <el-table :data="tableData" border height="100%" v-loading="loading">
            <el-table-column label="序号" type="index" align="center" width="50" fixed />
            <el-table-column label="主播ID" prop="anchorId" align="center" />
            <el-table-column label="主播昵称" prop="name" align="center" />
            <el-table-column label="所属公会" prop="guildId" align="center">
              <template slot-scope="scope">
                <div v-for="(item,key,index) in dataGuild" :key="index">
                  <span v-if="scope.row.guildId == item.id">{{item.name}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="充值金币" prop="gold" align="center"></el-table-column>
            <el-table-column label="充值金币提成" prop="comGold" align="center"></el-table-column>
            <el-table-column label="充值vip" prop="vip" align="center"></el-table-column>
            <el-table-column label="充值vip提成" prop="comVip" align="center"></el-table-column>
            <el-table-column label="充值翻译包" prop="trans" align="center"></el-table-column>
            <el-table-column label="充值翻译包提成" prop="comTrans" align="center"></el-table-column>
            <el-table-column label="充值通话包" prop="tick" align="center"></el-table-column>
            <el-table-column label="充值通话包提成" prop="comTick" align="center"></el-table-column>
            <el-table-column label="消费通话秒数" prop="talkTimeSeconds" align="center"></el-table-column>
            <el-table-column label="视频礼物数量" prop="giftCount" align="center"></el-table-column>
            <el-table-column label="视频礼物金币数" prop="giftGold" align="center"></el-table-column>
            <el-table-column label="视频礼物金额" prop="exGift" align="center"></el-table-column>
            <el-table-column label="视频礼物提成" prop="costComGift" align="center"></el-table-column>
            <el-table-column label="消费im字符数" prop="imTransCount" align="center"></el-table-column>
            <el-table-column label="消费im金额" prop="exImTrans" align="center"></el-table-column>
            <el-table-column label="消费im提成" prop="comImTrans" align="center"></el-table-column>
            <el-table-column label="聊天礼物数量" prop="imGiftCount" align="center"></el-table-column>
            <el-table-column label="聊天礼物金币数" prop="imGiftGold" align="center"></el-table-column>
            <el-table-column label="聊天礼物金额" prop="exImGift" align="center"></el-table-column>
            <el-table-column label="聊天礼物提成" prop="comImGift" align="center"></el-table-column>
            <el-table-column label="邀请人数" prop="inviteCount" align="center"></el-table-column>
  <!--          <el-table-column label="时间" prop="insertTime" align="center"></el-table-column>-->
          </el-table>
        </div>
        <el-pagination
            class="mt-15"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageObj.pageIndex"
            :page-sizes="page.sizes"
            :page-size.sync="pageObj.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { TM2UTC } from '@/components/js/date.js'
export default {
  mounted() {
    if (this.$checkArr("anchorSummary:pageList")) {
      this.anchorSummaryPageList("初始化");
      this.getGuildList();
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      starVisible: false,
      dialogVisible: false,
      activeName: "1",
      isDisabled: false,

      // 查询
      selectTime: null,
      ss: {
        anchorId: "",
        guildId: "",
        startTime: '',
        endTime: ''
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20,
      },
      // 表格
      tableData: [],
      loading: false,

      trainAnchorForm: {},
      bindTrainDigVisible: false,
      dataScopeArr: [],
      dataGuild: [],
      dataCountry: [],
    };
  },
  methods: {
    // 查询用户列表--分页
    anchorSummaryPageList(type) {
      this.loading = true
      this.$api.findAnchorSummaryPage({
            form: {...this.ss},
            ...this.pageObj
          }).then((res) => {
            this.activeName = "1";
            this.page.total = res.data.data.total;
            this.tableData = res.data.data.list;
            if (type == "初始化") {
            }
            this.loading = false
          });
    },
    // 搜索
    query() {
      this.getTimes()
      this.pageObj.pageIndex = 1
      this.anchorSummaryPageList()
    },
    getTimes() {
      this.ss.startTime = this.selectTime ? TM2UTC(this.selectTime[0]) : ''
      this.ss.endTime = this.selectTime ? TM2UTC(this.selectTime[1]) : ''
    },

    // 获取主播类型列表
    getPercentageList() {
      this.$api
          .getPercentageList()
          .then((res) => {
            this.dataScopeArr = res.data.data;
          });
    },

    // 获取工会列表
    getGuildList() {
      this.$api
          .getGuildList()
          .then((res) => {
            this.dataGuild = res.data.data;
          });
    },

    // 查询国家代码
    countryCodeList() {
      this.$api
          .regionList()
          .then((res) => {
            this.dataCountry = res.data.data;
            console.log(this.dataCountry)
          });
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.anchorSummaryPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.anchorSummaryPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.anchorSummaryPageList();
    },

  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
