import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import {CL} from "../Tools/loading"; // 开启/关闭加载图
import {isCK} from "../Tools/storage"; // 本地储存LS与服务器储存SS
import {wMsg} from "../Tools/msg"; // 警告信息 s成功 w警告 e错误 (msgtext)
import login from "../components/view/login.vue"; //登录视图
import home from "../components/view/home.vue"; // 主页视图
import info from "../components/view/info"; // 用户中心
import mainIndex from "../components/view/mainIndex"; // 登录引导页
import menuSettings from "../components/view/sys/menuSettings"; // 菜单设置
import roleSettings from "../components/view/sys/roleSettings"; // 角色设置
import dictionariesSettings from "../components/view/sys/dictionariesSettings"; // 词典设置
import basicPersonnel from "../components/view/sys/basicPersonnel"; // 基本人员设置
import structureSettings from "../components/view/sys/structureSettings"; // 组织结构管理
import personnelSettings from "../components/view/sys/personnelSettings"; // 用户管理
import userList from "../components/view/manage/userList"; // 会员用户管理
import orderList from "../components/view/manage/orderList"; // 充值记录
import talkRecord from "../components/view/manage/talkRecord"; //聊天消息记录
import agent from "../components/view/manage/agent"; //聊天消息记录
import guildOfManage from "../components/view/manage/guild"; //聊天消息记录
import guildOfAgent from "../components/view/agent/guild"; //聊天消息记录
import anchorApplyList from "../components/view/manage/anchorApplyList"; //主播审核表
import anchorApplyHistory from "../components/view/manage/anchorApplyHistory"; //主播审核记录表
import anchorOfManage from "../components/view/manage/anchor"; //主播管理
import anchorOfAgent from "../components/view/agent/anchor"; //主播审核记录表
import anchorOfGuild from "../components/view/guild/anchor"; //主播审核记录表
import percentage from "../components/view/manage/percentage"; //提成设置查看
import costInfo from "../components/view/manage/costList"; //消费信息查看
import userListByRole from "../components/view/manage/userListByRole"; // 登录登录用户查用户列表
import videoCallList from "../components/view/manage/videoCallList"; // 登录登录用户查用户列表
import sysAdminLogList from "../components/view/manage/sysAdminLogList"; // 操作日志列表
import regionAgentSettings from "../components/view/sys/regionAgentSettings"; // 操作日志列表
import momentList from "../components/view/manage/momentList"; // 动态列表
import reportInfoList from "../components/view/manage/reportInfoList"; // 举报列表
import agoraList from "../components/view/manage/agoraList"; // 频道列表
import statistics from "../components/view/manage/statistics"; // 统计列表
import blackIp from "../components/view/manage/blackIp"; // ip黑名单列表
import video from "../components/view/manage/video"; // 视频列表
import gift from "../components/view/manage/gift"; // 礼物列表
import sysMsg from "../components/view/manage/sysMsg"; // 系统消息列表
import orderListByAnchor from "../components/view/manage/orderListByAnchor"; // 主播充值列表查看
import anchorSummary from "../components/view/manage/anchorSummary"; // 主播汇总列表
import blackList from "../components/view/manage/blackList"; // 拉黑列表
import costListByAnchor from "../components/view/manage/costListByAnchor"; // 主播充值列表查看


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  {
    path: "/home",
    component: home,
    // redirect: '/userList',
    children: [
      {
        path: "/home",
        name: "mainIndex",
        component: mainIndex,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
          ],
        },
      },
      {
        path: "/info",
        name: "info",
        component: info,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "",
              text: "用户中心",
            },
          ],
        },
      },
      {
        path: "/menuSettings",
        name: "menuSettings",
        component: menuSettings,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "",
              text: "系统设置",
            },
            {
              to: "",
              text: "菜单设置",
            },
          ],
        },
      },
      {
        path: "/roleSettings",
        name: "roleSettings",
        component: roleSettings,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "",
              text: "系统设置",
            },
            {
              to: "",
              text: "角色设置",
            },
          ],
        },
      },
      {
        path: "/dictionariesSettings",
        name: "dictionariesSettings",
        component: dictionariesSettings,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "",
              text: "系统设置",
            },
            {
              to: "",
              text: "字典设置",
            },
          ],
        },
      },
      {
        path: "/basicPersonnel",
        name: "basicPersonnel",
        component: basicPersonnel,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "",
              text: "用户管理",
            },
            {
              to: "",
              text: "基本人员设置",
            },
          ],
        },
      },
      {
        path: "/structureSettings",
        name: "structureSettings",
        component: structureSettings,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "",
              text: "组织结构",
            },
          ],
        },
      },
      {
        path: "/personnelSettings",
        name: "personnelSettings",
        component: personnelSettings,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "",
              text: "用户管理",
            },
            {
              to: "",
              text: "角色分配",
            },
          ],
        },
      },
      {
        path: "/userList",
        name: "userList",
        component: userList,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "",
              text: "用户列表",
            },
          ],
        },
      },
      {
        path: "/orderList",
        name: "orderList",
        component: orderList,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "",
              text: "充值列表",
            },
          ],
        },
      },
      {
        path: "/talkRecord",
        name: "talkRecord",
        component: talkRecord,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "",
              text: "消息列表",
            },
          ],
        },
      },
      {
        path: "/agent",
        name: "agent",
        component: agent,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "",
              text: "组织管理",
            },
            {
              to: "",
              text: "一级代理",
            }
          ],
        },
      },
      {
        path: "/guildOfManage",
        name: "guildOfManage",
        component: guildOfManage,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "",
              text: "组织管理",
            },
            {
              to: "",
              text: "公会管理",
            }
          ],
        },
      },
      {
        path: "/guildOfAgent",
        name: "guildOfAgent",
        component: guildOfAgent,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "/anchorApplyList",
              text: "公会管理",
            }
          ],
        },
      },
      {
        path: "/anchorApplyList",
        name: "anchorApplyList",
        component: anchorApplyList,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "/anchorApplyList",
              text: "主播审核管理",
            },
          ],
        },
      },
      {
        path: "/anchorApplyHistory",
        name: "anchorApplyHistory",
        component: anchorApplyHistory,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "/anchorApplyHistory",
              text: "主播审核记录",
            },
          ],
        },
      },
      {
        path: "/percentage",
        name: "percentage",
        component: percentage,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "/percentage",
              text: "提成设置",
            },
          ],
        },
      },
      {
        path: "/costInfo",
        name: "costInfo",
        component: costInfo,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "/costInfo",
              text: "消费记录",
            },
          ],
        },
      },
      {
        path: "/anchorOfManage",
        name: "anchorOfManage",
        component: anchorOfManage,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "",
              text: "组织管理",
            },
            {
              to: "",
              text: "主播管理",
            }
          ],
        },
      },
      {
        path: "/anchorOfAgent",
        name: "anchorOfAgent",
        component: anchorOfAgent,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "/anchorApplyList",
              text: "主播管理",
            }
          ],
        },
      },
      {
        path: "/anchorOfGuild",
        name: "anchorOfGuild",
        component: anchorOfGuild,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            },
            {
              to: "/anchorApplyList",
              text: "主播管理",
            }
          ],
        },
      },
      {
        path: "/userListByRole",
        name: "userListByRole",
        component: userListByRole,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/userListByRole",
              text: "用户列表",
            }
          ],
        },
      },
      {
        path: "/videoCallList",
        name: "videoCallList",
        component: videoCallList,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/videoCallList",
              text: "通话记录",
            }
          ],
        },
      },
      {
        path: "/sysAdminLogList",
        name: "sysAdminLogList",
        component: sysAdminLogList,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/sysAdminLogList",
              text: "操作日志",
            }
          ],
        },
      },
      {
        path: "/regionAgentSettings",
        name: "regionAgentSettings",
        component: regionAgentSettings,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/regionAgentSettings",
              text: "代理设置",
            }
          ],
        },
      },
      {
        path: "/momentList",
        name: "momentList",
        component: momentList,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/momentList",
              text: "动态管理",
            }
          ],
        },
      },
      {
        path: "/reportInfoList",
        name: "reportInfoList",
        component: reportInfoList,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/reportInfoList",
              text: "举报管理",
            }
          ],
        },
      },
      {
        path: "/agoraList",
        name: "agoraList",
        component: agoraList,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/agoraList",
              text: "视频管理",
            }
          ],
        },
      },
      {
        path: "/statistics",
        name: "statistics",
        component: statistics,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/statistics",
              text: "数据统计",
            }
          ],
        },
      },
      {
        path: "/blackIp",
        name: "blackIp",
        component: blackIp,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/blackIp",
              text: "IP列表",
            }
          ],
        },
      },
      {
        path: "/video",
        name: "video",
        component: video,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/video",
              text: "视频列表",
            }
          ],
        },
      },
      {
        path: "/gift",
        name: "gift",
        component: gift,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/gift",
              text: "礼物列表",
            }
          ],
        },
      },
      {
        path: "/sysMsg",
        name: "sysMsg",
        component: sysMsg,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/sysMsg",
              text: "系统消息列表",
            }
          ],
        },
      },
      {
        path: "/orderListByAnchor",
        name: "orderListByAnchor",
        component: orderListByAnchor,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/orderListByAnchor",
              text: "充值明细",
            }
          ],
        },
      },
      {
        path: "/anchorSummary",
        name: "anchorSummary",
        component: anchorSummary,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/anchorSummary",
              text: "充值明细",
            }
          ],
        },
      },
      {
        path: "/blackList",
        name: "blackList",
        component: blackList,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/blackList",
              text: "拉黑管理",
            }
          ],
        },
      },
      {
        path: "/costListByAnchor",
        name: "costListByAnchor",
        component: costListByAnchor,
        meta: {
          data: [
            {
              to: "/home",
              text: "首页",
            }, {
              to: "/costListByAnchor",
              text: "消费明细",
            }
          ],
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  CL();
  if (isCK("token")) {
    // if (to.path != "/home" && to.path != "/") {
    //   if (store.state.pageState == 0) {
    //     next({path: "/home"});
    //   } else {
    //     next();
    //   }
    // } else {
    //   next();
    // }
    if(to.path === "/home") {
      window.localStorage.setItem('activePage', '')
      store.commit('setActivePage')
    }
    next()
  } else {
    if (to.path != "/") {
      wMsg("请先登录");
      next({path: "/"});
    } else {
      next();
    }
  }
});

router.afterEach((to, from) => {
  CL();
});

export default router;
