<template>
  <div class="card">
    <el-tabs v-model="activeName" style="height: 100%">
      <el-tab-pane label="用户列表" name="1" style="height: 100%">
        <el-row class="mb-15">
          <el-input
            v-model="ss.id"
            class="w200 mr-15"
            size="small"
            placeholder="请输入用户id"
            clearable
          ></el-input>

          <el-input
              v-model="ss.anchorId"
              class="w200 mr-15"
              size="small"
              placeholder="请输入主播id"
              clearable
          ></el-input>

          <el-date-picker
            v-model="selectTime"
            type="datetimerange"
            size="small"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          />

          &nbsp;
          <el-select v-model="ss.country" size="small" placeholder="选择国家" filterable style="width: 180px" clearable>
            <el-option
                v-for="(v, k) in dataScopeArr"
                :label="v.sc"
                :value="v.locale"
                :key="k"
            ></el-option>
          </el-select>
          &nbsp;
          <el-select v-model="ss.net" size="small" placeholder="状态" style="width: 150px;" clearable>
            <el-option label="在线" value="1"></el-option>
            <el-option label="离线" value="0"></el-option>
          </el-select>
          &nbsp;
          <el-button type="primary" size="small" @click="query">搜索</el-button>
          <el-button type="success" size="small" @click="downloadUser()" :disabled="exportDisabled"
                     v-if="$checkArr('user:downloadUser')">导出</el-button>
        </el-row>
        <div class="table150">
          <el-table :data="tableData" border height="100%" v-loading="loading">
            <el-table-column label="序号" type="index" align="center" width="50" fixed />
            <el-table-column label="用户名" prop="name" align="center"></el-table-column>
            <el-table-column label="用户id" prop="id" align="center"></el-table-column>
            <el-table-column label="头像" align="center">
              <template slot-scope="scope">
                <img v-if="scope.row.avatar" :src="scope.row.avatar" style="height: 50px; width: 50px">
                <img v-else src="../../../assets/home/default_head.png" style="height: 50px; width: 50px">
              </template>
            </el-table-column>
            <el-table-column label="国家" prop="country" align="center">
              <template slot-scope="scope">
                <div v-for="(item,key,index) in dataScopeArr" :key="index">
                  <span v-if="scope.row.country == item.locale">{{item.sc}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="性别" prop="gender" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.gender == 1">男</span>
                <span v-else>女</span>
              </template>
            </el-table-column>
            <el-table-column label="所属推广" prop="belongAnchor" align="center"></el-table-column>
            <el-table-column label="在线状态" prop="net" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.net == 1" class="el-icon-success" style="color: #7AE552FF; font-size: 25px"></span>
                <span v-else class="el-icon-error" style="font-size: 25px"></span>
              </template>
            </el-table-column>
            <el-table-column label="最后一次登陆信息(CST/UTC)" prop="lastLoginTime" width="220" align="center">
              <template v-if="scope.row.lastLoginTime" slot-scope="scope">
                <div>{{ scope.row.lastLoginTime | TM2CST }}</div>
                <div>{{ scope.row.lastLoginTime | TM2UTC }}</div>
              </template>
            </el-table-column>
            <el-table-column label="注册时间(CST/UTC)" prop="createTime" width="170" align="center">
              <template v-if="scope.row.createTime" slot-scope="scope">
                <div>{{ scope.row.createTime | TM2CST }}</div>
                <div>{{ scope.row.createTime | TM2UTC }}</div>
              </template>
            </el-table-column>
            <el-table-column label="账号状态" prop="status" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1">注销</span>
                <span v-else>正常</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          class="mt-15"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageObj.pageIndex"
          :page-sizes="page.sizes"
          :page-size.sync="pageObj.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CountryCode from "@/components/json/countryCode.json";
import {TM2UTC} from "@/components/js/date";

export default {
  mounted() {
    if (this.$checkArr("webUser:rolePageList")) {
      this.userPageList("初始化");
      this.countryCodeList();
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      // 查询
      selectTime: null,
      ss: {
        id: "",
        country: "",
        net: '',
        anchorId: '',
        startTime: '',
        endTime: ''
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20,
      },
      // 表格
      tableData: [],
      loading: false,

      formData: {},
      formTitle: "修改人员",
      dataScopeArr: [],
      exportDisabled: false,
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询用户列表--分页
    userPageList(type) {
      this.loading = true
      this.$api.rolePageList({
        form: {...this.ss},
        ...this.pageObj
      })
      .then((res) => {
        this.page.total = res.data.data.total;
        this.tableData = res.data.data.list.map(i => {
          if(i.avatar) {
            i.avatar = i.avatar.includes('http') ? i.avatar : this.$imageUrl + i.avatar
          }
          return i
        })
        if (type == "初始化") {
        }
      });
      this.loading = false
    },
    // 搜索
    query() {
      this.getTimes()
      this.pageObj.pageIndex = 1
      this.userPageList()
    },
    getTimes() {
      this.ss.startTime = this.selectTime ? TM2UTC(this.selectTime[0]) : ''
      this.ss.endTime = this.selectTime ? TM2UTC(this.selectTime[1]) : ''
    },

    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.activeName = "2";
      this.formTitle = "修改人员";
    },


    // 重置密码
    rePwd(v) {
      this.$wTx(
        "该操作将重置用户：" + v.name + "的登录密码，确认继续吗？",
        () => {
          this.$OL("正在重置密码...");
          this.$api.webUserResetPassword({ id: v.id }).then((res) => {
            this.$CL();
            this.$sMsg("密码重置成功");
            this.userPageList();
          });
        },
        () => {
          this.$iMsg("已取消重置密码操作");
        }
      );
    },

    // 删除
    removeAvatar(v) {
      this.$wTx(
          "该操作将删除该用户头像，确认继续吗？",
          () => {
            this.$OL("正在删除中...");
            this.$api
                .deleteAvatar({ id: v })
                .then((res) => {
                  this.$sMsg("删除成功");
                  this.back();
                  this.$CL();
                });
          },
          () => {
            this.$iMsg("已取消删除");
          }
      );
    },

    // 查询国家代码
    countryCodeList() {
      // this.dataScopeArr = CountryCode.country
      this.$api
          .regionList()
          .then((res) => {
            this.dataScopeArr = res.data.data;
            console.log(this.dataScopeArr)
          });

    },

    // 用户导出列表
    downloadUser() {
      this.getTimes()
      let user = this.$getCK("user");
      var officeId = user.currentOfficeId;
      var sysUserId = user.cUserId;

      this.exportDisabled = true
      let startTime = ""
      let endTime = ""
      if (this.ss.startTime !== undefined && this.ss.startTime.length > 0) {
        startTime = this.ss.startTime;
      }
      if (this.ss.endTime !== undefined && this.ss.endTime.length > 0) {
        endTime = this.ss.endTime;
      }
      window.location.href = 'https://boss-api.yaku.online/rpc/admin/downloadUser?startTime=' + startTime + "&endTime=" + endTime + "&officeId=" + officeId + "&sysUserId=" + sysUserId
      // window.location.href = 'http://127.0.0.1:8187/rpc/admin/downloadUser?startTime=' + startTime + "&endTime=" + endTime + "&officeId=" + officeId + "&sysUserId=" + sysUserId
      // alert(dz_url)
      this.exportDisabled = false
    },


    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "修改人员";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "修改人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
