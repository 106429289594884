<template>
  <div class="card">
    <el-row class="mb-15">
        <el-input v-model="ss.userId" class="w200 mr-15" size="small" placeholder="请输入用户id" clearable />
        <el-input v-model="ss.otherId" class="w200 mr-15" size="small" placeholder="请输入消费对象id" clearable />
        <el-select v-model="ss.type" size="small" placeholder="消费类型" clearable>
          <el-option label="视频通话" value="1" />
          <el-option label="聊天翻译" value="2" />
          <el-option label="动态翻译" value="3" />
          <el-option label="评论翻译" value="5" />
          <el-option label="视频聊天翻译" value="9" />
          <el-option label="视频赠送礼物" value="4" />
          <el-option label="聊天赠送礼物" value="6" />
          <el-option label="超级赞" value="7" />
          <el-option label="性别筛选" value="8" />
          <el-option label="金币消费" value="10" />
        </el-select>
        &nbsp;&nbsp;
        <el-date-picker
          v-model="selectTime"
          type="datetimerange"
          size="small"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          :picker-options="pickerOptions"
        />
        &nbsp;
        <!-- <el-date-picker v-model="ss.startTime" class="mr-15" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
          default-time="00:00:00" placeholder="选择开始日期">
        </el-date-picker>

        <el-date-picker v-model="ss.endTime" class="mr-15" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
          default-time="00:00:00" placeholder="选择结束日期">
        </el-date-picker> -->
        <el-button type="primary" size="small" @click="query">搜索</el-button>
        <el-button v-if="$checkArr('cost:downloadOrder')" type="success" size="small" @click="downloadCost()" :disabled="exportDisabled">
          导出
        </el-button>
    </el-row>
    <span>支出金额：{{ expenditure }}</span> &nbsp;&nbsp; &nbsp;&nbsp;
    <span>消费时长(分)：{{ total }}</span> &nbsp;&nbsp; &nbsp;&nbsp;
    <span>消费时长(秒)：{{ secondNum }}</span>
    <div class="table190">
      <el-table :data="tableData" border height="100%" v-loading="loading">
        <el-table-column label="序号" type="index" align="center" width="50" fixed />
        <el-table-column label="用户id" prop="userId" align="center"></el-table-column>
        <el-table-column label="用户昵称" prop="userName" align="center"></el-table-column>
        <el-table-column label="消费对象id" prop="otherId" align="center"></el-table-column>
        <el-table-column label="消费对象昵称" prop="otherName" align="center"></el-table-column>
        <el-table-column label="消费类型" prop="type" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">通话时长</span>
            <span v-else-if="scope.row.type == 2">聊天翻译</span>
            <span v-else-if="scope.row.type == 3">动态翻译</span>
            <span v-else-if="scope.row.type == 4">视频赠送礼物</span>
            <span v-else-if="scope.row.type == 5">评论翻译</span>
            <span v-else-if="scope.row.type == 6">聊天赠送礼物</span>
            <span v-else-if="scope.row.type == 7">超级赞</span>
            <span v-else-if="scope.row.type == 8">筛选性别</span>
            <span v-else-if="scope.row.type == 9">视频聊天翻译</span>
            <span v-else-if="scope.row.type == 10">金币消费</span>
          </template>
        </el-table-column>
        <el-table-column label="消费数量(分/个)" prop="total" align="center"></el-table-column>
        <el-table-column label="消费时长(秒)" prop="secondNum" align="center"></el-table-column>
        <el-table-column label="支出金额" prop="expenditure" align="center"></el-table-column>
        <el-table-column label="开始时间(CST/UTC)" prop="startTime" width="170" align="center">
          <template v-if="scope.row.startTime" slot-scope="scope">
            <div>{{ scope.row.startTime | TM2CST }}</div>
            <div>{{ scope.row.startTime | TM2UTC }}</div>
          </template>
        </el-table-column>
        <el-table-column label="结束时间(CST/UTC)" prop="endTimeStr" width="170" align="center">
          <template v-if="scope.row.endTime" slot-scope="scope">
            <div>{{ scope.row.endTime | TM2CST }}</div>
            <div>{{ scope.row.endTime | TM2UTC }}</div>
          </template>
        </el-table-column>
        <el-table-column label="插入时间(CST/UTC)" prop="insertTime" width="170" align="center">
          <template v-if="scope.row.insertTime" slot-scope="scope">
            <div>{{ scope.row.insertTime | TM2CST }}</div>
            <div>{{ scope.row.insertTime | TM2UTC }}</div>
          </template>
        </el-table-column>
        <el-table-column label="提成" prop="commission" align="center"></el-table-column>
        <el-table-column label="msgId" prop="msgId" align="center">
          <template slot-scope="scope">
            <span>
              <el-button type="text" @click="handleMsg(scope.row.msgId)">{{ scope.row.msgId }}</el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt-15" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="pageObj.pageIndex" :page-sizes="page.sizes" :page-size.sync="pageObj.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="page.total">
    </el-pagination>

    <el-dialog title="信息" :visible.sync="msgVisible" width="30%" :before-close="handleClose">

      <div style="margin-bottom: 30px"> <span>内容：{{ content }}</span></div>
      <div><span>翻译：{{ trans }}</span></div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="msgVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { TM2UTC } from '@/components/js/date.js'
export default {
  mounted() {
    if (this.$checkArr("costInfo:pageList")) {
      this.userPageList("初始化");
      this.costTotalInfo()
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      // 查询
      selectTime: null,
      ss: {
        userId: '',
        otherId: '',
        startTime: '',
        endTime: '',
        type: ''
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20
      },
      // 收益
      expenditure: '--',
      total: '--',
      secondNum: '--',
      // 表格
      loading: false,
      tableData: [],
      
      formData: {},
      formTitle: "添加人员",
      exportDisabled: false,
      msgVisible: false,
      content: "",
      trans: "",

      pickerOptions: {
        shortcuts: [{
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setHours(0);
            end.setMinutes(0);
            end.setSeconds(0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setTime(start.getTime());
            end.setHours(0);
            end.setMinutes(0);
            end.setSeconds(0);
            end.setTime(end.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            end.setHours(0);
            end.setMinutes(0);
            end.setSeconds(0);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询用户列表--分页
    userPageList(type) {
      this.loading = true
      this.$api.costList({
        form: {...this.ss},
        ...this.pageObj
      }).then((res) => {
        this.page.total = res.data.data.total;
        this.tableData = res.data.data.list;
        if (type == "初始化") {
        }
        this.loading = false
      })
    },
    // 统计消费金额
    costTotalInfo(type) {
      this.expenditure = '--'
      this.total = '--'
      this.secondNum = '--'
      this.$api.costTotalInfo({
        ...this.ss
      }).then((res) => {
        this.expenditure = res.data.data.expenditure;
        this.total = res.data.data.total;
        this.secondNum = res.data.data.secondNum;
        if (type == "初始化") {
        }
      })
    },
    // 查询
    query() {
      this.getTimes()
      this.pageObj.pageIndex = 1
      this.userPageList()
      this.costTotalInfo()
    },
    getTimes() {
      this.ss.startTime = this.selectTime ? TM2UTC(this.selectTime[0]) : ''
      this.ss.endTime = this.selectTime ? TM2UTC(this.selectTime[1]) : ''
    },

    

    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.activeName = "2";
      this.formTitle = "修改人员";
    },



    // 导出列表
    downloadCost() {
      this.getTimes()
      let user = this.$getCK("user");
      var officeId = user.currentOfficeId;
      var sysUserId = user.cUserId;

      this.exportDisabled = true
      let startTime = ""
      let endTime = ""
      let type = 0
      if (this.ss.startTime !== undefined && this.ss.startTime.length > 0) {
        startTime = this.ss.startTime;
      }
      if (this.ss.endTime !== undefined && this.ss.endTime.length > 0) {
        endTime = this.ss.endTime;
      }
      if (this.ss.type !== undefined && this.ss.type.length > 0) {
        type = this.ss.type;
      }
      window.location.href = 'https://boss-api.yaku.online/rpc/admin/downloadCost?startTime=' + startTime + "&endTime=" + endTime + "&type=" + type + "&officeId=" + officeId + "&sysUserId=" + sysUserId
      // window.location.href = 'http://127.0.0.1:8187/rpc/admin/downloadCost?startTime='+ startTime +"&endTime="+endTime + "&type=" + type + "&officeId=" + officeId + "&sysUserId=" + sysUserId
      this.exportDisabled = false

    },

    //查看消息
    handleMsg(v) {
      this.msgVisible = true;
      this.$api
        .getTalkRecordById({
          id: v,
        })
        .then((ress) => {
          debugger;
          this.content = ress.data.data.content;
          this.trans = ress.data.data.trans;
        });

    },

    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {
        });
    },


    // 上传
    upFile(dom) {
      this.$OL("正在上传文件...");
      let form = new FormData();
      form.append("file", dom.files[0]);
      this.$api
        .userModifyPwd(form)
        .then((res) => {
          this.userPageList();
          this.$CL();
          dom.remove();
          this.sMsg("导入成功");
        })
        .catch(() => {
          this.$CL();
          dom.remove();
        });
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加人员";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
