<template>
  <div class="loginFrom">
    <div class="title">
      <h1>Yaku后台管理系统</h1>
    </div>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="80px"
    >
      <el-form-item label="工号" prop="act">
        <el-input v-model="formData.act" @keyup.enter.native="login()" />
      </el-form-item>
      <el-form-item label="密码" prop="pwd">
        <el-input
          v-model="formData.pwd"
          show-password
          @keyup.enter.native="login()"
        />
      </el-form-item>
      <el-form-item label="验证码">
        <template>
          <div class="flex">
            <el-input
              v-model="formData.yzCode"
              style="width: calc(100% - 100px)"
              @keyup.enter.native="login()"
            />
            <canvas
                ref="canvas"
                width="100"
                height="40"
                @click="$draw(vfCode, $refs.canvas)"
            ></canvas>
          </div>
        </template>
      </el-form-item>
      <el-button type="primary" class="loginBtn" @click="login()">登录</el-button>
      <el-form-item class="mt-15" label-width="0px">
        <div class="flex fd-c">
          <span>技术支持：河南羽锐科技有限公司</span>
          <span>联系电话：1111111111</span>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "login",
  mounted() {
    this.$draw(this.vfCode, this.$refs.canvas);
  },
  data() {
    return {
      vfCode: [],
      formData: {
        act: "",
        pwd: "",
        yzCode: "",
      },
      rules: {
        act: [
          { required: true, message: '请输入工号', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    keyUp(e) {
      console.log(e);
    },

    login() {
      this.$refs.form
          .validate()
          .then(() => {
            if (this.formData.yzCode.toUpperCase() == this.vfCode.join("").toUpperCase()) {
              this.$OL("登陆中...");
              this.$api
                  .login({
                    username: this.formData.act,
                    password: this.formData.pwd,
                  })
                  .then((res) => {
                    this.$setCK("token", res.data.data.token);
                    this.$setCK("user", JSON.stringify(res.data.data));
                    // 保存当前角色层级
                    this.$api.loginGetLevelOfCurrentRole().then((res) => {
                      this.$setCK("userInfo", JSON.stringify(res.data.data));
                      // 保存权限集合
                      this.$api.getPermissions().then((res) => {
                        this.$setCK("r", JSON.parse(JSON.stringify(res.data.data))); // 权限集合
                        window.localStorage.setItem('activePage', '')
                        this.$router.push({
                          path: "/home",
                        });
                      });
                    });
                  });
            } else {
              this.$wMsg("验证码错误");
            }
          })
          .catch(() => {
            $wMsg("请填写完整信息");
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 65px;
}

.loginBtn {
  width: 100%;
}
</style>
