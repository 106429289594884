var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showFalg)?_c('div',[_c('el-menu',{staticClass:"el-menu-vertical-demo el-menu-title",attrs:{"text-color":"#ffffff","background-color":"#050350","active-text-color":"#ffffff","router":"","unique-opened":"","default-active":_vm.activePage},on:{"select":_vm.select}},[_vm._l((_vm.menuData),function(v){return [(v.children)?[_c('el-submenu',{key:v.id,attrs:{"index":v.id}},[_c('template',{slot:"title"},[_c('i',{class:v.icon}),_c('el-badge',{staticClass:"badge",attrs:{"type":"success","value":_vm.x1 + _vm.x2 + _vm.x3,"hidden":v.id != '58adc6a15ca54d399be1620eb789ef97' || _vm.x1 + _vm.x2 + _vm.x3 == 0}},[_c('span',[_vm._v(_vm._s(v.name))])])],1),_vm._l((v.children),function(rv){return [(rv.children)?_c('el-submenu',{key:rv.id,attrs:{"index":rv.id}},[_c('template',{slot:"title"},[_c('i',{class:rv.icon}),_c('span',[_vm._v(_vm._s(rv.name))])]),_vm._l((rv.children),function(kv){return _c('el-menu-item',{key:kv.id,attrs:{"index":kv.href}},[_c('i',{class:kv.icon}),_c('span',[_vm._v(_vm._s(kv.name))])])})],2):_c('el-menu-item',{attrs:{"index":rv.href}},[_c('i',{class:rv.icon}),_c('el-badge',{staticClass:"badge",attrs:{"value":_vm.hiddenJudge(rv).v == 1
                    ? _vm.x1
                    : _vm.hiddenJudge(rv).v == 2
                      ? _vm.x2
                      : _vm.hiddenJudge(rv).v == 3
                        ? _vm.x3
                        : 0,"hidden":_vm.hiddenJudge(rv).f ||
                    (_vm.hiddenJudge(rv).v == 1
                      ? _vm.x1 == 0
                      : _vm.hiddenJudge(rv).v == 2
                        ? _vm.x2 == 0
                        : _vm.hiddenJudge(rv).v == 3
                          ? _vm.x3 == 0
                          : true)}},[_c('span',[_vm._v(_vm._s(rv.name))])])],1)]})],2)]:[_c('el-menu-item',{key:v.id,attrs:{"index":v.href}},[_c('i',{class:v.icon}),_c('el-badge',{staticClass:"badge",attrs:{"type":"success","value":_vm.hiddenJudge(v).v == 1
                ? _vm.x1
                : _vm.hiddenJudge(v).v == 2
                  ? _vm.x2
                  : _vm.hiddenJudge(v).v == 3
                    ? _vm.x3
                    : 0,"hidden":_vm.hiddenJudge(v).f ||
  (_vm.hiddenJudge(v).v == 1
    ? _vm.x1 == 0
    : _vm.hiddenJudge(v).v == 2
      ? _vm.x2 == 0
      : _vm.hiddenJudge(v).v == 3
        ? _vm.x3 == 0
        : true)}},[_c('span',[_vm._v(_vm._s(v.name))])])],1)]]})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }