<template>
  <div class="card">
    <el-tabs v-model="activeName" @tab-click="tabChenge" style="height: 100%;">
      <el-tab-pane label="代理列表" name="1" style="height: 100%;">
        <el-row class="mb-15">
          <el-input v-model="ss.name" class="w200 mr-15" size="small" placeholder="请输入名称" clearable />
          <el-button type="primary" size="small" @click="query">搜索</el-button>
        </el-row>
        <div class="table150">
          <el-table :data="tableData" border height="100%" v-loading="loading">
            <el-table-column label="序号" type="index" width="50" align="center" fixed />
            <el-table-column label="代理ID" prop="id" />
            <el-table-column label="名称" prop="name" />
            <el-table-column label="备注" prop="remark" />
            <el-table-column label="创建时间(CST/UTC)" prop="createDate" width="170" align="center">
              <template v-if="scope.row.createDate" slot-scope="scope">
                <div>{{ scope.row.createDate | TM2CST }}</div>
                <div>{{ scope.row.createDate | TM2UTC }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="350" v-if="$checkArr('bi:agent:oper')">
              <template slot-scope="scope">
                <el-button type="warning" size="mini" @click="modify(scope.row)" v-if="$checkArr('bi:agent:oper')">修改
                </el-button>
                <!--              <el-button-->
                <!--                  type="danger"-->
                <!--                  size="mini"-->
                <!--                  @click="rePwd(scope.row)"-->
                <!--                  v-if="$checkArr('bi:agent:oper')"-->
                <!--              >重置密码-->
                <!--              </el-button>-->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination class="mt-15" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="pageObj.pageIndex" :page-sizes="page.sizes" :page-size.sync="pageObj.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="page.total">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('bi:agent:oper')">
        <el-row>
          <el-col :span="12">
            <el-form ref="form" :model="formData" :rules="{
              name: [
                {
                  required: true,
                  message: '该项目不能为空',
                  trigger: 'blur',
                }
              ]
            }" label-width="150px" label-position="right">
              <el-form-item label="代理名称" prop="name">
                <el-input v-model="formData.name" placeholder="" :disabled="isDisabled"></el-input>
              </el-form-item>
              <el-form-item label="账号名称" prop="loginName">
                <el-input v-model="formData.loginName" placeholder="" :disabled="isDisabled"></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input v-model="formData.remark" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-button type="success" @click="
                  $refs.form
                    .validate()
                    .then(() => {
                      save();
                    })
                    .catch(() => {
                      $wMsg('请填写完整信息');
                    })
                ">保存
                </el-button>
                <el-button type="info" @click="back()">返回</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { formatDate } from '@/components/js/date.js'
export default {
  mounted() {
    if (this.$checkArr("bi:agent:view")) {
      this.agentPageList("初始化");
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      
      isDisabled: false,

      // 查詢
      ss: {
        name: ""
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20
      },
      // 表格
      loading: false,
      tableData: [],

      formData: {},
      formTitle: "添加代理",
    };
  },
  filters: {
    formatDate(time) {
      time = time
      let date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    }
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    tabChenge(tab, event) {
      console.log(tab, event);
      if (tab.name === '1') {
        this.isDisabled = false;
        this.activeName = "1";
      }
    },

    // 查询用户列表--分页
    agentPageList(type) {
      this.loading = true
      this.$api.findAgentPage({
        form: {...this.ss},
        ...this.pageObj
      }).then((res) => {
        this.activeName = "1";
        this.page.total = res.data.data.total;
        this.tableData = res.data.data.list;
        if (type == "初始化") {
        }
        this.loading = false
      });
    },
    // 查询
    query() {
      this.pageObj.pageIndex = 1
      this.agentPageList()
    },

    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.activeName = "2";
      this.formTitle = "修改代理";
      this.isDisabled = true;
    },

    // 删除
    remove(v, falg) {
      this.$wTx(
        "该操作将" + (falg ? "永久" : "") + "删除当前代理条目，确认继续吗？",
        () => {
          this.$OL("正在删除...");
          this.$api[falg ? "agentRelDel" : "agentDel"]({ id: v.id }).then((res) => {
            this.back();
            this.$CL();
            this.$sMsg("删除成功");
          });
        },
        () => {
          this.$iMsg("已取消删除");
        }
      );
    },

    // 重置密码
    rePwd(v) {
      this.$wTx(
        "该操作将重置用户：" + v.name + "的登录密码，确认继续吗？",
        () => {
          this.$OL("正在重置密码...");
          this.$api.agentResetPassword({ id: v.id }).then((res) => {
            this.$CL();
            this.$sMsg("密码重置成功");
            this.agentPageList();
          });
        },
        () => {
          this.$iMsg("已取消重置密码操作");
        }
      );
    },

    // 保存
    save() {
      if (this.formTitle == "添加代理") {
        this.$api
          .agentSave({
            id: "",
            name: this.formData.name,
            loginName: this.formData.loginName,
            remark: this.formData.remark
          })
          .then((ress) => {
            this.$CL();
            this.$sMsg("添加成功");
            this.back();
          });
      } else {
        this.$api
          .agentUpdate({
            id: this.formData.id,
            //name: this.formData.name,
            remark: this.formData.remark
          })
          .then((ress) => {
            this.$CL();
            this.$sMsg("修改成功");
            this.back();
          });
      }

    },


    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加代理";
      this.isDisabled = false;
      this.agentPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.agentPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.agentPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加代理";
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
