<template>
  <el-card>
    <el-tabs v-model="activeName">
      <el-tab-pane label="短信" name="1">
        <el-row class="mb-15">
          <el-select v-model="ss.region" filterable placeholder="请选择区号" clearable>
            <el-option
                v-for="(v, k) in this.regionCodeArr"
                :key="'区域' + k"
                :label="v.name"
                :value="v.phoneCode"
            ></el-option>
          </el-select>
          <el-select v-model="ss.agent" placeholder="请选择代理" clearable>
            <el-option
                v-for="(v, k) in this.agentCodeArr"
                :key="'代理' + k"
                :label="v.name"
                :value="v.value"
            ></el-option>
          </el-select>
          <el-button type="primary" @click="regionAgentGet()">查询</el-button>
          <el-button type="primary" @click="regionAgentSet()">设置</el-button>
          <el-button type="primary" @click="regionAgentClear()">清空</el-button>
          <el-button type="primary" @click="regionAgentReal()">实际</el-button>
          <el-button type="primary" @click="removeSmsAgent()">删除代理</el-button>
        </el-row>

        <el-row class="mb-15">
          <el-input
              v-model="agentQueueStr"
              class="w250 mr-15"
              clearable
          ></el-input>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import CountryCode from '@/components/json/countryCode.json'
import AgentCode from '@/components/json/agentCode.json'

export default {
  mounted() {
    this.regionCodeList();
    this.agentCodeList();
  },
  data() {
    return {
      agentQueueStr: "",
      activeName: "1",
      ss: {
        anchorId: "",
        guildId: ""
      },
      regionCodeArr: [],
      agentCodeArr: []
    };
  },
  methods: {
    // 设置代理
    regionAgentSet() {
      this.$api
          .regionAgentSet({
            region: this.ss.region,
            agent: this.ss.agent
          })
          .then((res) => {
            this.$CL();
            this.$sMsg("设置成功");
          });
    },


    // 查询代理
    regionAgentGet() {
      this.$api
          .regionAgentGet({
            region: this.ss.region,
            agent: this.ss.agent
          })
          .then((res) => {
            this.agentQueueStr = res.data.data;
          });
    },


    // 清空代理
    regionAgentClear() {
      this.$api
          .regionAgentClear({
            region: this.ss.region,
            agent: this.ss.agent
          })
          .then((res) => {
            this.agentQueueStr = res.data.data;
          });
    },


    // 还原实际代理
    regionAgentReal() {
      this.$api
          .regionAgentReal({
            region: this.ss.region,
            agent: this.ss.agent
          })
          .then((res) => {
            this.agentQueueStr = res.data.data;
          });
    },

    // 删除代理
    removeSmsAgent() {
      this.$api
          .removeSmsAgent({
            region: this.ss.region,
            agent: this.ss.agent
          })
          .then((res) => {
            this.agentQueueStr = res.data.data;
          });
    },

    // 查询国家代码
    regionCodeList() {
      this.regionCodeArr = CountryCode.country
    },

    // 查询国家代码
    agentCodeList() {
      this.agentCodeArr = AgentCode.agent
    },
  }
};
</script>

<style lang="scss" scoped></style>
