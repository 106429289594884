<template>
  <div class="box"  >
    <h1>欢迎进入羽锐科技管理后台</h1>
<!--    <img src="../../assets/home/homeImg.png" alt=""/>-->
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.box{
  text-align: center;
  margin-top: 10%;
}
</style>
