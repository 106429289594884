import {MessageBox} from "element-ui";

export const wTx = (msg, res, err) => {
  MessageBox.confirm(msg, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
      .then(() => {
        res();
      })
      .catch(() => {
        err();
      });
};
