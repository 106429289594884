<template>
  <div class="onSize" ref="onSize" @scroll="handScroll()">
    <div class="child" ref="child"></div>
  </div>
</template>


<script>
export default {
  mounted() {
    this.$refs.child.style.width = this.$refs.onSize.clientWidth * 2 + "px";
    this.$refs.child.style.height = this.$refs.onSize.clientHeight * 2 + "px";
    this.$refs.onSize.scrollTop = 1000;
    this.$refs.onSize.scrollLeft = 1000;
  },
  data() {
    return {};
  },
  methods: {
    handScroll() {
      this.$emit("onSize", {
        w: this.$refs.onSize.clientWidth,
        h: this.$refs.onSize.clientHeight,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.onSize {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}
</style>