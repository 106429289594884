<template>
  <div class="card">
    <el-row style="height: 100%" :gutter="20">
      <el-col v-show="dividerFalg" :span="6">
        <div class="flex c-p" @click="dividerFalg = false">
          <el-divider content-position="center">组织机构</el-divider>
        </div>
        <el-tree
          :data="treeData"
          :props="defaultProps"
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
        ></el-tree>
      </el-col>
      <el-col v-show="!dividerFalg" :span="1">
        <div class="flex fd-c jc-fs c-p" @click="dividerFalg = true">
          <el-divider
            style="height: 90px"
            direction="vertical"
            content-position="center"
          ></el-divider>
          <span>组</span>
          <span>织</span>
          <span>机</span>
          <span>构</span>
          <el-divider
            style="height: 90px"
            direction="vertical"
            content-position="center"
          ></el-divider>
        </div>
      </el-col>
      <el-col :span="dividerFalg ? 18 : 23" style="height: 100%">
        <el-row>
          <el-divider content-position="center">{{ activeObj.name }}</el-divider>
        </el-row>
        <el-tabs v-model="activeName">
          <el-tab-pane style="height: 100%" label="人员列表" name="1">
            <el-row :gutter="20" class="mb-15">
              <el-col :span="5">
                <el-input v-model="ss.name" size="small" placeholder="请输入姓名"></el-input>
              </el-col>
              <el-col :span="5">
                <el-input v-model="ss.loginName" size="small" placeholder="请输入账号"></el-input>
              </el-col>
              <el-col :span="14">
                <div>
                <!-- <div class="flex jc-fe"> -->
                  <el-button type="primary" size="small" @click="userPageList(activeObj)">
                    搜索
                  </el-button>
                </div>
              </el-col>
            </el-row>
            <div class="table150">
              <el-table
                  :data="tableData"
                  border
                  @selection-change="handleSelectionChange"
                  row-key="id"
                >
                  <!-- <el-table-column
                    align="center"
                    type="selection"
                    reserve-selection
                  ></el-table-column> -->
                  <el-table-column label="姓名" prop="name"></el-table-column>
                  <el-table-column label="账号" prop="loginName"></el-table-column>
                  <el-table-column label="角色" prop="roleText"></el-table-column>
                  <el-table-column
                    label="操作"
                    align="center"
                    v-if="$checkArr('sys:userRole:oper')"
                  >
                    <template slot-scope="scope">
                      <el-button type="warning" size="mini" @click="modify(scope.row)"
                        >修改</el-button
                      >
                      <el-button type="danger" size="mini" @click="remove(scope.row)"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <el-pagination
              v-if="page.total > 20"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page.index"
              :page-sizes="page.sizes"
              :page-size.sync="page.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page.total"
            />
          </el-tab-pane>
          <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('sys:userRole:oper')">
            <el-col :span="12">
              <el-form
                ref="form"
                :model="formData"
                :rules="{
                  name: [
                    {
                      required: true,
                      message: '该项不能为空',
                      trigger: ['change'],
                    },
                  ],
                  loginName: [
                    {
                      required: true,
                      message: '该项不能为空',
                      trigger: ['change'],
                    },
                  ],
                  roleIds: [
                    {
                      required: true,
                      message: '该项不能为空',
                      trigger: 'change',
                    },
                  ],
                }"
                label-position="right"
                label-width="90px"
              >
                <el-form-item label="姓名：" prop="name">
                  <div class="searchMenu" @click="clickMB()"></div>
                  <el-input v-model="formData.name" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="账号：" prop="loginName">
                  <div class="searchMenu"></div>
                  <el-input v-model="formData.loginName" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="角色：" prop="roleIds">
                  <el-select
                      v-model="formData.roleIds"
                      multiple
                      placeholder=""
                      style="width: 100%"
                  >
                    <el-option
                      v-for="(v, k) in roleArr"
                      :key="'角色数组' + k"
                      :label="v.name"
                      :value="v.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <el-button
                    type="primary"
                    @click="
                      $refs.form
                        .validate()
                        .then(() => {
                          save();
                        })
                        .catch(() => {
                          $wMsg('请填写完整信息');
                        })
                    "
                    >保存</el-button
                  >
                  <el-button type="info" @click="back()">返回</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>

    <!-- 选择人员 -->
    <el-dialog
      title="选择人员"
      :visible.sync="personnelDialogVisible"
      width="75%"
      :close-on-click-modal="false"
    >
      <div>
        <el-row class="mb-15">
          <el-input
            v-model="personnelSs.name"
            class="w200 mr-15"
            placeholder="请输入姓名"
            clearable
          ></el-input>
          <el-input
            v-model="personnelSs.loginName"
            class="w200 mr-15"
            placeholder="请输入账号"
            clearable
          ></el-input>
          <el-button type="primary" @click="getUserInfo()">搜索</el-button>
        </el-row>

        <el-table :data="personnelTableData" border>
          <el-table-column label="姓名" prop="name"></el-table-column>
          <el-table-column label="账号" prop="loginName"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="setPersonnel(scope.row)"
                >选择</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          class="mt-15"
          @size-change="personnelHandleSizeChange"
          @current-change="personnelHandleCurrentChange"
          :current-page.sync="personnelPage.index"
          :page-sizes="personnelPage.sizes"
          :page-size.sync="personnelPage.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="personnelPage.total"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "personnelSettings",
  mounted() {
    if (this.$checkArr("sys:userRole:view")) {
      this.officeGetTreeOfCurrentUser("初始化");
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      treeData: [],
      dividerFalg: true, // 分割线切换
      activeObj: { name: "未选中" },
      defaultProps: {
        children: "children",
        label: "name",
      },
      activeName: "1",
      ss: {
        loginName: "",
        name: "",
      },
      formTitle: "添加人员",
      tableData: [],
      tableSelectionData: [], // 多选表格的选中状态数据
      page: {
        index: 1,
        sizes: [10, 20, 30, 40],
        size: 10,
        total: 0,
      },
      formData: {},
      personnelDialogVisible: false,
      personnelTableData: [],
      personnelSs: {
        loginName: "",
        name: "",
      },
      personnelPage: {
        index: 1,
        sizes: [10, 20, 30, 40],
        size: 10,
        total: 0,
      },
      roleArr: [],
    };
  },
  methods: {
    reData() {
      this.formData = {};
    },
    // 获取所有机构--树形结构
    officeGetTreeOfCurrentUser(type) {
      this.$api.officeGetTreeOfCurrentUser().then((res) => {
        this.treeData = res.data.data;
        if (type == "初始化") {
          this.activeObj = res.data.data[0];
          this.userPageList(this.activeObj);
        }
      });
    },
    // 查询用户列表--分页
    userPageList(v) {
      this.$api
        .officeUserPageListOfOffice({
          form: {
            avatar: "",
            birthday: "",
            email: "",
            idcardNo: "",
            loginFlag: "",
            loginName: this.ss.loginName,
            name: this.ss.name,
            officeId: v.id,
            sex: "",
          },
          pageIndex: this.page.index,
          pageSize: this.page.size,
        })
        .then((res) => {
          this.page.total = res.data.data.total;
          let data = res.data.data.list;
          data.map((v) => {
            v.roleIds = [];
            v.roleText = [];
            v.roles.forEach((rv) => {
              v.roleIds.push(rv.id);
              v.roleText.push(rv.name);
            });
            v.roleText = v.roleText.join(",");
          });
          this.tableData = data;
          this.getUserInfo();
          this.getRoleInfo();
        });
    },
    // 获取用户信息
    getUserInfo() {
      this.$api
        .userPageList({
          form: {
            loginName: this.personnelSs.loginName,
            name: this.personnelSs.name,
          },
          pageIndex: this.personnelPage.index,
          pageSize: this.personnelPage.size,
        })
        .then((res) => {
          this.personnelPage.total = res.data.data.total;
          this.personnelTableData = res.data.data.list;
        });
    },
    // 获取角色信息
    getRoleInfo() {
      this.$api
        .officeUserGetRolesOfOfficeLevel({
          officeId: this.activeObj.id,
        })
        .then((res) => {
          this.roleArr = res.data.data;
        });
    },
    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.formTitle = "修改人员";
      this.activeName = "2";
    },
    // 删除
    remove(v) {
      this.$wTx(
        "该操作将删除该用户在当前机构的权限，确认继续吗？",
        () => {
          this.$OL("正在删除中...");
          this.$api
            .officeUserDel({ officeId: this.activeObj.id, userId: v.id })
            .then((res) => {
              this.$sMsg("删除成功");
              this.back();
              this.$CL();
            });
        },
        () => {
          this.$iMsg("已取消删除");
        }
      );
    },
    // 保存
    save() {
      this.$OL("保存中...");
      this.$api
        .officeUserUpdate({
          officeId: this.activeObj.id,
          userId: this.formData.id,
          roleIds: this.formData.roleIds,
        })
        .then((res) => {
          this.$CL();
          this.$sMsg(this.formTitle == "添加人员" ? "添加成功" : "修改成功");
          this.back();
        });
    },
    // 返回
    back() {
      this.formTitle = "添加人员";
      this.activeName = "1";
      this.reData();
      this.userPageList(this.activeObj);
    },
    // 选中菜单
    handleNodeClick(v) {
      this.activeObj = v;
      this.userPageList(this.activeObj);
      if (this.activeName == "2") {
        this.reData();
        this.activeName = "1";
      }
    },
    // 表格选中状态改变
    handleSelectionChange(v) {
      this.tableSelectionData = v;
    },
    // 切换页码
    handleCurrentChange() {
      this.userPageList(this.activeObj);
    },
    // 切换每页条目数
    handleSizeChange() {
      this.userPageList(this.activeObj);
    },
    setPersonnel(v) {
      for (const k in v) {
        if (k != "roleIds") {
          this.$set(this.formData, k, v[k]);
          // this.formData[k] = v[k];
        }
      }
      this.personnelDialogVisible = false;
      this.$refs.form.validate();
    },
    personnelHandleSizeChange() {
      this.getUserInfo();
    },
    personnelHandleCurrentChange() {
      this.getUserInfo();
    },

    // 点击用户信息上的蒙版
    clickMB() {
      this.formTitle == "添加人员" ? (this.personnelDialogVisible = true) : "";
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.searchMenu {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  cursor: text;
}
.el-tabs {
  height: calc(100% - 50px);
}
</style>
