<template>
  <el-card>
    <el-row :gutter="20">
      <el-col v-show="dividerFalg" :span="6">
        <div class="flex c-p" @click="dividerFalg = false">
          <el-divider content-position="center">菜单树</el-divider>
        </div>
        <el-tree
          :data="treeData1"
          :props="defaultProps"
          :default-expanded-keys="treeExpandIds1"
          :expand-on-click-node="false"
          node-key="id"
          @node-click="handleNodeClick1"
          @node-expand="nodeExpand1"
          @node-collapse="nodeCollapse1"
        ></el-tree>
      </el-col>
      <el-col v-show="!dividerFalg" :span="1">
        <div class="flex fd-c jc-fs c-p" @click="dividerFalg = true">
          <el-divider
            style="height: 90px"
            direction="vertical"
            content-position="center"
          ></el-divider>
          <span>组</span>
          <span>织</span>
          <span>机</span>
          <span>构</span>
          <el-divider
            style="height: 90px"
            direction="vertical"
            content-position="center"
          ></el-divider>
        </div>
      </el-col>

      <el-col :span="dividerFalg ? 18 : 23">
        <el-row>
          <el-divider content-position="center">{{ activeObj.name }}</el-divider>
        </el-row>

        <el-tabs v-model="activeName">
          <el-tab-pane label="菜单管理" name="1">
            <el-row class="mb-15" :gutter="20">
              <el-col :span="7">
                <span class="mr-15">类型:</span>
                <el-input
                  style="width: auto"
                  v-model="ss.type"
                  placeholder="请输入字典类型"
                ></el-input>
              </el-col>
              <el-col :span="7">
                <span class="mr-15">描述:</span>
                <el-input
                  style="width: auto"
                  v-model="ss.description"
                  placeholder="请输入字典描述"
                ></el-input>
              </el-col>
              <el-col :span="10" class="flex jc-fe">
                <el-button type="primary" @click="dictPageList(activeObj)"
                  >搜索</el-button
                >
              </el-col>
            </el-row>
            <el-table :data="tableData" border tooltip-effect="dark">
              <el-table-column prop="value" label="键值"></el-table-column>
              <el-table-column prop="label" label="标签"></el-table-column>
              <el-table-column prop="type" label="类型"></el-table-column>
              <el-table-column prop="description" label="描述"></el-table-column>
              <el-table-column prop="sort" label="排序"></el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="300px"
                v-if="$checkArr('sys:dict:oper')"
              >
                <template slot-scope="scope">
                  <el-button size="mini" type="warning" @click="modify(scope.row)"
                    >修改</el-button
                  >
                  <el-button size="mini" type="danger" @click="remove(scope.row)"
                    >删除</el-button
                  >
                  <!-- <el-button size="mini" type="danger" @click="remove(scope.row, true)"
                    >物理删除</el-button
                  > -->
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
              class="mt-15"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page.index"
              :page-sizes="page.sizes"
              :page-size.sync="page.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page.total"
            >
            </el-pagination>
          </el-tab-pane>
          <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('sys:dict:oper')">
            <el-col :span="12">
              <el-form
                ref="form"
                :model="formData"
                :rules="{
                  menu: [
                    {
                      required: true,
                      message: '该项目不能为空',
                      trigger: 'blur',
                    },
                  ],
                  value: [
                    {
                      required: true,
                      message: '该项目不能为空',
                      trigger: 'blur',
                    },
                  ],
                  label: [
                    {
                      required: true,
                      message: '该项目不能为空',
                      trigger: 'blur',
                    },
                  ],
                  type: [
                    {
                      required: true,
                      message: '该项目不能为空',
                      trigger: 'blur',
                    },
                  ],
                  sort: [
                    {
                      required: true,
                      message: '该项目不能为空',
                      trigger: 'blur',
                    },
                  ],
                }"
                label-position="right"
                label-width="90px"
              >
                <el-form-item label="父菜单：" prop="menu">
                  <div class="searchMenu" @click="searchMenu()"></div>
                  <el-input v-model="formData.menu" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="键值：" prop="value">
                  <el-input v-model="formData.value" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="标签：" prop="label">
                  <el-input v-model="formData.label" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="类型：" prop="type">
                  <el-input v-model="formData.type" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="描述：">
                  <el-input
                    v-model="formData.description"
                    placeholder=""
                    type="textarea"
                    :autosize="{
                      minRows: 3,
                    }"
                  ></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                  <el-input
                    v-model="formData.sort"
                    placeholder=""
                    @keydown.native.enter="
                      $refs.form
                        .validate()
                        .then(() => {
                          save();
                        })
                        .catch(() => {
                          $wMsg('请填写完整信息');
                        })
                    "
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="
                      $refs.form
                        .validate()
                        .then(() => {
                          save();
                        })
                        .catch(() => {
                          $wMsg('请填写完整信息');
                        })
                    "
                    >保存</el-button
                  >
                  <el-button type="info" @click="back()">返回</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <!-- 选择菜单对话框 -->
    <el-dialog
      title="选择菜单"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div>
        <el-tree
          :data="treeData2"
          :props="defaultProps"
          @node-click="handleNodeClick2"
        ></el-tree>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "dictionariesSettings",
  mounted() {
    if (this.$checkArr("sys:dict:view")) {
      this.getOfficeTree("初始化");
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      treeData1: [],
      treeExpandIds1: [],
      dividerFalg: true, // 分割线切换
      activeObj: { name: "未选中", id: "" },
      defaultProps: {
        children: "children",
        label: "name",
      },
      ss: {
        type: "",
        description: "",
      },
      activeName: "1",
      tableData: [],
      formTitle: "添加字典",
      formData: {},
      page: {
        index: 1,
        sizes: [10, 20, 30, 40],
        size: 10,
        total: 0,
      },
      dialogVisible: false,
      treeData2: [],
    };
  },
  methods: {
    // 初始化fromdata
    reData() {
      this.formData = {
        menu: this.activeObj.name,
        menuId: this.activeObj.id,
      };
    },

    // 获取菜单导航
    getOfficeTree(type) {
      this.$api.getOfficeTree().then((res) => {
        this.treeData1 = res.data.data;
        this.treeData2 = res.data.data;
        if (type == "初始化") {
          this.activeObj = this.treeData1[0];
          this.dictPageList(this.activeObj);
          this.reData();
        }
      });
    },

    // 查询字典列表——分页
    dictPageList(v) {
      this.$api
        .dictPageList({
          form: {
            description: this.ss.description,
            label: "",
            menuId: v.id,
            sort: "",
            type: this.ss.type,
            value: "",
          },
          pageIndex: this.page.index,
          pageSize: this.page.size,
        })
        .then((res) => {
          this.tableData = res.data.data.list;
          this.page.total = res.data.data.total;
        });
    },

    modify(v) {
      this.activeName = "2";
      this.formTitle = "修改字典";
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      let x = (arr) => {
        for (let i = 0; i < arr.length; i++) {
          let v = arr[i];
          console.log(v.id, obj.menuId);
          if (v.id == obj.menuId) {
            this.formData.menu = v.name;
            return true;
            break;
          } else if (v.children) {
            let falg = x(v.children);
            if (falg) {
              return true;
            }
          }
        }
      };
      x(this.treeData2);
    },

    remove(v, falg) {
      this.$wTx(
        "该操作将" + (falg ? "永久" : "") + "删除当前字典条目，确认继续吗？",
        () => {
          this.$OL("删除字典条目中...");
          this.$api[falg ? "dictRelDel" : "dictDel"]({ id: v.id }).then((res) => {
            this.$sMsg("删除成功");
            this.back();
            this.$CL();
          });
        },
        () => {
          this.$iMsg("已取消删除");
        }
      );
    },

    save() {
      this.$OL((this.formTitle == "添加字典" ? "添加" : "修改") + "字典中...");
      this.$api
        .dictUpdate({
          id: this.formTitle == "添加字典" ? "" : this.formData.id,
          value: this.formData.value,
          label: this.formData.label,
          type: this.formData.type,
          menuId: this.formData.menuId,
          description: this.formData.description,
          sort: this.formData.sort,
        })
        .then((res) => {
          this.$CL();
          this.$sMsg(this.formTitle == "添加字典" ? "添加成功" : "修改成功");
          this.back();
        });
    },

    back() {
      this.reData();
      this.formTitle = "添加字典";
      this.dictPageList(this.activeObj);
      this.activeName = "1";
    },

    // 打开选择菜单
    searchMenu() {
      this.dialogVisible = true;
    },

    // 选中菜单
    handleNodeClick1(v) {
      this.activeObj = JSON.parse(JSON.stringify(v));
      this.dictPageList(this.activeObj);
      if (this.formTitle == "添加字典") {
        this.formData.menu = this.activeObj.name;
        this.formData.menuId = this.activeObj.id;
      }
    },

    // 节点展开
    nodeExpand1(v, node, dom) {
      this.treeExpandIds1.push(v.id);
    },

    // 节点关闭
    nodeCollapse1(v, node, dom) {
      this.treeExpandIds1.splice(
        this.treeExpandIds1.findIndex((rv) => {
          rv.id == v.id;
        }),
        1
      );
    },

    // 选中菜单
    handleNodeClick2(v) {
      if (this.treeDbClick) {
        this.formData.menu = v.name;
        this.formData.menuId = v.id;
        this.dialogVisible = false;
        clearTimeout(this.treeDbClick);
        this.treeDbClick = null;
      } else {
        this.treeDbClick = setTimeout(() => {
          clearTimeout(this.treeDbClick);
          this.treeDbClick = null;
        }, 300);
      }
    },

    // 切换页码
    handleCurrentChange(index) {
      this.dictPageList(this.activeObj);
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.dictPageList(this.activeObj);
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加字典";
      } else if (this.activeName == "2" && this.formTitle == "添加字典") {
        this.formData.menu = this.activeObj.name;
        this.formData.menuId = this.activeObj.id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.searchMenu {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  cursor: text;
}
</style>
