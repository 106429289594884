<template>
  <el-card>
    <el-tabs v-model="activeName">
      <el-tab-pane label="日志列表" name="1">
        <el-row class="mb-15">
          <el-input
            v-model="ss.uid"
            class="w200 mr-15"
            placeholder="请输入操作对象"
            clearable
          ></el-input>
          <el-date-picker
              v-model="ss.startTime"
              class="mr-15"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择开始日期"
          >
          </el-date-picker>

          <el-date-picker
              v-model="ss.endTime"
              class="mr-15"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择结束日期"
          >
          </el-date-picker>
          <el-button type="primary" @click="userPageList()">搜索</el-button>
        </el-row>
        <el-table :data="tableData" border>
          <el-table-column label="操作人" prop="name" align="center"></el-table-column>
          <el-table-column label="操作对象" prop="uid" align="center"></el-table-column>
          <el-table-column label="操作人ip" prop="ip" align="center"></el-table-column>
          <el-table-column label="操作内容" prop="log" align="center"></el-table-column>
          <el-table-column label="时间(CST/UTC)" prop="time" width="170" align="center">
            <template v-if="scope.row.time" slot-scope="scope">
              <div>{{ scope.row.time | TM2CST }}</div>
              <div>{{ scope.row.time | TM2UTC }}</div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt-15"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page.index"
          :page-sizes="page.sizes"
          :page-size.sync="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
// import {dateFormat} from '@/components/js/date.js'
export default {
  mounted() {
    if (this.$checkArr("log:pageList")) {
      this.userPageList("初始化");
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      tableData: [],
      ss: {
        name: "",
        loginName: "",
      },
      page: {
        index: 1,
        sizes: [10, 20, 30, 40],
        size: 20,
        total: 40,
      },
      formData: {},
      formTitle: "添加人员",
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询用户列表--分页
    userPageList(type) {
      this.$api
        .sysAdminLogList({
          form: {
            id: this.ss.uid,
            startTime: this.ss.startTime,
            endTime: this.ss.endTime
          },
          pageIndex: this.page.index,
          pageSize: this.page.size,
        })
        .then((res) => {
          this.page.total = res.data.data.total;
          this.tableData = res.data.data.list;
          if (type == "初始化") {
          }
        });
    },


    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加人员";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
