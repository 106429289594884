const data = [
    {
        icon: "el-icon-setting",
        val: "系统设置",
        index: "1",
        chr: [
            {
                icon: "el-icon-setting",
                val: "菜单设置",
                index: "menuSettings",
            },
            {
                icon: "el-icon-setting",
                val: "角色设置",
                index: "roleSettings",
            },
            {
                icon: "el-icon-setting",
                val: "字典设置",
                index: "dictionariesSettings",
            },
            {
                icon: "el-icon-setting",
                val: "人员设置",
                index: "basicPersonnel",
            },
        ],
    },
    {
        icon: "el-icon-setting",
        val: "组织结构",
        index: "structureSettings",
    },
    {
        icon: "el-icon-setting",
        val: "用户管理",
        index: "personnelSettings",
    },
    {
        icon: "el-icon-setting",
        val: "日常巡查",
        index: "patrolDaily",
    },
    {
        icon: "el-icon-setting",
        val: "专项巡查",
        index: "patrolSpecial",
    },
    {
        icon: "el-icon-setting",
        val: "日常自查",
        index: "patrolOwn",
    },
    {
        icon: "el-icon-setting",
        val: "隐患管理",
        index: "danger",
    },
    {
        icon: "el-icon-setting",
        val: "整改管理",
        index: "rectification",
    },
    {
        icon: "el-icon-setting",
        val: "安全台账",
        index: "book",
    },
    {
        icon: "el-icon-setting",
        val: "用户管理",
        index: "userList",
    },
];
export {data};
