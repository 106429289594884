// 单独对信息提示做了封装，引入message文件
import {eMsg} from "../msg";

import router from "../../router/index";
// 由于不想在每个代码里面判断（懒），所以将判断全部提出来，放在拦截器里面进行判断（code虽然可能一样，但是提示信息不同）
// 代码里面只写成功的返回数据
// 条件过多不考虑条件判断（后期可能会添加）
// 修改代码往往比增加代码危险很多
// 需要根据状态码的不同进行不同操作

const codeErrMsg = (code, message) => {
  // 判断errorCode[code]是否为一个函数
  // 避免因未设置的错误码而报错
  if (errorCode[code] instanceof Function) {
    // 调用该函数
    errorCode[code](code, message);
  } else {
    console.log(code, message);
    eMsg("未知错误");
  }
};
const errorCode = {
  code401: (code, message) => {
    console.log(code, message);
    eMsg("认证失败");
    router.push("/");
  },
  code500: function (code, message) {
    console.log(code, message);
    // message == "账户不存在"
    //     ? eMsg("账户不存在")
    //     : message == "密码错误!"
    //         ? eMsg("密码错误!")
    //         : eMsg("服务器内部错误");
    eMsg(message);
  },
};
export default codeErrMsg;
