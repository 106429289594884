<template>
  <div class="card">
    <el-row class="mb-15">
      <el-button type="primary" size="small" @click="userPageList()">刷新</el-button>
      <template>
        当前时间：{{ currentTime }}
      </template>
    </el-row>
    <div class="table150">
      <el-table :data="tableData" border height="100%" v-loading="loading">
        <el-table-column label="序号" type="index" width="50" align="center" fixed />
        <el-table-column label="发起用户" prop="userId" align="center" />
        <el-table-column label="接收用户" prop="otherId" align="center" />
        <el-table-column label="频道名" prop="channelName" align="center" />
        <!--          <el-table-column label="发起时间" prop="startTime" align="center"></el-table-column>-->
        <el-table-column label="发起时间(CST/UTC)" prop="ts" width="170" align="center">
          <template v-if="scope.row.ts" slot-scope="scope">
            <div>{{ scope.row.ts | TM2CST }}</div>
            <div>{{ scope.row.ts | TM2UTC }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" v-if="$checkArr('agora:delete')"
              @click="remove(scope.row.channelName)">中断</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt-15" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="pageObj.pageIndex" :page-sizes="page.sizes" :page-size.sync="pageObj.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="page.total" />
  </div>
</template>

<script>
export default {
  created() {
    this.timer = setInterval(() => {
      this.currentTime = this.getCurrentTime();
    })
  },
  mounted() {
    if (this.$checkArr("agora:pageList")) {
      this.userPageList("初始化");
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",

      // 查詢
      ss: {
        name: "",
        loginName: "",
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20
      },
      // 表格
      loading: false,
      tableData: [],

      formData: {},
      formTitle: "添加人员",
      picUrl: [],
      currentTime: '',
      nowTime: new Date(),
      timer: undefined,
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },
    // 查询用户列表--分页
    userPageList(type) {
      this.loading = true
      this.$api.agoraList({
        // form: {
        //   userId: this.ss.userId
        // },
        ...this.pageObj
      }).then((res) => {
        this.page.total = res.data.data.total;
        this.tableData = res.data.data.list;
        if (type == "初始化") {
        }
        this.loading = false
      });
    },

    // 删除
    remove(v) {
      debugger;
      this.$wTx(
        "该操作将中断用户视频聊天，确认继续吗？",
        () => {
          this.$OL("正在中断...");
          this.$api
            .deleteAgora({ channelName: v })
            .then((res) => {
              this.$sMsg("中断成功");
              this.back();
              this.$CL();
            });
        },
        () => {
          this.$iMsg("已取消中断");
        }
      );
    },

    getCurrentTime() {
      //获取当前时间并打印
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      let time = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
      return time;

    },


    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加人员";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加人员";
      }
    },
  },

  // 关闭页面销毁定时器
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
