<template>
  <el-card>
    <el-row :gutter="20">
      <el-col v-show="dividerFalg" :span="6">
        <div class="flex c-p" @click="dividerFalg = false">
          <el-divider content-position="center">菜单树</el-divider>
        </div>
        <el-tree
          :data="treeData1"
          :props="defaultProps1"
          :default-expanded-keys="treeExpandIds1"
          node-key="id"
          :expand-on-click-node="false"
          @node-click="handleNodeClick1"
          @node-expand="nodeExpand1"
          @node-collapse="nodeCollapse1"
        ></el-tree>
      </el-col>
      <el-col v-show="!dividerFalg" :span="1">
        <div class="flex fd-c jc-fs c-p" @click="dividerFalg = true">
          <el-divider
            style="height: 90px"
            direction="vertical"
            content-position="center"
          ></el-divider>
          <span>菜</span>
          <span>单</span>
          <span>树</span>
          <el-divider
            style="height: 90px"
            direction="vertical"
            content-position="center"
          ></el-divider>
        </div>
      </el-col>
      <el-col :span="dividerFalg ? 18 : 23">
        <el-row>
          <el-divider content-position="center">{{ activeObj.name }}</el-divider>
        </el-row>
        <el-tabs v-model="activeName">
          <el-tab-pane label="菜单管理" name="1">
            <el-table
              :data="tableData"
              border
              tooltip-effect="dark"
              row-key="id"
              default-expand-all
              :tree-props="{ children: 'chr' }"
            >
              <el-table-column
                show-overflow-tooltip
                prop="name"
                label="名称"
              ></el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="href"
                label="路由"
              ></el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="sort"
                label="排序"
              ></el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="icon"
                label="图标"
              ></el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="permission"
                label="权限"
              ></el-table-column>

              <el-table-column
                label="操作"
                align="center"
                width="300px"
                v-if="$checkArr('sys:menu:oper')"
              >
                <template slot-scope="scope">
                  <el-button size="mini" type="warning" @click="modify(scope.row)"
                    >修改</el-button
                  >
                  <el-button size="mini" type="danger" @click="remove(scope.row)"
                    >删除</el-button
                  >
                  <!-- <el-button size="mini" type="danger" @click="remove(scope.row, true)"
                    >物理删除</el-button
                  > -->
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('sys:menu:oper')">
            <el-col :span="12">
              <el-form :model="formData" label-position="right" label-width="90px">
                <el-form-item label="父菜单：">
                  <div class="searchMenu" @click="searchMenu"></div>
                  <el-input v-model="formData.parent" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="名称：">
                  <el-input v-model="formData.name" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="路由：">
                  <el-input v-model="formData.href" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="排序：">
                  <el-input v-model="formData.sort" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="图标：">
                  <el-input v-model="formData.icon" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="显示：">
                  <el-radio v-model="formData.isShow" label="1">是</el-radio>
                  <el-radio v-model="formData.isShow" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="权限：">
                  <el-input v-model="formData.permission" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-button type="primary" @click="save()">保存</el-button>
                  <el-button type="info" @click="back()">返回</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <!-- 选择菜单对话框 -->
    <el-dialog
      title="选择菜单"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div>
        <el-tree
          :data="treeData2"
          :props="defaultProps2"
          :expand-on-click-node="false"
          @node-click="handleNodeClick2"
        ></el-tree>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "menuSettings",
  mounted() {
    this.$checkArr("sys:menu:view") ? this.getOfficeTree("初始化") : this.$wMsg("无权限");
  },
  data() {
    return {
      treeData1: [],
      treeExpandIds1: [],
      dividerFalg: true, // 分割线切换
      activeObj: { name: "未选中" },
      defaultProps1: {
        children: "children",
        label: "name",
      },
      activeName: "1",
      tableData: [],
      formTitle: "添加菜单",
      formData: {},

      dialogVisible: false,
      treeData2: [],
      defaultProps2: {
        children: "children",
        label: "name",
      },
      treeDbClick: null,
    };
  },
  methods: {
    // 重置数据
    reData() {
      this.formData = {};
    },
    // 获取菜单
    getOfficeTree(type) {
      this.$api.menuGetMenuTree().then((res) => {
        this.treeData1 = res.data.data;
        this.treeData2 = res.data.data;
        if (type == "初始化") {
          this.activeObj = this.treeData1[0];
          this.menuGetChildList(this.treeData1[0]);
        }
      });
    },

    // 获取子菜单
    menuGetChildList(v) {
      this.$api.menuGetChildList({ id: v.id }).then((res) => {
        this.tableData = [];
        this.tableData = res.data.data;
      });
    },

    // 打开选择菜单
    searchMenu() {
      this.dialogVisible = true;
    },

    // 选中菜单
    handleNodeClick1(v) {
      this.activeObj = JSON.parse(JSON.stringify(v));
      this.menuGetChildList(this.activeObj);
      if (this.formTitle == "添加菜单") {
        this.formData.parent = this.activeObj.name;
        this.formData.parentId = this.activeObj.id;
      }
    },

    // 节点展开
    nodeExpand1(v, node, dom) {
      this.treeExpandIds1.push(v.id);
    },

    // 节点关闭
    nodeCollapse1(v, node, dom) {
      this.treeExpandIds1.splice(
        this.treeExpandIds1.findIndex((rv) => {
          rv.id == v.id;
        }),
        1
      );
    },

    // 选中菜单
    handleNodeClick2(v) {
      if (this.treeDbClick) {
        this.formData.parent = v.name;
        this.formData.parentId = v.id;
        this.dialogVisible = false;
        clearTimeout(this.treeDbClick);
        this.treeDbClick = null;
      } else {
        this.treeDbClick = setTimeout(() => {
          clearTimeout(this.treeDbClick);
          this.treeDbClick = null;
        }, 300);
      }
    },

    modify(v) {
      this.activeName = "2";
      this.formTitle = "修改菜单";
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      let x = (arr) => {
        for (let i = 0; i < arr.length; i++) {
          let v = arr[i];
          if (v.id == obj.parentId) {
            this.formData.parent = v.name;
            break;
          } else if (v.children) {
            x(v.children);
          }
        }
      };
      x(this.treeData2);
    },

    remove(v, falg) {
      this.$wTx(
        "此操作将" + (falg ? "永久" : "") + "删除该菜单，确认继续吗？",
        () => {
          this.$OL("正在删除菜单...");
          this.$api[falg ? "menuRelDel" : "menuDel"]({ id: v.id }).then((res) => {
            this.$sMsg("删除成功");
            this.$CL();
            this.getOfficeTree();
            this.menuGetChildList(this.activeObj);
          });
        },
        () => {
          this.$iMsg("已取消删除");
        }
      );
    },

    save() {
      this.$OL("正在保存...");
      this.$api
        .menuUpDate({
          href: this.formData.href,
          icon: this.formData.icon,
          id: this.formTitle == "添加菜单" ? "" : this.formData.id,
          isShow: this.formData.isShow,
          name: this.formData.name,
          parentId: this.formData.parentId,
          permission: this.formData.permission,
          sort: this.formData.sort,
        })
        .then((res) => {
          this.$sMsg(this.formTitle == "添加菜单" ? "添加成功" : "修改成功");
          this.$bus.$emit("刷新菜单");
          this.back();
          this.$CL();
        });
    },

    back() {
      this.activeName = "1";
      this.formTitle = "添加菜单";
      this.reData();
      this.getOfficeTree();
      this.menuGetChildList(this.activeObj);
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加菜单";
      } else if (this.activeName == "2" && this.formTitle == "添加菜单") {
        this.formData.parent = this.activeObj.name;
        this.formData.parentId = this.activeObj.id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.searchMenu {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  cursor: text;
}
</style>
