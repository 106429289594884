<template>
  <el-card>
    <el-tabs v-model="activeName">
        <el-row class="mb-15">
          <el-input
            v-model="ss.userId"
            class="w200 mr-15"
            placeholder="请输入用户id"
            clearable
          ></el-input>
          <el-input
              v-model="ss.otherId"
              class="w200 mr-15"
              placeholder="请输入对方id"
              clearable
          ></el-input>
          <el-button type="primary" @click="userPageList()">搜索</el-button>
        </el-row>
        <el-table :data="tableData" border>
          <el-table-column label="用户id" prop="userId" align="center"></el-table-column>
          <el-table-column label="对方id" prop="otherId" align="center"></el-table-column>
          <el-table-column label="拉黑时间" prop="createTime">
            <template v-if="scope.row.createTime" slot-scope="scope">
              <div class="table_time">{{ scope.row.createTime | TM2CST }}</div>
              <div class="table_time">{{ scope.row.createTime | TM2UTC }}</div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt-15"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page.index"
          :page-sizes="page.sizes"
          :page-size.sync="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
    </el-tabs>
  </el-card>
</template>

<script>
export default {
  mounted() {
    if (this.$checkArr("blackList:pageList")) {
      this.userPageList("初始化");
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      tableData: [],
      ss: {
        name: "",
        loginName: "",
      },
      page: {
        index: 1,
        sizes: [10, 20, 30, 40],
        size: 10,
        total: 40,
      },
      formData: {},
      formTitle: "添加人员",
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询用户列表--分页
    userPageList(type) {
      this.$api
        .blackList({
          form: {
            userId: this.ss.userId,
            otherId: this.ss.otherId
          },
          pageIndex: this.page.index,
          pageSize: this.page.size,
        })
        .then((res) => {
          this.page.total = res.data.data.total;
          this.tableData = res.data.data.list;
          if (type == "初始化") {
          }
        });
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加人员";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
