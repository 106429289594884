<template>
   <!-- :style="'height:' + this.$store.state.maxH + 'px'" -->
  <div class="loginBG">
    <!-- :style="'height:' + this.$store.state.maxH + 'px'" -->
    <vue-particles
      style="height: 100%;"
      color="#fff"
      :particleOpacity="0.7"
      :particlesNumber="80"
      shapeType="circle"
      :particleSize="4"
      linesColor="#fff"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <el-card class="loginBox">
      <login_c />
    </el-card>
  </div>
</template>

<script>
import login_c from "./login/login";

export default {
  name: "login",
  components: {
    login_c,
  },

  mounted() {
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.loginBG {
  width: 100%;
  height: 100%!important;
  background: linear-gradient(-90deg, #29bdd9 0%, #276ace 100%);
  position: relative;
  overflow: hidden;
  .loginBox {
    
    width: 400px;
    position: absolute;
    top: calc((100% - 500px) / 2);
    left: calc((100% - 500px) / 2);
  }
}
</style>
