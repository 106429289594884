var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',[_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"角色列表","name":"1"}},[_c('el-table',{attrs:{"data":_vm.tableData,"border":"","row-key":"index","default-expand-all":"","tree-props":{ children: 'chr' }}},[_c('el-table-column',{attrs:{"label":"名称","prop":"name"}}),(_vm.$checkArr('sys:role:oper'))?_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"warning","size":"mini"},on:{"click":function($event){return _vm.modify(scope.row)}}},[_vm._v("修改")]),_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.remove(scope.row)}}},[_vm._v("删除")])]}}],null,false,3472346464)}):_vm._e()],1),_c('el-pagination',{staticClass:"mt-15",attrs:{"current-page":_vm.page.index,"page-sizes":_vm.page.sizes,"page-size":_vm.page.size,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.page.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){return _vm.$set(_vm.page, "index", $event)},"update:current-page":function($event){return _vm.$set(_vm.page, "index", $event)},"update:pageSize":function($event){return _vm.$set(_vm.page, "size", $event)},"update:page-size":function($event){return _vm.$set(_vm.page, "size", $event)}}})],1),(_vm.$checkArr('sys:role:oper'))?_c('el-tab-pane',{attrs:{"label":_vm.formTitle,"name":"2"}},[_c('el-col',{attrs:{"span":12}},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":{
            name: [{ required: true, message: '该项目不能为空', trigger: 'blur' }],
            dataScope: [{ required: true, message: '该项目不能为空', trigger: 'blur' }],
          },"label-position":"right","label-width":"150px"}},[_c('el-form-item',{attrs:{"label":"名称：","prop":"name"}},[_c('el-input',{model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('el-form-item',{attrs:{"label":"权限等级：","prop":"dataScope"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":""},model:{value:(_vm.formData.dataScope),callback:function ($$v) {_vm.$set(_vm.formData, "dataScope", $$v)},expression:"formData.dataScope"}},_vm._l((_vm.dataScopeArr),function(v,k){return _c('el-option',{key:'权限等级' + k,attrs:{"label":v.label,"value":v.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"角色授权："}},[_c('div',{staticClass:"menuBox"},[_c('el-tree',{key:_vm.treeKey,ref:"tree",attrs:{"data":_vm.treeData,"props":_vm.defaultProps,"default-expanded-keys":_vm.treeExpandIds,"default-checked-keys":_vm.treeCheckedIds,"node-key":"id","show-checkbox":""},on:{"check":_vm.check}})],1)]),_c('el-form-item',{attrs:{"label":"","label-width":"90px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.$refs.form
                  .validate()
                  .then(function () {
                    _vm.save();
                  })
                  .catch(function () {
                    _vm.$wMsg('请填写完整信息');
                  })}}},[_vm._v("保存")]),_c('el-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.back()}}},[_vm._v("返回")])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }