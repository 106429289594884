<template>
  <div class="card">
    <el-tabs v-model="activeName" style="height: 100%">
      <el-tab-pane label="ip列表" name="1" style="height: 100%">
        <el-row class="mb-15">
          <el-input
              v-model="ss.ip"
              class="w200 mr-15"
              size="small"
              placeholder="请输入IP"
              clearable
          ></el-input>
          <el-button type="primary" size="small" @click="query">搜索</el-button>
        </el-row>
        <div class="table150">
          <el-table :data="tableData" border height="100%" v-loading="loading">
            <el-table-column label="序号" type="index" align="center" width="50" fixed />
            <el-table-column label="IP" prop="ip" align="center"></el-table-column>
            <el-table-column label="国家" prop="countryCode" align="center"></el-table-column>
            <el-table-column label="描述" prop="remark" align="center"></el-table-column>
            <el-table-column label="创建时间(CST/UTC)" prop="createTime" width="170" align="center">
              <template v-if="scope.row.createTime" slot-scope="scope">
                <div>{{ scope.row.createTime | TM2CST }}</div>
                <div>{{ scope.row.createTime | TM2UTC }}</div>
              </template>
            </el-table-column>
            <el-table-column
                label="操作"
                align="center"
                v-if="$checkArr('bi:blackIp:oper')"
            >
              <template slot-scope="scope">
                <el-button
                    type="warning"
                    size="mini"
                    @click="modify(scope.row)"
                    v-if="$checkArr('bi:blackIp:oper')"
                >修改
                </el-button
                >
                <el-button
                    type="danger"
                    size="mini"
                    @click="remove(scope.row)"
                    v-if="$checkArr('bi:blackIp:oper')"
                >删除
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            class="mt-15"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageObj.pageIndex"
            :page-sizes="page.sizes"
            :page-size.sync="pageObj.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('bi:blackIp:oper')">
        <el-row>
          <el-col :span="12">
            <el-form
                ref="form"
                :model="formData"
                :rules="{
                ip: [
                  {
                    required: true,
                    message: '该项目不能为空',
                    trigger: 'blur',
                  },
                ]
              }"
                label-width="150px"
                label-position="right"
            >
              <el-form-item label="Ip" prop="ip">
                <el-input v-model="formData.ip" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="国家" prop="countryCode">
                <el-input v-model="formData.countryCode" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="描述" prop="remark">
                <el-input v-model="formData.remark" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-button
                    type="success"
                    @click="
                    $refs.form
                      .validate()
                      .then(() => {
                        save();
                      })
                      .catch(() => {
                        $wMsg('请填写完整信息');
                      })
                  "
                >保存
                </el-button
                >
                <el-button type="info" @click="back()">返回</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// import {dateFormat} from '@/components/js/date.js'
export default {
  mounted() {
    this.pageBlackIp("初始化");
  },
  data() {
    return {
      activeName: "1",
      // 查询
      ss: {
        ip: ""
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20,
      },
      // 表格
      tableData: [],
      loading: false,

      formData: {},
      formTitle: "添加IP",
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询用户列表--分页
    pageBlackIp(type) {
      this.loading = true
      this.$api.pageBlackIp({
        form: {...this.ss},
        ...this.pageObj
      }).then((res) => {
        this.page.total = res.data.data.total;
        this.tableData = res.data.data.list;
        if (type == "初始化") {
        }
        this.loading = false
      });
    },
    // 搜索
    query() {
      this.pageObj.pageIndex = 1
      this.pageBlackIp()
    },

    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.activeName = "2";
      this.formTitle = "修改IP";
    },

    // 删除
    remove(v, falg) {
      this.$wTx(
          "该操作将" + (falg ? "永久" : "") + "删除当前条目？",
          () => {
            this.$OL("正在删除...");
            this.$api.blackIpDel({id: v.id}).then((res) => {
              this.back();
              this.$CL();
              this.$sMsg("删除成功");
            });
          },
          () => {
            this.$iMsg("已取消删除");
          }
      );
    },

    // 保存
    save() {
      // this.$OL("正在保存...");
      this.$api
          .blackIpUpdate({
            id: this.formTitle == "添加IP" ? "" : this.formData.id,
            ip: this.formData.ip,
            countryCode: this.formData.countryCode,
            remark: this.formData.remark
          })
          .then((ress) => {
            this.$CL();
            this.$sMsg(this.formTitle == "添加IP" ? "添加成功" : "修改成功");
            this.back();
          });
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加IP";
      this.pageBlackIp();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.pageBlackIp();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.pageBlackIp();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加IP";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
