<template>
  <div class="card">
    <el-tabs v-model="activeName" style="height: 100%;">
      <el-tab-pane label="公会列表" name="1" style="height: 100%;">
        <el-row class="mb-15">
          <el-input
              v-model="ss.name"
              class="w200 mr-15"
              size="small"
              placeholder="请输入名称"
              clearable
          ></el-input>
          <el-button type="primary" size="small" @click="query">搜索</el-button>
        </el-row>
        <div class="table150">
          <el-table :data="tableData" border height="100%" v-loading="loading">
            <el-table-column label="序号" type="index" width="50" align="center" fixed />
            <el-table-column label="公会ID" prop="id" align="center"></el-table-column>
            <el-table-column label="公会名称" prop="name" align="center"></el-table-column>
            <!--          <el-table-column label="LOGO" prop="logo"></el-table-column>-->
            <el-table-column label="LOGO" width="80" align="center">
              <template slot-scope="scope">
                <img v-if="scope.row.logo" :src="scope.row.logo" style="height: 50px; width: 50px">
                <img v-else src="../../../assets/home/default_head.png" style="height: 50px; width: 50px">
              </template>
            </el-table-column>
            <el-table-column label="所属代理" prop="agentName" align="center"></el-table-column>
            <el-table-column label="主播数" prop="anchorCount" width="100" align="center"></el-table-column>
            <el-table-column label="备注" prop="remark" align="center"></el-table-column>
            <el-table-column label="创建时间(CST/UTC)" prop="createDate" width="170" align="center">
              <template v-if="scope.row.createDate" slot-scope="scope">
                <div>{{ scope.row.createDate | TM2CST }}</div>
                <div>{{ scope.row.createDate | TM2UTC }}</div>
              </template>
            </el-table-column>
            <el-table-column
                label="操作"
                width="100"
                align="center"
                v-if="$checkArr('bi:guild:oper')"
            >
              <template slot-scope="scope">
                <el-button
                    type="warning"
                    size="mini"
                    @click="modify(scope.row)"
                    v-if="$checkArr('bi:guild:oper')"
                >修改
                </el-button>
                <!--              <el-button-->
                <!--                  type="danger"-->
                <!--                  size="mini"-->
                <!--                  @click="rePwd(scope.row)"-->
                <!--                  v-if="$checkArr('bi:guild:oper')"-->
                <!--              >重置密码-->
                <!--              </el-button>-->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            class="mt-15"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageObj.pageIndex"
            :page-sizes="page.sizes"
            :page-size.sync="pageObj.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('bi:guild:oper')">
        <el-row>
          <el-col :span="12">
            <el-form
                ref="form"
                :model="formData"
                :rules="{
                name: [
                  {
                    required: true,
                    message: '该项目不能为空',
                    trigger: 'blur',
                  }
                ]
              }"
                label-width="150px"
                label-position="right"
            >
              <el-form-item label="公会名称" prop="name">
                <el-input v-model="formData.name" placeholder="" :disabled="isDisabled"></el-input>
              </el-form-item>
              <!--              <el-form-item label="账号名称" prop="loginName">-->
              <!--                <el-input v-model="formData.loginName" placeholder="" :disabled="isDisabled"></el-input>-->
              <!--              </el-form-item>-->
              <el-form-item label="选择代理：" prop="parentId">
                <el-select v-model="formData.agentId" placeholder="" style="width: 100%">
                  <el-option
                      v-for="(v, k) in dataScopeArr"
                      :key="'权限等级' + k"
                      :label="v.name"
                      :value="v.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="LOGO" prop="logo">
                <el-upload
                    class="avatar-uploader"
                    action="/api/sys/fileUpload/uploadImg/guild"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                  <img v-if="imageUrl" :src="imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>

              <el-form-item label="是否国外" prop="isOuter">
                <el-select v-model="formData.isOuter" placeholder="请选择">
                  <el-option label="否" value="0"></el-option>
                  <el-option label="是" value="1"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="备注" prop="remark">
                <el-input v-model="formData.remark" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-button
                    type="success"
                    @click="
                    $refs.form
                      .validate()
                      .then(() => {
                        save();
                      })
                      .catch(() => {
                        $wMsg('请填写完整信息');
                      })
                  "
                >保存
                </el-button
                >
                <el-button type="info" @click="back()">返回</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.$checkArr("bi:guild:view")) {
      this.guildPageList("初始化");
      this.getGuildList();
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      imageUrl: "",
      isDisabled: false,
      // 查询
      ss: {
        name: ""
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20
      },

      // 表格
      loading: false,
      tableData: [],

      formData: {},
      formTitle: "添加公会",
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.formData.logo = res.msg;
      console.log(res.msg);
    },

    tabChenge(tab, event) {
      if (tab.name === '1') {
        this.isDisabled = false;
        this.activeName = "1";
      }
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('Avatar picture must be JPG format!');
      }
      if (!isLt2M) {
        this.$message.error('Avatar picture size can not exceed 2MB!');
      }
      return isJPG && isLt2M;
    },


    // 查询用户列表--分页
    guildPageList(type) {
      this.loading = true
      this.$api
          .findGuildPage({
            form: {...this.ss},
            ...this.pageObj
          })
          .then((res) => {
            this.activeName = "1";
            this.page.total = res.data.data.total;
            this.tableData = res.data.data.list.map(i => {
              if(i.logo) {
                i.logo = i.logo.includes('http') ? i.logo : this.$imageUrl + i.logo
              }else {
                i.logo = ''
              }
              return i
            })
            if (type == "初始化") {
            }
            this.loading = false
          });
    },
    // 查询
    query() {
      this.pageObj.pageIndex = 1
      this.guildPageList()
    },


    // 获取权限等级字典
    getGuildList() {
      this.$api
          .getAgentList()
          .then((res) => {
            this.dataScopeArr = res.data.data;
          });
    },


    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      console.log(obj);
      this.activeName = "2";
      this.formTitle = "修改公会";
      this.imageUrl = "api/" + v.logo;
      this.isDisabled = true;
    },

    // 删除
    remove(v, falg) {
      this.$wTx(
          "该操作将" + (falg ? "永久" : "") + "删除当前公会条目，确认继续吗？",
          () => {
            this.$OL("正在删除...");
            this.$api[falg ? "guildRelDel" : "guildDel"]({id: v.id}).then((res) => {
              this.back();
              this.$CL();
              this.$sMsg("删除成功");
            });
          },
          () => {
            this.$iMsg("已取消删除");
          }
      );
    },

    // 重置密码
    rePwd(v) {
      this.$wTx(
          "该操作将重置用户：" + v.name + "的登录密码，确认继续吗？",
          () => {
            this.$OL("正在重置密码...");
            this.$api.guildResetPassword({id: v.id}).then((res) => {
              this.$CL();
              this.$sMsg("密码重置成功");
              this.guildPageList();
            });
          },
          () => {
            this.$iMsg("已取消重置密码操作");
          }
      );
    },

    // 保存
    save() {
      if (this.formTitle == "添加公会") {
        this.$api
            .guildSave({
              id: "",
              name: this.formData.name,
              loginName: this.formData.loginName,
              parentId: this.formData.agentId,
              logo: this.formData.logo,
              remark: this.formData.remark,
              isOuter: this.formData.isOuter
            })
            .then((ress) => {
              console.log(ress);
              this.$CL();
              this.$sMsg("添加成功");
              this.back();
            });
      } else {
        this.$api
            .guildUpdate({
              id: this.formData.id,
              //name: this.formData.name,
              logo: this.formData.logo,
              isOuter: this.formData.isOuter,
              remark: this.formData.remark
            })
            .then((ress) => {
              this.$CL();
              this.$sMsg("修改成功");
              this.back();
            });
      }

    },


    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加公会";
      this.isDisabled = false;
      this.guildPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.guildPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.guildPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加公会";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
