<template>
  <div class="card">
    <div class="mod-reve-profile">
      <div class="search-bar">
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-select
                v-model="dateValue"
                @change="setDateRange(dateValue)"
            >
              <el-option
                  v-for="item in dateOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
            <span class="time-text" v-if="dateValue === 1 || dateValue === 2">{{day}}</span>
            <span class="time-text" v-if="dateValue === 3 || dateValue === 4 || dateValue === 5 || dateValue === 6">{{startTime}} 至 {{endTime}}</span>
            <span class="time-text" v-if="dateValue === 7">
            <el-date-picker
                v-model="timeRange"
                type="daterange"
                align="center"
                unlink-panels
                range-separator="-"
                @change="changeDateTime"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="timestamp"
            >></el-date-picker>
          </span>
          </el-form-item>
        </el-form>
      </div>
      <div class="main-container">
        <div class="profile-item">
          <div class="item-title">
            <div class="line"></div>
            <div class="text">充值状况</div>
          </div>
          <div class="item-content income">
            <div class="imcome-item">
              <div class="title-text">充值金额</div>
              <div class="money-amount">{{ totalPayment }}</div>
            </div>
            <div class="imcome-item">
              <div class="title-text">订单数量</div>
              <div class="money-amount">{{ orderNums }}</div>
            </div>
            <div class="imcome-item">
              <div class="title-text">支付笔数</div>
              <div class="money-amount">{{ payNums }}</div>
            </div>
            <div class="imcome-item">
              <div class="title-text">支付人数</div>
              <div class="money-amount">{{ rechargerNum }}</div>
            </div>


<!--            <div v-for="index in 3" :key="index" class="imcome-item">-->
<!--              <div class="title-text">{{ overviewData.title[index-1] }}</div>-->
<!--              <div class="money-amount">{{ overviewData.amount[index-1] }}w</div>-->
<!--            </div>-->
          </div>
        </div>
<!--        <div class="profile-item">-->
<!--          <div class="item-title">-->
<!--            <div class="line"></div>-->
<!--            <div class="text">finaManage.reveTrend</div>-->
<!--          </div>-->
<!--          <div class="item-content trend">-->
<!--            <div class="charts-title">-->
<!--              <div class="charts-title-item" v-for="(item,index) in sumData" :key="item">-->
<!--                <div :style="{background: lineStyle[index].normal.lineStyle.color}" class="line"></div>-->
<!--                <span>{{ item }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="trend-echart">-->
<!--              <div id="trend-echart" style="width:100%;height:400px;"></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  mounted() {
    if (this.$checkArr("order:statistics")) {
      this.setDateRange(1)
      this.userPageList("初始化");
    } else {
      this.$wMsg("无权限");
    }
  },
  data () {
    return {
      orderNums:'',
      payNums:'',
      totalPayment:'',
      rechargerNum:'',
      dateValue: 1, //  时间栏默认选中
      dateRange: [], // 展示的时间参数
      timeRange: '',
      startTime: '',
      endTime: '',
      day: '',  //  今天，昨天选中展示

      dateOptions: [
        {
          label: "今日",
          value: 1
        },
        {
          label: "昨日",
          value: 2
        },
        {
          label: "近7天",
          value: 3
        },
        {
          label: "近30天",
          value: 4
        },
        {
          label: "这个月",
          value: 5
        },
        {
          label: "上个月",
          value: 6
        },
        {
          label: "自定义",
          value: 7
        }
      ],

      storeValue: -1, //  门店下拉的选项
      dropDowmOptions: [
        {
          label: 'date.a',
          value: -1
        }
      ],
      trendDataList: {
        currentDate: [],
        incomeAmount: [],
        payoutAmount: [],
        actualIncome: []
      },
      overviewData: {
        title: [111, 222, 333],
        amount: [],
        ratio: []
      },
      sumData: [
        "v1",
        "v2",
        "v3"
      ],
      lineStyle: [
        {
          normal: {
            color: '#29CB97',
            lineStyle: {
              color: '#29CB97'
            }
          }
        },
        {
          normal: {
            color: '#0058FF',
            lineStyle: {
              color: '#0058FF'
            }
          }
        },
        {
          normal: {
            color: '#F56C6C',
            lineStyle: {
              color: '#F56C6C'
            }
          }
        }
      ]
    }
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    updateDateTime () {
      this.trendDataList = {
        currentDate: [],
        incomeAmount: [],
        payoutAmount: [],
        actualIncome: []
      }
    },
    // 1-7:今日、昨日、最近7天、最近30天、这个月、上个月以及自定义时间,都是2020-8-20模式
    setDateRange (val) {
      this.dateValue = val
      var date = new Date()
      if (val === 1) {
        // 今天
        this.day = moment(date).format('L')
        this.startTime = this.day
        this.endTime = this.day
      } else if (val === 2) {
        // 昨天
        this.day = moment(date).add(-1, 'days').format('L')
        this.startTime = this.day
        this.endTime = this.day
      } else if (val === 3) {
        // 近7天
        this.endTime = moment(date).add(-1, 'days').format('L')
        this.startTime = moment(date).add(-7, 'days').format('L')
      } else if (val === 4) {
        // 近30天
        this.endTime = moment(date).add(-1, 'days').format('L')
        this.startTime = moment(date).add(-30, 'days').format('L')
      } else if (val === 5) {
        // 这个月
        this.endTime = moment(date).format('L')
        this.startTime = moment(date).startOf('month').format('L')
      } else if (val === 6) {
        // 上个月
        let month = moment(date).add(-1, 'month').startOf('month')
        this.endTime = moment(month).endOf('month').format('L')
        this.startTime = month.format('L')
      } else if (val === 7) {
        return
      } else {
        return
      }
      var startTimeStamp = moment(this.startTime).unix() * 1000
      var endTimeStamp = moment(this.endTime).unix() * 1000
      this.dateRange = [startTimeStamp, endTimeStamp]
      this.updateDateTime()
      this.userPageList()
    },
    // 自定义时间栏，改变时调用
    changeDateTime (timeRange) {
      var startTimeStamp = timeRange[0]
      var endTimeStamp = timeRange[1]
      this.dateRange = [startTimeStamp, endTimeStamp]
      this.updateDateTime()
      this.userPageList()
    },
    // 查询
    userPageList() {
      this.$api
        .statistics({
            startTime: this.dateRange ? this.dateRange[0] : null,
            endTime: this.dateRange ? this.dateRange[1] : null,
        })
        .then((res) => {
          this.orderNums = res.data.data.orderNums;
          this.payNums = res.data.data.payNums;
          this.totalPayment = res.data.data.totalPayment;
          this.rechargerNum = res.data.data.rechargerNum;
        });
    },


    // 返回
    back() {
      this.reData();
      this.userPageList();
    },
  },
  watch: {

  },
};
</script>

<style lang="scss" scoped>
.mod-reve-profile {
  .time-text {
    padding: 0 10px;
    color: #999;
  }
  .data-explan {
    padding: 20px;
    background: #edf4ff;
    border: 1px solid #155bd4;
    p {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .profile-item {
    margin-top: 30px;
    .item-title {
      width: 100%;
      background: #F7F8FA;
      box-sizing: border-box;
      padding: 19px 20px;
      display: flex;
      margin: 20px 0 10px 0;
      .line {
        width: 4px;
        height: 19px;
        background: #155bd4;
        opacity: 1;
        border-radius: 2px;
        margin-right: 10px;
      }
      .text {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        opacity: 1;
      }
    }

    .item-content.income {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 25px;
      box-sizing: border-box;
      .imcome-item {
        flex: 1;
        text-align: center;
        .title-text {
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 15px;
        }
        .money-amount {
          font-size: 20px;
          font-weight: 900;
          margin-bottom: 15px;
        }
        .compare {
          font-size: 12px;
        }
      }
    }

    .item-content.trend {
      .charts-title {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
      .charts-title .charts-title-item .line {
        width: 18px;
        height: 8px;
        opacity: 1;
        border-radius: 8px;
        margin-right: 10px;
      }
      .charts-title .charts-title-item {
        margin-right: 40px;
        color: #999999;
        font-size: 12px;
        display: flex;
        align-items: center;
        height: 20px;
      }
    }
  }
}
</style>
