<template>
  <div class="card">
    <el-tabs v-model="activeName" style="height: 100%">
      <el-tab-pane label="主播列表" name="1" style="height: 100%">
        <el-row class="mb-15">
          <el-input
              v-model="ss.anchorId"
              class="w200 mr-15"
              size="small"
              placeholder="请输入主播ID"
              clearable
          ></el-input>
          <el-date-picker
            v-model="selectTime"
            type="datetimerange"
            size="small"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          />
          &nbsp;
          <el-button type="primary" size="small" @click="query">搜索</el-button>
        </el-row>
        <div class="table150">
          <el-table :data="tableData" border height="100%" v-loading="loading">
            <el-table-column label="序号" type="index" align="center" width="50" fixed />
            <el-table-column label="主播ID" prop="anchorId" align="center"></el-table-column>
            <el-table-column label="主播昵称" prop="name" align="center"></el-table-column>
            <!--          <el-table-column label="头像" prop="avatar"></el-table-column>-->
            <el-table-column label="头像" align="center">
              <template slot-scope="scope">
                <img v-if="scope.row.avatar==''" src="../../../assets/home/default_head.png"
                    style="height: 50px; width: 50px">
                <img v-else :src="scope.row.avatar.includes('http')?scope.row.avatar:$imageUrl+scope.row.avatar" style="height: 50px; width: 50px">
              </template>
            </el-table-column>
            <el-table-column label="国家" prop="country" align="center">
              <template slot-scope="scope">
                <div v-for="(item,key,index) in dataCountry" :key="index">
                  <span v-if="scope.row.country == item.locale">{{item.sc}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="所属公会" prop="guildName" align="center"></el-table-column>
            <el-table-column label="主播类型" prop="percentageName" align="center"></el-table-column>
            <el-table-column label="在线状态" prop="net" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.net == 1" class="el-icon-success" style="color: #7AE552FF; font-size: 25px" />
                <span v-else-if="scope.row.net == 2" class="el-icon-remove" style="color: #E6A23C; font-size: 25px" />
                <span v-else class="el-icon-error" style="font-size: 25px" />
              </template>
            </el-table-column>
            <el-table-column label="视频通话时长" prop="callTime" align="center"></el-table-column>
            <el-table-column label="接通率" prop="callPassRate" align="center"></el-table-column>
            <el-table-column label="好评率" prop="favorableRate" align="center"></el-table-column>
          </el-table>
        </div>
        <el-pagination
            class="mt-15"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageObj.pageIndex"
            :page-sizes="page.sizes"
            :page-size.sync="pageObj.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('bi:anchor:oper')">
        <el-row>
          <el-col :span="12">
            <el-form
                ref="form"
                :model="formData"
                :rules="{
                name: [
                  {
                    required: true,
                    message: '该项目不能为空',
                    trigger: 'blur',
                  }
                ]
              }"
                label-width="150px"
                label-position="right"
            >
              <el-form-item label="主播ID：" prop="anchorId">
                <el-input v-model="formData.anchorId" placeholder=""></el-input>
              </el-form-item>
              <!--              <el-form-item label="后台账号：" prop="loginName">-->
              <!--                <el-input v-model="formData.loginName" placeholder=""></el-input>-->
              <!--              </el-form-item>-->
              <el-form-item label="姓名：" prop="realName">
                <el-input v-model="formData.realName" placeholder=""></el-input>
              </el-form-item>
              <!--              <el-form-item label="类型：" prop="percentageId">-->
              <!--                <el-select v-model="formData.percentageId" placeholder="" style="width: 100%">-->
              <!--                  <el-option-->
              <!--                      v-for="(v, k) in dataScopeArr"-->
              <!--                      :key="'权限等级' + k"-->
              <!--                      :label="v.name"-->
              <!--                      :value="v.id"-->
              <!--                  ></el-option>-->
              <!--                </el-select>-->
              <!--              </el-form-item>-->
              <el-form-item label="">
                <el-button
                    type="success"
                    @click="
                    $refs.form
                      .validate()
                      .then(() => {
                        save();
                      })
                      .catch(() => {
                        $wMsg('请填写完整信息');
                      })
                  "
                >保存
                </el-button
                >
                <el-button type="info" @click="back()">返回</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { TM2UTC } from '@/components/js/date.js'
export default {
  mounted() {
    if (this.$checkArr("bi:anchor:view")) {
      this.anchorPageList("初始化");
      this.getPercentageList();
      this.countryCodeList();
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      tableData: [],
      isDisabled: false,

      // 查询
      selectTime: null,
      ss: {
        anchorId: "",
        guildId: "",
        startTime: '',
        endTime: ''
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20,
      },
      // 表格
      loading: false,
      tableData: [],

      formData: {},
      formTitle: "添加主播",
      dataScopeArr: [],
      dataCountry: []
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },


    // 查询用户列表--分页
    anchorPageList(type) {
      this.loading = true
      this.$api
          .findAnchorPage({
            form: { ...this.ss },
            ...this.pageObj
          })
          .then((res) => {
            this.activeName = "1";
            this.page.total = res.data.data.total;
            this.tableData = res.data.data.list;
            if (type == "初始化") {
            }
            this.loading = false
          });
    },
    // 搜索
    query() {
      this.getTimes()
      this.pageObj.pageIndex = 1
      this.anchorPageList()
    },
    getTimes() {
      this.ss.startTime = this.selectTime ? TM2UTC(this.selectTime[0]) : ''
      this.ss.endTime = this.selectTime ? TM2UTC(this.selectTime[1]) : ''
    },

    // 保存
    save() {
      if (this.formTitle == "添加主播") {
        this.$api
            .anchorAdd({
              anchorId: this.formData.anchorId,
              //percentageId: this.formData.percentageId,
              loginName: this.formData.loginName,
              realName: this.formData.realName
            })
            .then((ress) => {
              console.log(ress);
              this.$CL();
              this.$sMsg(ress.data.msg);
              this.back();
            });
      }

    },

    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      console.log(obj);
      this.activeName = "2";
      this.formTitle = "修改主播";
    },


    // 获取主播类型列表
    getPercentageList() {
      this.$api
          .getPercentageList()
          .then((res) => {
            this.dataScopeArr = res.data.data;
          });
    },

    // 查询国家代码
    countryCodeList() {
      this.$api
          .regionList()
          .then((res) => {
            this.dataCountry = res.data.data;
            console.log(this.dataCountry)
          });
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.anchorPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.anchorPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.anchorPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
