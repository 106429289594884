<template>
  <div class="card">
        <el-row class="mb-15">
          <el-input
            v-model="ss.userId"
            class="w200 mr-15"
            size="small"
            placeholder="请输入用户id"
            clearable
          />
          <el-select v-model="ss.way" size="small" placeholder="请选择支付方式" clearable>
            <el-option
              v-for="item in payTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          &nbsp;&nbsp;
          <el-select v-model="ss.status" size="small" placeholder="请选择订单状态" clearable>
            <el-option label="未支付" value="0"></el-option>
            <el-option label="已支付" value="1"></el-option>
            <el-option label="电子支票" value="2"></el-option>
            <el-option label="退款" value="3"></el-option>
          </el-select>
          &nbsp;&nbsp;
          <el-date-picker
            v-model="selectTime"
            type="datetimerange"
            size="small"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          />
          &nbsp;
          <!-- <el-date-picker
              v-model="ss.startTime"
              class="mr-15"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              default-time="00:00:00"
              placeholder="选择支付开始日期"
          >
          </el-date-picker>

          <el-date-picker
              v-model="ss.endTime"
              class="mr-15"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              default-time="00:00:00"
              placeholder="选择支付结束日期"
          >
          </el-date-picker> -->
          <el-button type="primary" size="small" @click="query">搜索</el-button>
        </el-row>
        <span>订单收益：{{ total }}  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;    提成：{{ commission }} </span>
        <div class="table190">
          <el-table :data="tableData" height="100%" border v-loading="loading">
            <el-table-column label="序号" type="index" align="center" width="50" fixed />
            <el-table-column label="用户名" prop="userName" align="center"></el-table-column>
            <el-table-column label="用户id" prop="userId" align="center"></el-table-column>
            <el-table-column label="消费对象id" prop="otherId" align="center"></el-table-column>
            <el-table-column label="所属推广" prop="belongAnchor" align="center"></el-table-column>
            <el-table-column label="充值类型" prop="service" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.service == 'gold'">购买金币</span>
                <span v-if="scope.row.service == 'vip'">蓝钻</span>
                <span v-if="scope.row.service == 'svip'">皇冠</span>
                <span v-if="scope.row.service == 'trans'">翻译</span>
                <span v-if="scope.row.service == 'tick'">语音包</span>
              </template>
            </el-table-column>
            <el-table-column label="充值数量" prop="amount" align="center"></el-table-column>
            <el-table-column label="充值金额" prop="total" align="center"></el-table-column>
            <el-table-column label="充值净额" prop="profit" align="center"></el-table-column>
            <el-table-column label="支付方式" prop="way" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.way | payTypeFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column label="充值订单" prop="no" align="center"></el-table-column>
            <el-table-column label="三方订单号" prop="txId" align="center"></el-table-column>
            <el-table-column label="订单状态" prop="status" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status == -1">全部订单</span>
                <span v-if="scope.row.status == 0">未支付</span>
                <span v-if="scope.row.status == 1">已支付</span>
                <span v-if="scope.row.status == 2">电子支票</span>
                <span v-if="scope.row.status == 3">退款</span>
              </template>
            </el-table-column>
            <el-table-column label="下单时间(CST/UTC)" prop="insertTime" width="170" align="center">
              <template v-if="scope.row.insertTime" slot-scope="scope">
                <div>{{ scope.row.insertTime | TM2CST }}</div>
                <div>{{ scope.row.insertTime | TM2UTC }}</div>
              </template>
            </el-table-column>
            <el-table-column label="支付完成时间(CST/UTC)" prop="paymentDate" width="220" align="center">
              <template v-if="scope.row.paymentDate" slot-scope="scope">
                <div>{{ scope.row.paymentDate | TM2CST }}</div>
                <div>{{ scope.row.paymentDate | TM2UTC }}</div>
              </template>
            </el-table-column>
            <el-table-column label="提成" prop="commission"></el-table-column>
          </el-table>
        </div>
        <el-pagination
          class="mt-15"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageObj.pageIndex"
          :page-sizes="page.sizes"
          :page-size.sync="pageObj.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        />
  </div>
</template>

<script>
import { TM2UTC } from '@/components/js/date.js'
import { payTypeList } from '@/components/js/payType.js'
export default {
  mounted() {
    if (this.$checkArr("order:anchor")) {
      this.userPageList("初始化");
      this.orderTotalByRole("初始化");
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      total: 0,
      commission: 0,
      activeName: "1",

      // 查询
      selectTime: null,
      payTypeList: [],
      ss: {
        userId: '',
        way: '',
        status: '',
        startTime: '',
        endTime: '',
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20,
      },

      // 表格
      loading: false,
      tableData: [],
      
      
      formData: {},
      formTitle: "添加人员",
      exportDisabled: false,
    };
  },
  created() {
    this.payTypeList = payTypeList
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询用户列表--分页
    userPageList(type) {
      this.loading = true
      this.$api.orderListByAnchor({
          form: {...this.ss},
          ...this.pageObj
        }).then((res) => {
          this.page.total = res.data.data.total;
          this.tableData = res.data.data.list;
          if (type == "初始化") {
          }
          this.loading = false
        });
    },
    // 搜索
    query() {
      this.getTimes()
      this.pageObj.pageIndex = 1
      this.userPageList()
      this.orderTotalByRole()
    },
    getTimes() {
      this.ss.startTime = this.selectTime ? TM2UTC(this.selectTime[0]) : ''
      this.ss.endTime = this.selectTime ? TM2UTC(this.selectTime[1]) : ''
    },

    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.activeName = "2";
      this.formTitle = "修改人员";
    },

    // 查询用户列表--分页
    orderTotalByRole(type) {
      this.$api.orderTotalByRole({
          ...this.ss
        }).then((res) => {
          this.total = res.data.data.total;
          this.commission = res.data.data.commission;
          if (type == "初始化") {
          }
        });
    },

    // 查询用户列表--分页
    searchOrder() {
      this.userPageList();
      this.orderTotalByRole();
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加人员";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
