<template>
  <div class="card">
    <el-tabs v-model="activeName" style="height: 100%">
      <el-tab-pane label="系统消息列表" name="1" style="height: 100%">
        <el-row class="mb-15">
          <el-input
              v-model="queryParams.title"
              class="w200 mr-15"
              size="small"
              placeholder="请输入标题"
              clearable
          />
          <el-button type="primary" size="small" @click="query">搜索</el-button>
        </el-row>
        <div class="table150">
          <el-table :data="tableData" border height="100%" v-loading="loading">
            <el-table-column label="序号" type="index" align="center" width="50" fixed />
            <el-table-column label="标题" align="center" prop="title"/>
            <el-table-column label="内容" align="center" prop="content"/>
            <el-table-column label="类型" align="center" prop="type"/>
            <el-table-column label="路径" align="center" prop="url"/>
            <el-table-column
                label="操作"
                align="center"
                width="200"
                v-if="$checkArr('bi:sysMsg:oper')"
            >
              <template slot-scope="scope">
                <el-button
                    type="warning"
                    size="mini"
                    @click="modify(scope.row)"
                    v-if="$checkArr('bi:sysMsg:oper')"
                >修改
                </el-button
                >
                <el-button
                    type="danger"
                    size="mini"
                    @click="remove(scope.row)"
                    v-if="$checkArr('bi:sysMsg:oper')"
                >删除
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            class="mt-15"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page.index"
            :page-sizes="page.sizes"
            :page-size.sync="page.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('bi:sysMsg:oper')">
        <el-row>
          <el-col :span="12">
            <el-form
                ref="form"
                :model="formData"
                :rules="{
                title: [
                  {
                    required: true,
                    message: '该项目不能为空',
                    trigger: 'blur',
                  },
                ]
              }"
                label-width="150px"
                label-position="right"
            >
              <el-form-item label="标题" prop="title">
                <el-input v-model="formData.title" placeholder="请输入标题"/>
              </el-form-item>
              <el-form-item label="内容" prop="content">
                <el-input v-model="formData.content" placeholder="请输入内容"/>
              </el-form-item>
              <el-form-item label="类型" prop="type">
                <el-input v-model="formData.type" placeholder="0：系统1：个人"/>
              </el-form-item>
              <el-form-item label="路径" prop="url">
                <el-input v-model="formData.url" placeholder="请输入路径"/>
              </el-form-item>
              <el-form-item label="">
                <el-button
                    type="success"
                    @click="
                    $refs.form
                      .validate()
                      .then(() => {
                        save();
                      })
                      .catch(() => {
                        $wMsg('请填写完整信息');
                      })
                  "
                >保存
                </el-button
                >
                <el-button type="info" @click="back()">返回</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  mounted() {
    this.pageSysMsg("初始化");
  },
  data() {
    return {
      activeName: "1",
      // 查询
      queryParams: {
        title: ""
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20,
      },
      // 表格
      tableData: [],
      loading: false,

      formData: {},
      formTitle: "添加系统消息",
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询用户列表--分页
    pageSysMsg(type) {
      this.loading = true
      this.$api.pageSysMsg({
        form: {...this.queryParams},
        ...this.pageObj
      }).then((res) => {
        this.page.total = res.data.data.total;
        this.tableData = res.data.data.list;
        if (type == "初始化") {
        }
        this.loading = false
      });
    },
     // 搜索
     query() {
      this.pageObj.pageIndex = 1
      this.pageSysMsg()
    },

    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.activeName = "2";
      this.formTitle = "修改系统消息";
    },

    // 删除
    remove(v, falg) {
      this.$wTx(
          "该操作将" + (falg ? "永久" : "") + "删除当前条目？",
          () => {
            this.$OL("正在删除...");
            this.$api.sysMsgDel({id: v.id}).then((res) => {
              this.back();
              this.$CL();
              this.$sMsg("删除成功");
            });
          },
          () => {
            this.$iMsg("已取消删除");
          }
      );
    },

    // 保存
    save() {
      // this.$OL("正在保存...");
      this.$api
          .sysMsgUpdate({
            id: this.formTitle == "添加系统消息" ? "" : this.formData.id,
            title: this.formData.title,
            type: this.formData.type,
            url: this.formData.url,
            content: this.formData.content,
          })
          .then((ress) => {
            this.$CL();
            this.$sMsg(this.formTitle == "添加系统消息" ? "添加成功" : "修改成功");
            this.back();
          });
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加系统消息";
      this.pageSysMsg();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.pageSysMsg();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.pageSysMsg();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加系统消息";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
