<template>
  <div class="card">
    <el-tabs style="height: 100%" v-model="activeName">
        <el-tab-pane style="height: 100%" label="用户列表" name="1">
          <el-row class="mb-10">
            <el-input v-model="ss.id" class="w200 mr-15 mb-10" size="small" placeholder="请输入用户id" clearable />

            <el-input v-model="ss.phone" class="w200 mr-15" size="small" placeholder="请输入用户手机号" clearable></el-input>

            <el-input v-model="ss.anchorId" class="w200 mr-15" size="small" placeholder="请输入主播id" clearable></el-input>

            <el-date-picker
              v-model="selectTime"
              type="datetimerange"
              size="small"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
            />
            &nbsp;
            <el-select v-model="ss.country" size="small" placeholder="选择国家" filterable style="width: 180px" clearable>
              <el-option v-for="(v, k) in dataScopeArr" :label="v.sc" :value="v.locale" :key="'国家' + k"></el-option>
            </el-select>
            &nbsp;
            <el-select v-model="ss.loginWay" size="small" placeholder="登录方式" style="width: 150px;" clearable>
              <el-option label="手机号" value="1"></el-option>
              <el-option label="faceBook" value="2"></el-option>
              <el-option label="苹果" value="3"></el-option>
              <el-option label="谷歌" value="4"></el-option>
            </el-select>
            &nbsp;
            <el-select v-model="ss.role" size="small" placeholder="角色" style="width: 150px;" clearable>
              <el-option label="普通用户" value="0"></el-option>
              <el-option label="主播" value="1"></el-option>
              <el-option label="阻止" value="2"></el-option>
            </el-select>
            &nbsp;
            <el-select v-model="ss.gender" size="small" placeholder="性别" style="width: 150px;" clearable>
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
            </el-select>
            &nbsp;
            <el-select v-model="ss.net" size="small" placeholder="状态" style="width: 150px;" clearable>
              <el-option label="在线" value="1"></el-option>
              <el-option label="忙碌" value="2"></el-option>
              <el-option label="离线" value="0"></el-option>
            </el-select>
            &nbsp;
            <el-select v-model="ss.channel" size="small" placeholder="来源" style="width: 150px;" clearable>
              <el-option label="广告" value="adjust" />
              <el-option label="自然" value="Organic" />
              <el-option label="facebook" value="Unattributed" />
            </el-select>
            &nbsp;
            <el-button size="small" type="primary" @click="query" style="margin-top: 10px">搜索</el-button>
            <el-button type="success" size="small" @click="downloadUser()" :disabled="exportDisabled"
                       v-if="$checkArr('user:downloadUser')">导出</el-button>
          </el-row>
          <div class="table190">
            <el-table :data="tableData" border height="100%" v-loading="loading">
              <el-table-column label="序号" type="index" align="center" width="50" fixed />
              <el-table-column label="用户名" prop="name" width="150" align="center"></el-table-column>
              <el-table-column label="用户id" prop="id" align="center"></el-table-column>
              <el-table-column label="用户手机号" prop="phone" width="120" align="center"></el-table-column>
              <el-table-column label="头像" align="center">
                <template slot-scope="scope">
                  <el-popover v-if="scope.row.avatar" placement="top-start" trigger="click">
                    <a :href="scope.row.avatar" target="_blank" title="查看最大化图片">
                      <img :src="scope.row.avatar" style="width: 300px;height: 300px">
                    </a>
                    <img slot="reference" :src="scope.row.avatar" style="width: 50px;height: 50px; cursor:pointer">
                  </el-popover>
                  <img v-else src="../../../assets/home/default_head.png" style="height: 50px; width: 50px">
                </template>
              </el-table-column>
              <el-table-column label="国家" prop="country" align="center">
                <template slot-scope="scope">
                  <div v-for="(item, key, index) in dataScopeArr" :key="index">
                    <span v-if="scope.row.country == item.locale">{{ item.sc }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="性别" prop="gender" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.gender == 1">男</span>
                  <span v-else>女</span>
                </template>
              </el-table-column>
              <el-table-column label="角色" prop="role" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.role == 0">普通用户</span>
                  <span v-if="scope.row.role == 1">主播</span>
                  <span v-if="scope.row.role == 2">阻止</span>
                </template>
              </el-table-column>
              <el-table-column label="会员" prop="vip" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.vip == 0">普通</span>
                  <span v-if="scope.row.vip == 1">vip</span>
                </template>
              </el-table-column>
              <el-table-column label="会员过期时间(CST/UTC)" prop="expireTimeStr" width="190" align="center">
                <template v-if="scope.row.expireTime" slot-scope="scope">
                  <div class="table_time">{{ scope.row.expireTime | TM2CST}}</div>
                  <div class="table_time">{{ scope.row.expireTime | TM2UTC }}</div>
                </template>
              </el-table-column>
              <el-table-column label="所属推广" prop="belongAnchor" align="center"></el-table-column>
              <el-table-column label="来源" prop="channel" align="center" :formatter="fromFormat" />
              <el-table-column label="金币" prop="gold" align="center"></el-table-column>
              <el-table-column label="免费通话包" width="100" prop="freeTickCount" align="center"></el-table-column>
              <el-table-column label="付费通话包" width="100" prop="tickCount" align="center"></el-table-column>
              <el-table-column label="免费翻译包" width="100" prop="freeTransCount" align="center"></el-table-column>
              <el-table-column label="付费翻译包" width="100" prop="formalTransCount" align="center"></el-table-column>
              <el-table-column label="注册时间(CST/UTC)" prop="createTime" width="170" align="center">
                <template v-if="scope.row.createTime" slot-scope="scope">
                  <div class="table_time">{{ scope.row.createTime | TM2CST }}</div>
                  <div class="table_time">{{ scope.row.createTime | TM2UTC }}</div>
                </template>
              </el-table-column>
              <el-table-column label="注册终端" prop="os" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.os == 1">
                    <el-button type="text" @click="brandInfo(scope.row.brand)">android</el-button>
                  </span>
                  <span v-else-if="scope.row.os == 2">
                    <el-button type="text" @click="brandInfo(scope.row.brand)">ios</el-button>
                  </span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="最后一次登陆时间(CST/UTC)" prop="lastLoginTime" width="210" align="center">
                <template v-if="scope.row.lastLoginTime" slot-scope="scope">
                  <div class="table_time">{{ scope.row.lastLoginTime | TM2CST }}</div>
                  <div class="table_time">{{ scope.row.lastLoginTime | TM2UTC }}</div>
                </template>
              </el-table-column>
              <el-table-column label="第三方登录" prop="thirdLogin" width="100" align="center"></el-table-column>
              <el-table-column label="在线" prop="net" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.net == 1" class="el-icon-success"
                    style="color: #7AE552; font-size: 25px"></span>
                  <span v-else-if="scope.row.net == 2" class="el-icon-remove"
                    style="color: #E6A23C; font-size: 25px"></span>
                  <span v-else class="el-icon-error" style="font-size: 25px"></span>
                </template>
              </el-table-column>
              <el-table-column label="ip" prop="ip" width="150" align="center"></el-table-column>
              <el-table-column label="账号状态" prop="status" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.status == 1">注销</span>
                  <span v-else>正常</span>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="260px" align="center"
                v-if="$checkArr('webUser:update') || $checkArr('webUser:resetPassword') || $checkArr('webUser:deleteUserAvatar')">
                <template slot-scope="scope">
                  <el-button type="warning" size="mini" @click="modify(scope.row)" v-if="$checkArr('webUser:update')">修改
                  </el-button>
                  <el-button type="primary" size="mini" v-if="$checkArr('webUser:resetPassword')"
                    @click="restPwd(scope.row)">重置密码
                  </el-button>

                  <el-button size="mini" type="danger" v-if="$checkArr('webUser:deleteUserAvatar')"
                    @click="removeAvatar(scope.row.id)">删除头像
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <el-pagination class="mt-15" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page.sync="pageObj.pageIndex" :page-sizes="page.sizes" :page-size.sync="pageObj.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="page.total">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('webUser:update')">
          <el-row>
            <el-col :span="12">
              <el-form ref="form" :model="formData" :rules="{
                name: [
                  {
                    required: true,
                    message: '该项目不能为空',
                    trigger: 'blur',
                  },
                ],
                loginName: [
                  {
                    required: true,
                    message: '该项目不能为空',
                    trigger: 'blur',
                  },
                ],
                email: [
                  {
                    required: true,
                    message: '该项目不能为空',
                    trigger: 'blur',
                  },
                  {
                    pattern: /^\S+@{1}\S+$/,
                    trigger: 'blur',
                    message: '格式错误',
                  },
                ],
                wxOpenId: [
                  {
                    required: true,
                    message: '该项目不能为空',
                    trigger: 'blur',
                  },
                  {
                    pattern: /^[0-9]{11}$/,
                    trigger: 'blur',
                    message: '格式错误',
                  },
                ]
              }" label-width="150px" label-position="right">
                <el-form-item label="昵称" prop="name">
                  <el-input v-model="formData.name" placeholder="" style="width: 300px"></el-input>
                  <el-button style="margin-left: 15px" type="success" size="mini"
                    @click="saveName(formData.id, formData.name)" v-if="$checkArr('user:saveName')">保存</el-button>
                </el-form-item>
                <el-form-item label="性别" prop="gender">
                  <el-select v-model="formData.gender" placeholder="请选择" style="width: 300px">
                    <el-option label="男" :value="1"></el-option>
                    <el-option label="女" :value="2"></el-option>
                  </el-select>
                  <el-button style="margin-left: 15px" type="success" size="mini"
                    @click="saveGender(formData.id, formData.gender)" v-if="$checkArr('user:saveGender')">保存</el-button>
                </el-form-item>

                <el-form-item label="生日" prop="birth">
                  <el-date-picker v-model="formData.birth" class="mr-15" type="date" value-format="yyyy-MM-dd"
                    placeholder="选择日期" style="width: 300px">
                  </el-date-picker>
                  <el-button type="success" size="mini" @click="saveBirth(formData.id, formData.birth)"
                    v-if="$checkArr('user:saveBirth')">保存</el-button>
                </el-form-item>
                <el-form-item label="国家" prop="country">
                  <el-select v-model="formData.country" placeholder="" filterable style="width: 300px">
                    <el-option v-for="(v, k) in dataScopeArr" :label="v.sc" :value="v.locale" :key="k"></el-option>
                  </el-select>
                  <el-button style="margin-left: 15px" type="success" size="mini"
                    @click="saveCountry(formData.id, formData.country)" v-if="$checkArr('user:saveCountry')">保存
                  </el-button>
                </el-form-item>
                <el-form-item label="会员" prop="vip">
                  <el-select v-model="formData.vip" placeholder="请选择" style="width: 300px">
                    <el-option label="普通用户" :value="0"></el-option>
                    <el-option label="vip" :value="1"></el-option>
                  </el-select>
                  <el-button style="margin-left: 15px" type="success" size="mini"
                    @click="saveVip(formData.id, formData.vip)" v-if="$checkArr('user:saveVip')">保存</el-button>
                </el-form-item>
                <el-form-item label="会员过期时间" prop="expireTimeStr">
                  <el-date-picker v-model="formData.expireTimeStr" class="mr-15" type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="选择日期" style="width: 300px">
                  </el-date-picker>
                  <el-button type="success" size="mini" @click="saveExpireTime(formData.id, formData.expireTimeStr)"
                    v-if="$checkArr('user:saveExpireTime')">保存</el-button>
                </el-form-item>
                <el-form-item label="角色" prop="role">
                  <el-select v-model="formData.role" placeholder="请选择" style="width: 300px">
                    <el-option label="普通用户" :value="0"></el-option>
                    <el-option label="主播" :value="1"></el-option>
                    <el-option label="阻止" :value="2"></el-option>
                  </el-select>
                  <el-button style="margin-left: 15px" type="success" size="mini"
                    @click="saveRole(formData.id, formData.role)" v-if="$checkArr('user:saveRole')">保存</el-button>
                </el-form-item>
                <el-form-item label="所属推广" prop="belongAnchor">
                  <el-input v-model="formData.belongAnchor" placeholder="" style="width: 300px"></el-input>
                  <el-button style="margin-left: 15px" type="success" size="mini"
                    @click="saveBelongAnchor(formData.id, formData.belongAnchor)" :disabled="bindDisabled"
                    v-if="$checkArr('user:saveBelongAnchor')">保存</el-button>
                </el-form-item>
                <el-form-item label="金币" prop="gold">
                  <el-input v-model="formData.gold" type="number" placeholder="" style="width: 300px"></el-input>
                  <el-button style="margin-left: 15px" type="success" size="mini"
                    @click="saveGold(formData.id, formData.gold)" v-if="$checkArr('user:saveGold')">保存</el-button>
                </el-form-item>
                <el-form-item label="免费语音包" prop="tickCount">
                  <el-input v-model="formData.freeTickCount" type="number" placeholder="" style="width: 300px">
                  </el-input>
                  <el-button style="margin-left: 15px" type="success" size="mini"
                    @click="saveFreeTickCount(formData.id, formData.freeTickCount)"
                    v-if="$checkArr('user:saveFreeTickCount')">保存</el-button>
                </el-form-item>
                <el-form-item label="付费语音包" prop="tickCount">
                  <el-input v-model="formData.tickCount" type="number" placeholder="" style="width: 300px"></el-input>
                  <el-button style="margin-left: 15px" type="success" size="mini"
                    @click="saveTickCount(formData.id, formData.tickCount)" v-if="$checkArr('user:saveTickCount')">保存
                  </el-button>
                </el-form-item>
                <el-form-item label="付费翻译包" prop="formalTransCount">
                  <el-input v-model="formData.formalTransCount" type="number" placeholder="" style="width: 300px">
                  </el-input>
                  <el-button style="margin-left: 15px" type="success" size="mini"
                    @click="saveFormalTransCount(formData.id, formData.formalTransCount)"
                    v-if="$checkArr('user:saveFormalTransCount')">保存</el-button>
                </el-form-item>
                <el-form-item label="免费翻译包" prop="freeTransCount">
                  <el-input v-model="formData.freeTransCount" type="number" placeholder="" style="width: 300px">
                  </el-input>
                  <el-button style="margin-left: 15px" type="success" size="mini"
                    @click="saveFreeTransCount(formData.id, formData.freeTransCount)"
                    v-if="$checkArr('user:saveFreeTransCount')">保存</el-button>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                  <el-input v-model="formData.phone" type="number" placeholder="" style="width: 300px"></el-input>
                  <el-button style="margin-left: 15px" type="success" size="mini"
                    @click="savePhone(formData.id, formData.phone)" v-if="$checkArr('user:savePhone')">保存</el-button>
                </el-form-item>
                <!--              <el-form-item label="状态" prop="status">-->
                <!--                <el-input v-model="formData.status" placeholder="" style="width: 300px"></el-input>-->
                <!--              </el-form-item>-->
                <el-form-item label="">
                  <el-button type="info" @click="back()">返回</el-button>
                  <el-button type="primary" @click="synCache()" v-if="$checkArr('webUser:synCache')">同步缓存</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </el-tab-pane>
    </el-tabs>

    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form>
        <el-form-item label="昵称" prop="userId">
          <el-input v-model="formData.name" placeholder="" style="width: 300px" disabled></el-input>
        </el-form-item>
      </el-form>
      <el-form>
        <el-form-item label="密码" prop="pwd">
          <el-input v-model="formData.pwd" placeholder="" style="width: 300px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="savePwd()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="设备信息" :visible.sync="brandVisible" width="30%" :before-close="handleClose">
      <span>系统：{{ system == 1 ? "android" : "ios" }}</span><br />
      <span>语言代码：{{ language }}</span><br />
      <span>国家代码：{{ country }}</span><br />
      <span>软件版本：{{ edition }}</span><br />
      <span>操作系统：{{ operatingSystem }}</span><br />
      <span>手机型号：{{ phoneModel }}</span><br />
      <span>手机厂商：{{ manufacturer }}</span><br />
      <span slot="footer" class="dialog-footer">
        <el-button @click="brandVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CountryCode from '@/components/json/countryCode.json'
import { TM2UTC } from '@/components/js/date.js'

export default {
  mounted() {
    if (this.$checkArr("webUser:pageList")) {
      this.userPageList("初始化");
      this.countryCodeList();
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      // 查询
      selectTime: null,
      ss: {
        id: '',
        phone: '',
        anchorId: '',
        startTime: '',
        endTime: '',
        country: '',
        loginWay: '',
        role: '',
        gender: '',
        net: '',
        channel: ''
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20,
      },
      // 表格
      tableData: [],
      loading: false,
      

      brandVisible: false,
      dialogVisible: false,
      formData: {},
      formTitle: "修改人员",
      dataScopeArr: [],
      bindDisabled: false,
      exportDisabled: false
    };
  },
  methods: {
    fromFormat(row, column) {
      if (row[column.property] === 'adjust') {
        return '广告'
      } else if (row[column.property] === 'Organic') {
        return '自然'
      } else if (row[column.property] === 'Unattributed') {
        return 'Facebook'
      }
    },
    // 重置formData
    reData() {
      this.formData = {};
    },

    handleClose(done) {
      this.$confirm('确认关闭？').then(_ => {
        done();
      })
      .catch(_ => {
      });
    },
    // 查询用户列表--分页
    userPageList(type) {
      this.loading = true
      this.$api.webUserList({
        form: {...this.ss},
        ...this.pageObj
      }).then((res) => {
        this.page.total = res.data.data.total
        this.tableData = res.data.data.list.map(i => {
          if (i.avatar && !i.avatar.includes('http')) {
            i.avatar = this.$imageUrl + i.avatar
          }
          return i
        })
        if (type == "初始化") {
        }
        this.loading = false
      })
    },
    // 搜索
    query() {
      this.getTimes()
      this.pageObj.pageIndex = 1
      this.userPageList()
    },
    getTimes() {
      this.ss.startTime = this.selectTime ? TM2UTC(this.selectTime[0]) : ''
      this.ss.endTime = this.selectTime ? TM2UTC(this.selectTime[1]) : ''
    },

    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.activeName = "2";
      this.formTitle = "修改人员";
    },


    // 重置密码
    restPwd(v) {
      this.dialogVisible = true;
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.formData.pwd = "";
    },
    //展示设备信息
    brandInfo(v) {
      this.brandVisible = true;
      let obj = JSON.parse(JSON.stringify(v));
      var objList = obj.split("$");
      //系统 1.安卓 2.ios
      this.system = objList[0];
      //语言代码
      this.language = objList[1];
      //国家代码
      this.country = objList[2];
      //软件版本
      this.edition = objList[3];
      //操作系统
      this.operatingSystem = objList[4];
      //手机型号
      this.phoneModel = objList[5];
      //手机厂商
      this.manufacturer = objList[6];

    },

    // 设备信息
    brandVisible() {
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .webUserResetPassword({
                id: this.formData.id,
                pwd: this.formData.pwd
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改密码成功");
                this.dialogVisible = false;
              });
          } else {
            this.$eMsg("该学/工号已存在！");
          }
        });
    },

    // 重置密码
    rePwd(v) {
      this.$wTx(
        "该操作将重置用户：" + v.name + "的登录密码，确认继续吗？",
        () => {
          this.$OL("正在重置密码...");
          this.$api.webUserResetPassword({ id: v.id }).then((res) => {
            this.$CL();
            this.$sMsg("密码重置成功");
            this.userPageList();
          });
        },
        () => {
          this.$iMsg("已取消重置密码操作");
        }
      );
    },

    // 重置密码
    savePwd() {
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .webUserResetPassword({
                id: this.formData.id,
                pwd: this.formData.pwd
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改密码成功");
                this.dialogVisible = false;
              });
          } else {
            this.$eMsg("该学/工号已存在！");
          }
        });
    },

    // 删除
    removeAvatar(v) {
      this.$wTx(
        "该操作将删除该用户头像，确认继续吗？",
        () => {
          this.$OL("正在删除中...");
          this.$api
            .deleteAvatar({ id: v })
            .then((res) => {
              this.$sMsg("删除成功");
              this.back();
              this.$CL();
            });
        },
        () => {
          this.$iMsg("已取消删除");
        }
      );
    },

    // 修改昵称
    saveName(id, str) {
      // this.$OL("正在保存...");
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .updateName({
                id: id,
                name: str
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                // this.back();
              });
          } else {
            this.$eMsg(res.data.message);
          }
        });
    },


    // 修改性别
    saveGender(id, str) {
      // this.$OL("正在保存...");
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .updateGender({
                id: id,
                gender: str
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                // this.back();
              });
          } else {
            this.$eMsg(res.data.message);
          }
        });
    },

    // 修改角色
    saveRole(id, str) {
      // this.$OL("正在保存...");
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .updateRole({
                id: id,
                role: str
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                // this.back();
              });
          } else {
            this.$eMsg(res.data.message);
          }
        });
    },

    // 修改所属主播
    saveBelongAnchor(id, str) {
      // this.$OL("正在保存...");
      this.bindDisabled = true
      this.$api
        .bindAnchor({
          id: id,
          belongAnchor: str
        })
        .then((ress) => {
          this.bindDisabled = false
          this.$CL();
          this.$sMsg("修改成功");
          // this.back();
        });
    },

    // 修改金币
    saveGold(id, str) {
      // this.$OL("正在保存...");
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .updateGold({
                id: id,
                gold: str
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                // this.back();
              });
          } else {
            this.$eMsg(res.data.message);
          }
        });
    },

    // 修改语音包
    saveTickCount(id, str) {
      // this.$OL("正在保存...");
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .updateTickCount({
                id: id,
                tickCount: str
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                // this.back();
              });
          } else {
            this.$eMsg(res.data.message);
          }
        });
    },

    // 修改免费语音包
    saveFreeTickCount(id, str) {
      // this.$OL("正在保存...");
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .updateFreeTickCount({
                id: id,
                freeTickCount: str
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                // this.back();
              });
          } else {
            this.$eMsg(res.data.message);
          }
        });
    },

    // 修改付费语音包
    saveFormalTransCount(id, str) {
      // this.$OL("正在保存...");
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .updateFormalTransCount({
                id: id,
                formalTransCount: str
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                // this.back();
              });
          } else {
            this.$eMsg(res.data.message);
          }
        });
    },

    // 修改免费语音包
    saveFreeTransCount(id, str) {
      // this.$OL("正在保存...");
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .updateFreeTransCount({
                id: id,
                freeTransCount: str
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                // this.back();
              });
          } else {
            this.$eMsg(res.data.message);
          }
        });
    },

    // 修改手机号
    savePhone(id, str) {
      // this.$OL("正在保存...");
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .updatePhone({
                id: id,
                phone: str
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                // this.back();
              });
          } else {
            this.$eMsg(res.data.message);
          }
        });
    },

    // 修改国家
    saveCountry(id, str) {
      // this.$OL("正在保存...");
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .updateCountry({
                id: id,
                country: str
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                // this.back();
              });
          } else {
            this.$eMsg(res.data.message);
          }
        });
    },


    // 修改会员
    saveVip(id, str) {
      debugger;
      // this.$OL("正在保存...");
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .updateVip({
                id: id,
                vip: str
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                // this.back();
              });
          } else {
            this.$eMsg(res.data.message);
          }
        });
    },


    // 修改会员过期时间
    saveExpireTime(id, str) {
      // this.$OL("正在保存...");
      debugger;
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .updateExpireTime({
                id: id,
                expireTimeStr: str
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                // this.back();
              });
          } else {
            this.$eMsg(res.data.message);
          }
        });
    },


    // 修改生日
    saveBirth(id, str) {
      // this.$OL("正在保存...");
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .updateBirth({
                id: id,
                birth: str
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                // this.back();
              });
          } else {
            this.$eMsg(res.data.message);
          }
        });
    },



    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "修改人员";
      this.userPageList();
    },


    //同步缓存
    synCache() {
      this.$api
        .synCache({
          id: this.formData.id
        })
        .then((ress) => {
          this.$CL();
          this.$sMsg("同步成功");
          this.back();
        });
    },

    // 用户导出列表
    downloadUser() {
      this.getTimes()
      let user = this.$getCK("user");
      var officeId = user.currentOfficeId;
      var sysUserId = user.cUserId;

      this.exportDisabled = true
      let startTime = ""
      let endTime = ""
      if (this.ss.startTime !== undefined && this.ss.startTime.length > 0) {
        startTime = this.ss.startTime;
      }
      if (this.ss.endTime !== undefined && this.ss.endTime.length > 0) {
        endTime = this.ss.endTime;
      }
      window.location.href = 'https://boss-api.yaku.online/rpc/admin/downloadUser?startTime=' + startTime + "&endTime=" + endTime + "&officeId=" + officeId + "&sysUserId=" + sysUserId
      // window.location.href = 'http://127.0.0.1:8187/rpc/admin/downloadUser?startTime=' + startTime + "&endTime=" + endTime + "&officeId=" + officeId + "&sysUserId=" + sysUserId
      // alert(dz_url)
      this.exportDisabled = false
    },


    // 查询国家代码
    countryCodeList() {
      // this.dataScopeArr = CountryCode.country
      this.$api
        .regionList()
        .then((res) => {
          this.dataScopeArr = res.data.data;
        });
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "修改人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped>


</style>
