<template>
  <div class="card">
    <el-tabs v-model="activeName" style="height: 100%;">
      <el-tab-pane label="主播申请列表" name="1" style="height: 100%;">
        <el-row class="mb-15">
          <el-input v-model="ss.anchorId" class="w200 mr-15" size="small" placeholder="请输入主播id" clearable />
          <el-date-picker
            v-model="selectTime"
            type="datetimerange"
            size="small"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          />
          &nbsp;
          <!-- <el-date-picker v-model="ss.startTime" class="mr-15" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择开始日期">
          </el-date-picker>

          <el-date-picker v-model="ss.endTime" class="mr-15" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择结束日期">
          </el-date-picker> -->
          <el-button type="primary" size="small" @click="query">搜索</el-button>
        </el-row>
        <div class="table150">
          <el-table :data="tableData" border height="100%" v-loading="loading">
            <el-table-column label="序号" type="index" width="50" align="center" fixed />
            <el-table-column label="昵称" prop="nickName" align="center" />
            <el-table-column label="主播id" prop="anchorId" align="center" />
            <el-table-column label="头像" align="center">
              <template slot-scope="scope">
                <span>
                  <img v-if="scope.row.avatar == ''" src="../../../assets/home/default_head.png"
                    style="height: 50px; width: 50px">
                </span>
                <span v-if="scope.row.avatar">
                  <el-popover placement="top-start" trigger="click">
                    <!--trigger属性值：hover、click、focus 和 manual-->
                    <a :href="scope.row.avatar" target="_blank" title="查看最大化图片">
                      <img :src="scope.row.avatar.includes('http') ? scope.row.avatar : $imageUrl + scope.row.avatar"
                        style="width: 300px;height: 300px">
                    </a>
                    <img slot="reference"
                      :src="scope.row.avatar.includes('http') ? scope.row.avatar : $imageUrl + scope.row.avatar"
                      style="width: 50px;height: 50px; cursor:pointer">
                  </el-popover>
                </span>
                <!--              <img v-if="scope.row.avatar==''" src="../../../assets/home/default_head.png" style="height: 50px; width: 50px">-->
                <!--              <img v-else :src="$imageUrl+scope.row.avatar" style="height: 50px; width: 50px">-->
              </template>
            </el-table-column>
            <el-table-column label="国家" prop="country" align="center">
              <template slot-scope="scope">
                <div v-for="(item, key, index) in dataCountry" :key="index">
                  <span v-if="scope.row.country == item.locale">{{ item.sc }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="工会" prop="guildName" align="center" />
            <el-table-column label="申请时间(CST/UTC)" prop="createTime" width="170" align="center">
              <template v-if="scope.row.createTime" slot-scope="scope">
                <div>{{ scope.row.createTime | TM2CST }}</div>
                <div>{{ scope.row.createTime | TM2UTC }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="260px" v-if="$checkArr('anchorApply:examineAnchor')">
              <template slot-scope="scope">
                <el-button type="primary" size="small" :disabled="scope.row.status != 0" @click="modify(scope.row)"
                  v-if="$checkArr('anchorApply:examineAnchor')">审核</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination class="mt-15" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="pageObj.pageIndex" :page-sizes="page.sizes" :page-size.sync="pageObj.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="page.total">
        </el-pagination>
      </el-tab-pane>

      <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('anchorApply:examineAnchor')">
        <el-row>
          <el-col :span="12">
            <el-form ref="form" :model="formData" :rules="{
              name: [
                {
                  required: true,
                  message: '该项目不能为空',
                  trigger: 'blur',
                }
              ]
            }" label-width="150px" label-position="right">
              <el-form-item label="审核状态" prop="status">
                <el-radio v-model="formData.status" label="1">同意</el-radio>
                <el-radio v-model="formData.status" label="2">拒绝</el-radio>

              </el-form-item>
              <el-form-item label="选择类型：" prop="percentageId">
                <el-select v-model="formData.percentageId" placeholder="" style="width: 100%">
                  <el-option v-for="(v, k) in dataScopeArr" :label="v.name" :value="v.id" :key="k"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="拒绝理由" prop="reason">
                <el-input v-model="formData.reason" type="textarea" :rows="2"></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-button type="success" @click="
                  $refs.form
                    .validate()
                    .then(() => {
                      save();
                    })
                    .catch(() => {
                      $wMsg('请填写完整信息');
                    })
                ">保存
                </el-button>
                <el-button type="info" @click="back()">返回</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { TM2UTC } from '@/components/js/date.js'

export default {
  mounted() {
    if (this.$checkArr("anchorApply:pageList")) {
      this.userPageList("初始化");
      this.getPercentageList();
      this.countryCodeList();
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      // 查询
      selectTime: null,
      ss: {
        anchorId: "",
        status: 0,
        startTime: '',
        endTime: ''
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20
      },

      // 表格
      loading: false,
      tableData: [],

      formData: {},
      formTitle: "审核",
      dialogVisible: false,
      dataScopeArr: [],
      dataCountry: []
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询主播审核列表--分页
    userPageList(type) {
      this.loading = true
      this.$api
        .anchorApplyList({
          form: {...this.ss},
          ...this.pageObj
        })
        .then((res) => {
          this.page.total = res.data.data.total;
          this.tableData = res.data.data.list;
          if (type == "初始化") {
          }
          this.loading = false
        });
    },
    // 搜索
    query() {
      this.getTimes()
      this.pageObj.pageIndex = 1
      this.userPageList()
    },
    getTimes() {
      this.ss.startTime = this.selectTime ? TM2UTC(this.selectTime[0]) : ''
      this.ss.endTime = this.selectTime ? TM2UTC(this.selectTime[1]) : ''
    },

    // 获取提成信息
    getPercentageList() {
      this.$api
        .getPercentage()
        .then((res) => {
          this.dataScopeArr = res.data.data;
        });
    },

    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.activeName = "2";
      this.formTitle = "审核";
    },

    // 保存
    save() {
      if (this.formTitle == "审核") {
        this.$api
          .examineAnchor({
            id: this.formData.id,
            status: this.formData.status,
            percentageId: this.formData.percentageId,
            reason: this.formData.reason
          })
          .then((ress) => {
            this.$CL();
            this.$sMsg("审核成功");
            this.back();
          });
      }

    },

    // 查询国家代码
    countryCodeList() {
      this.$api
        .regionList()
        .then((res) => {
          this.dataCountry = res.data.data;
        });
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "审核";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
