import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activePage: '',
    pageState: 0,

    maxH: 0,

    l: null, // 加载

    tinymceSettings: {
      // 富文本配置
      menubar: false,
      toolbar:
          "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat ",
      toolbar_drawer: "sliding",
      quickbars_selection_toolbar:
          "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
      plugins: "link image media table lists fullscreen quickbars",
      images_upload_credentials: false,
      // images_upload_url: "#",
      language: "zh_CN", //本地化设置
      height: 350,
    },
  },
  mutations: {
    setActivePage(state) {
      state.activePage = window.localStorage.getItem('activePage')
    },
    setMaxH(state, v) {
      state.maxH = v;
    },
    setPageState(state) {
      state.pageState = 1;
    },
    setL(state, v) {
      state.l = v;
    },
  },
  actions: {},
  modules: {},
});
