<template>
  <div class="card">
    <el-row class="mb-15">
      <el-input v-model="ss.userId" class="w200 mr-15" size="small" placeholder="请输入用户id" clearable></el-input>

      <!--          <el-date-picker-->
      <!--              v-model="ss.startTime"-->
      <!--              class="mr-15"-->
      <!--              type="datetime"-->
      <!--              value-format="yyyy-MM-dd HH:mm:ss"-->
      <!--              placeholder="选择开始日期"-->
      <!--          >-->
      <!--          </el-date-picker>-->

      <!--          <el-date-picker-->
      <!--              v-model="ss.endTime"-->
      <!--              class="mr-15"-->
      <!--              type="datetime"-->
      <!--              value-format="yyyy-MM-dd HH:mm:ss"-->
      <!--              placeholder="选择结束日期"-->
      <!--          >-->
      <!--          </el-date-picker>-->
      <el-button type="primary" size="small" @click="query">搜索</el-button>
    </el-row>
    <div class="table150">
      <el-table :data="tableData" border height="100%" v-loading="loading">
        <el-table-column label="序号" type="index" width="50" align="center" fixed />
        <el-table-column label="用户id" prop="profile" align="center" />
        <el-table-column label="内容" prop="content" align="center" />
        <!--          <el-table-column label="图片地址" prop="urls" align="center"></el-table-column>-->
        <el-table-column label="图片" prop="picUrl" align="center">
          <template slot-scope="scope">
            <viewer v-if="scope.row.picUrl && scope.row.urls.includes('http')" :images="scope.row.picUrl">
              <img v-for="(src, index) in scope.row.picUrl" :src="src" :key="index" width="50">
            </viewer>
            <viewer v-else-if="scope.row.picUrl && !scope.row.urls.includes('http')" :images="scope.row.picUrl">
              <img v-for="(src, index) in scope.row.picUrl" :src="src.includes('http') ? src : $imageUrl + src"
                :key="index" width="50">
            </viewer>
          </template>
        </el-table-column>
        <el-table-column label="视频" prop="playUrl" align="center">
          <!--            <template>-->
          <!--            &lt;!&ndash;在视频外面加一个容器&ndash;&gt;-->
          <!--            <div class="input_video">-->
          <!--              <video-player  class="video-player vjs-custom-skin"-->
          <!--                             ref="videoPlayer"-->
          <!--                             :playsinline="true"-->
          <!--                             :options="playerOptions"-->
          <!--              ></video-player>-->
          <!--            </div>-->
          <!--            </template>-->


          <template slot-scope="scope">
            <div v-if="scope.row.playUrl">
              <vueMiniPlayer ref="vueMiniPlayer" :video="scope.row.videodata" @fullscreen="handleFullscreen" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="图片审核" prop="imageCheck" align="center"></el-table-column>
        <el-table-column label="标签" prop="label" align="center"></el-table-column>
        <el-table-column label="点赞数" prop="likeCount" align="center"></el-table-column>
        <el-table-column label="评论数" prop="commentCount" align="center"></el-table-column>
        <el-table-column label="发布时间(CST/UTC)" prop="createTime" width="170" align="center">
          <template v-if="scope.row.createTime" slot-scope="scope">
            <div>{{ scope.row.createTime | TM2CST }}</div>
            <div>{{ scope.row.createTime | TM2UTC }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" v-if="$checkArr('moment:delete')" @click="remove(scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt-15" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="pageObj.pageIndex" :page-sizes="page.sizes" :page-size.sync="pageObj.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="page.total">
    </el-pagination>
  </div>
</template>

<script>
import Vue from 'vue'
import { formatDate } from '@/components/js/date.js'
import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
Vue.use(vueMiniPlayer)
export default {
  mounted() {
    if (this.$checkArr("moment:pageList")) {
      this.userPageList("初始化");
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",

      // 查询
      ss: {
        userId: ""
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20
      },

      // 表格
      loading: false,
      tableData: [],

      formData: {},
      formTitle: "添加人员",
      picUrl: [],
      video: {
        // 视频播放源
        url: "",
        // 视频海报
        // cover: 'https://i.loli.net/2019/06/06/5cf8c5d9c57b510947.png',
        muted: false, // 是否静音播放
        loop: false, // 视频是否循环播放
        preload: 'auto', // 视频预加载
        poster: '', // 原生视频默认海报暂不设置
        volume: 1, // 默认音量
        autoplay: false, // 视频自动播放
      }
    };
  },
  computed: {
    $video() {
      return this.$refs.vueMiniPlayer.$video;
    }
  },

  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询用户列表--分页
    userPageList(type) {
      this.loading = true
      this.$api.momentList({
        form: {...this.ss},
        ...this.pageObj
      }).then((res) => {
        this.page.total = res.data.data.total;
        this.tableData = res.data.data.list;
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].videodata = {
            url: this.tableData[i].playUrl,
            cover: this.tableData[i].urls,
            muted: false,
            loop: false,
            preload: "auto",
            volume: 1,
            autoplay: false
          }
        }
        if (type == "初始化") {
        }
        this.loading = false
      });
    },
    // 查询
    query() {
      this.pageObj.pageIndex = 1
      this.userPageList()
    },

    // 删除
    remove(v) {
      debugger;
      this.$wTx(
        "该操作将删除该用户动态内容，确认继续吗？",
        () => {
          this.$OL("正在删除中...");
          this.$api
            .deleteMoment({ id: v })
            .then((res) => {
              this.$sMsg("删除成功");
              this.back();
              this.$CL();
            });
        },
        () => {
          this.$iMsg("已取消删除");
        }
      );
    },

    handleFullscreen() {

    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加人员";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
