// import cookies from "vue-cookies";
import Vue from "vue";

// Vue.use(cookies);

export function setSS(key, value) {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    return;
  }
}

export function getSS(key) {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch (error) {
    return;
  }
}

export function setLS(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    return;
  }
}

export function getLS(key) {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return;
  }
}

export function setCK(key, value) {
  try {
    if (typeof key != "string") {
      return Vue.$cookies.set(key, JSON.stringify(value), "0");
    } else {
      return Vue.$cookies.set(key, value, "0");
    }
  } catch (error) {
    return;
  }
}

export function getCK(key) {
  try {
    return Vue.$cookies.get(key);
  } catch (error) {
    return;
  }
}

export function delCK(key) {
  try {
    return Vue.$cookies.remove(key);
  } catch (error) {
    return;
  }
}

export function isCK(key) {
  try {
    return Vue.$cookies.isKey(key);
  } catch (error) {
    return false;
  }
}
