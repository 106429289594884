<template>
  <div class="card">
    <el-tabs v-model="activeName" style="height: 100%;">
      <el-tab-pane label="主播申请列表" name="1" style="height: 100%;">
        <el-row class="mb-15">
          <el-input
            v-model="ss.anchorId"
            class="w200 mr-15"
            size="small"
            placeholder="请输入主播id"
            clearable
          />
          <el-date-picker
            v-model="selectTime"
            type="datetimerange"
            size="small"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          />
          &nbsp;
          <el-button type="primary" size="small" @click="query">搜索</el-button>
        </el-row>
        <div class="table150">
          <el-table :data="tableData" border height="100%" v-loading="loading">
            <el-table-column label="序号" type="index" width="50" align="center" fixed />
            <el-table-column label="昵称" prop="nickName" align="center" />
            <el-table-column label="主播id" prop="anchorId" align="center" />
            <el-table-column label="头像" align="center">
              <template slot-scope="scope">
                        <span>
                  <img v-if="scope.row.avatar==''" src="../../../assets/home/default_head.png"
                        style="height: 50px; width: 50px">
                </span>
                <span v-if="scope.row.avatar">
                  <el-popover placement="top-start" trigger="click"> <!--trigger属性值：hover、click、focus 和 manual-->
                  <a :href="scope.row.avatar" target="_blank" title="查看最大化图片">
                    <img :src="scope.row.avatar.includes('http')?scope.row.avatar:$imageUrl+scope.row.avatar" style="width: 300px;height: 300px">
                  </a>
                  <img slot="reference" :src="scope.row.avatar.includes('http')?scope.row.avatar:$imageUrl+scope.row.avatar" style="width: 50px;height: 50px; cursor:pointer">
                </el-popover>
                </span>
  <!--              <img v-if="scope.row.avatar==''" src="../../../assets/home/default_head.png" style="height: 50px; width: 50px">-->
  <!--              <img v-else :src="$imageUrl+scope.row.avatar" style="height: 50px; width: 50px">-->
              </template>
            </el-table-column>
            <el-table-column label="国家" prop="country" align="center">
              <template slot-scope="scope">
                <div v-for="(item,key,index) in dataScopeArr" :key="index">
                  <span v-if="scope.row.country == item.locale">{{item.sc}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="工会" prop="guildName" align="center"></el-table-column>
            <el-table-column label="审核结果" prop="status" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0">待审核</span>
                <span v-else-if="scope.row.status == 1">已通过</span>
                <span v-else>不通过</span>
              </template>
            </el-table-column>
            <el-table-column label="主播类型" prop="percentageName" align="center"></el-table-column>
            <el-table-column label="拒绝原因" prop="reason" align="center"></el-table-column>
            <el-table-column label="申请时间(CST/UTC)" prop="createTime" width="170" align="center">
              <template v-if="scope.row.createTime" slot-scope="scope">
                <div>{{ scope.row.createTime | TM2CST }}</div>
                <div>{{ scope.row.createTime | TM2UTC }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          class="mt-15"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageObj.pageIndex"
          :page-sizes="page.sizes"
          :page-size.sync="pageObj.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { TM2UTC } from '@/components/js/date.js'
export default {
  mounted() {
    if (this.$checkArr("anchorApply:historyPageList")) {
      this.userPageList("初始化");
      this.countryCodeList();
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      // 查询
      selectTime: null,
      ss: {
        anchorId: '',
        startTime: '',
        endTime: '',
        status: -1
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20
      },

      // 表格
      loading: false,
      tableData: [],

      formData: {},
      formTitle: "添加人员",
      dataScopeArr: []
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询主播审核列表--分页
    userPageList(type) {
      this.loading = true
      this.$api
        .anchorApplyListHistory({
          form: {...this.ss},
          ...this.pageObj
        })
        .then((res) => {
          this.page.total = res.data.data.total;
          this.tableData = res.data.data.list;
          if (type == "初始化") {
          }
          this.loading = false
        });
    },
    // 搜索
    query() {
      this.getTimes()
      this.pageObj.pageIndex = 1
      this.userPageList()
    },
    getTimes() {
      this.ss.startTime = this.selectTime ? TM2UTC(this.selectTime[0]) : ''
      this.ss.endTime = this.selectTime ? TM2UTC(this.selectTime[1]) : ''
    },

    // 查询国家代码
    countryCodeList() {
      this.$api
          .regionList()
          .then((res) => {
            this.dataScopeArr = res.data.data;
            console.log(this.dataScopeArr)
          });
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加人员";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
