import {Loading} from "element-ui";
import store from "../store";

export function OL(text = "正在加载请稍后...") {
    store.commit(
        "setL",
        Loading.service({
            text: text,
            background: "rgba(0, 0, 0, 0.8)",
            customClass: "LoadingClass",
        })
    );
}

export function CL() {
    // 以服务的方式调用的 Loading 需要异步关闭
    if (store.state.l) {
        store.state.l.close();
    }
}
