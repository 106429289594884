var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"homeBox"},[_c('el-container',[_c('el-aside',{ref:"aside",staticClass:"aside",attrs:{"width":"200px"}},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../assets/home/logo.svg"),"alt":""}}),_c('span',{staticClass:"logoText"},[_vm._v("羽 锐 科 技")])]),_c('onSize',{on:{"onSize":function($event){return _vm.changeSize($event)}}}),_c('myMenu',{ref:"menuRef"})],1),_c('el-container',[_c('el-header',{staticClass:"header flex"},[_c('div',{staticClass:"topBox flex jc-sb"},[_c('div',{staticStyle:{"margin-left":"20px"}},[(_vm.breadcrumbFalg)?_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},_vm._l((_vm.$route.meta.data),function(v,k){return _c('el-breadcrumb-item',{key:'面包屑' + k,attrs:{"to":v.to}},[_vm._v(_vm._s(v.text))])}),1):_vm._e()],1),_c('div',{staticClass:"topRight"},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" "+_vm._s(_vm.$getCK("user").name)+" "),_vm._v(" "+_vm._s("(" + (_vm.$getCK("userInfo").dataScope == 0 ? "超管" : _vm.$getCK("userInfo").dataScope == 1 ? "管理" : _vm.$getCK("userInfo").dataScope == 2 ? "代理" : _vm.$getCK("userInfo").dataScope == 3 ? "工会" : _vm.$getCK("userInfo").dataScope == 4 ? "主播" : "") + ")")),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{staticClass:"flex",staticStyle:{"width":"95px"},attrs:{"command":2}},[_vm._v("修改密码")]),_c('el-dropdown-item',{staticClass:"flex",staticStyle:{"width":"95px"},attrs:{"command":3}},[_vm._v("切换角色")])],1)],1),_c('span',{staticClass:"back",on:{"click":function($event){return _vm.back()}}},[_vm._v("退出")])],1)])]),_c('el-main',{staticClass:"main"},[_c('keep-alive',{attrs:{"include":[
            'patrolDaily',
            'patrolOwn',
            'patrolSpecial',
            'myWorkPatrolDaily',
            'myWorkPatrolOwn',
            'myWorkPatrolSpecial',
            'danger',
            'dangerTwo',
            'reportList',
            'reportExamine',
            'programmeLIst' ]}},[_c('router-view')],1),(
            _vm.$route.path == '/patrolSpecialResult' ||
            _vm.$route.path == '/patrolSpecialResultDetails' ||
            _vm.$route.path == '/patrolDailyResult' ||
            _vm.$route.path == '/patrolDailyResultDetails' ||
            _vm.$route.path == '/patrolOwnResultTime' ||
            _vm.$route.path == '/patrolOwnResultLaboratory' ||
            _vm.$route.path == '/patrolOwnResult'
          )?_c('el-button',{staticClass:"mt-15",attrs:{"type":"info"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("返回")]):_vm._e()],1)],1)],1),_c('el-dialog',{attrs:{"title":"修改密码","visible":_vm.pwdDialogVisible,"width":"30%","close-on-click-modal":false},on:{"update:visible":function($event){_vm.pwdDialogVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.pwdFormData,"rules":{
        oldPwd: [
          {
            required: true,
            message: '该项目不能为空',
            trigger: 'blur',
          } ],
        newPwd: [
          {
            required: true,
            message: '该项目不能为空',
            trigger: 'blur',
          } ],
        cofPwd: [
          {
            required: true,
            message: '该项目不能为空',
            trigger: 'blur',
          } ],
      },"label-width":"150px","label-position":"left"}},[_c('el-form-item',{attrs:{"label":"旧密码：","prop":"oldPwd"}},[_c('el-input',{attrs:{"placeholder":""},model:{value:(_vm.pwdFormData.oldPwd),callback:function ($$v) {_vm.$set(_vm.pwdFormData, "oldPwd", $$v)},expression:"pwdFormData.oldPwd"}})],1),_c('el-form-item',{attrs:{"label":"新密码：","prop":"newPwd"}},[_c('el-input',{attrs:{"placeholder":""},model:{value:(_vm.pwdFormData.newPwd),callback:function ($$v) {_vm.$set(_vm.pwdFormData, "newPwd", $$v)},expression:"pwdFormData.newPwd"}})],1),_c('el-form-item',{attrs:{"label":"确认密码：","prop":"cofPwd"}},[_c('el-input',{attrs:{"placeholder":""},model:{value:(_vm.pwdFormData.cofPwd),callback:function ($$v) {_vm.$set(_vm.pwdFormData, "cofPwd", $$v)},expression:"pwdFormData.cofPwd"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.$refs.form
            .validate()
            .then(function () {
              _vm.userModifyPwd();
            })
            .catch(function () {
              _vm.$wMsg('请填写完整信息');
            })}}},[_vm._v("确 定")])],1)],1),_c('el-dialog',{attrs:{"title":"选择角色","visible":_vm.roleDialogVisible,"width":"75%","close-on-click-modal":false},on:{"update:visible":function($event){_vm.roleDialogVisible=$event}}},[_c('el-table',{attrs:{"data":_vm.roleTableData,"border":""}},[_c('el-table-column',{attrs:{"label":"所属组织","prop":"officeName"}}),_c('el-table-column',{attrs:{"label":"角色","prop":"roleName"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.switchOffice(scope.row)}}},[_vm._v("选择")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }