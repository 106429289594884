export const payTypeList = [
  {
    value: '0',
    label: 'Paypal'
  },
  {
    value: '1',
    label: 'GooglePlay'
  },
  {
    value: '2',
    label: 'AppStore'
  },
  {
    value: '3',
    label: 'Gold'
  },

  {
    value: '4',
    label: 'Stripe'
  },
]