<template>
  <div class="card">
    <el-row class="mb-15">
      <el-input v-model="ss.from" class="w200 mr-15" size="small" placeholder="请输入发送方" clearable />
      <el-input v-model="ss.to" class="w200 mr-15" size="small" placeholder="请输入接收方" clearable />
      <el-input v-model="ss.keyWord" class="w200 mr-15" size="small" placeholder="请输入关键词" clearable />
      <el-select v-model="ss.type" size="small" placeholder="请选择消息类型" clearable>
        <el-option label="图片" value="img"></el-option>
        <el-option label="文本" value="txt"></el-option>
        <el-option label="礼物" value="gift"></el-option>
        <el-option label="同意加好友" value="agree"></el-option>
        <el-option label="申请加好友" value="apply"></el-option>
        <el-option label="通话时长" value="line-tick"></el-option>
        <el-option label="取消通话" value="line-cancel"></el-option>
        <el-option label="忙碌" value="line-busy"></el-option>
        <el-option label="拒绝通话" value="line-refuse"></el-option>
      </el-select>
      &nbsp;
      <el-date-picker
        v-model="selectTime"
        type="datetimerange"
        size="small"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="timestamp"
      />
      &nbsp;
      <!-- <el-date-picker v-model="ss.startTime" class="mr-15" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
        default-time="00:00:00" size="small" placeholder="选择开始日期">
      </el-date-picker>

      <el-date-picker v-model="ss.endTime" class="mr-15" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
        default-time="00:00:00" size="small" placeholder="选择结束日期">
      </el-date-picker> -->
      <el-button type="primary" size="small" @click="query">搜索</el-button>
    </el-row>
    <div class="table150">
      <el-table :data="tableData" border height="100%" v-loading="loading">
        <el-table-column label="序号" type="index" width="50" align="center" fixed />
        <el-table-column label="发送方" prop="from" align="center"></el-table-column>
        <el-table-column label="接收方" prop="to" align="center"></el-table-column>
        <el-table-column label="类型" prop="type" align="center">
          <!--            <template slot-scope="scope">-->
          <!--              <span v-if="scope.row.type == 'txt'">文本</span>-->
          <!--              <span v-if="scope.row.type == 'img'">图片</span>-->
          <!--            </template>-->
        </el-table-column>
        <el-table-column label="内容" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 'img'">
              <el-popover placement="top-start" trigger="click">
                <!--trigger属性值：hover、click、focus 和 manual-->
                <a :href="scope.row.content" target="_blank" title="查看最大化图片">
                  <img :src="scope.row.content.includes('http') ? scope.row.content : $imageUrl + scope.row.content"
                    style="width: 300px;height: 300px">
                </a>
                <img slot="reference"
                  :src="scope.row.content.includes('http') ? scope.row.content : $imageUrl + scope.row.content"
                  style="width: 50px;height: 50px; cursor:pointer">
              </el-popover>
              <!--                <img :src="imgServer+scope.row.content" style="height: 50px; width: 50px">-->
            </span>
            <span v-if="scope.row.type == 'txt'">{{ scope.row.content }}</span>
          </template>
        </el-table-column>
        <el-table-column label="图片审核" prop="imageCheck" align="center"></el-table-column>
        <el-table-column label="翻译" prop="trans" align="center"></el-table-column>
        <el-table-column label="状态" prop="read" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.read == 1">已读</span>
            <span v-else>未读</span>
          </template>
        </el-table-column>
        <el-table-column label="发送时间(CST/UTC)" prop="time" width="170" align="center">
          <template v-if="scope.row.time" slot-scope="scope">
            <div>{{ scope.row.time | TM2CST }}</div>
            <div>{{ scope.row.time | TM2UTC }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-if="$checkArr('talkRecord:delete')">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" v-if="$checkArr('talkRecord:delete')" @click="remove(scope.row.msgId)">
              删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt-15" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="pageObj.pageIndex" :page-sizes="page.sizes" :page-size.sync="pageObj.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="page.total" />
  </div>
</template>

<script>
import { TM2UTC } from '../../js/date';
export default {
  mounted() {
    if (this.$checkArr("talkRecord:pageList")) {
      this.userPageList("初始化");
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      // 查询
      selectTime: null,
      ss: {
        from: '',
        to: '',
        keyWord: '',
        type: '',
        startTime: '',
        endTime: ''
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20
      },
      // 表格
      loading: false,
      tableData: [],

      formData: {},
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询聊天列表--分页
    userPageList(type) {
      this.loading = true
      this.$api.talkRecord({
        form: {...this.ss},
        ...this.pageObj
      }).then((res) => {
        this.page.total = res.data.data.total;
        this.tableData = res.data.data.list;
        if (type == "初始化") {
        }
        this.loading = false
      })
    },
    // 查询
    query() {
      this.getTimes()
      this.pageObj.pageIndex = 1
      this.userPageList()
    },
    getTimes() {
      this.ss.startTime = this.selectTime ? TM2UTC(this.selectTime[0]) : ''
      this.ss.endTime = this.selectTime ? TM2UTC(this.selectTime[1]) : ''
    },


    // 删除
    remove(v) {
      this.$wTx(
        "该操作将删除该用户聊天消息，确认继续吗？",
        () => {
          this.$OL("正在删除中...");
          this.$api
            .deleteTalkRecord({ id: v })
            .then((res) => {
              this.$sMsg("删除成功");
              this.back();
              this.$CL();
            });
        },
        () => {
          this.$iMsg("已取消删除");
        }
      );
    },


    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加人员";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加人员";
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
