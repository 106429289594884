<template>
  <div class="card">
    <el-tabs v-model="activeName" @tab-click="tabChenge" style="height: 100%">
      <el-tab-pane label="主播列表" name="1" style="height: 100%">
        <el-row class="mb-15">
          <el-input v-model="ss.anchorId" class="w200 mr-15" size="small" placeholder="请输入主播ID" clearable></el-input>
          <el-select v-model="ss.guildId" size="small" placeholder="请选择公会" filterable style="width: 200px" clearable>
            <el-option v-for="v in dataGuild" :key="v.id" :label="v.name" :value="v.id" />
          </el-select>
          &nbsp;
          <el-date-picker
            v-model="selectTime"
            type="datetimerange"
            size="small"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          />
          &nbsp;
          <el-button type="primary" size="small" @click="query">搜索</el-button>
        </el-row>
        <div class="table150">
          <el-table :data="tableData" border height="100%" v-loading="loading">
            <el-table-column label="序号" type="index" align="center" width="50" fixed />
            <el-table-column label="主播ID" prop="anchorId" align="center" />
            <el-table-column label="主播昵称" prop="name" align="center" />
            <el-table-column label="头像" align="center">
              <template slot-scope="scope">
                <img v-if="scope.row.avatar == ''" src="../../../assets/home/default_head.png"
                  style="height: 50px; width: 50px">
                <img v-else :src="scope.row.avatar.includes('http') ? scope.row.avatar : $imageUrl + scope.row.avatar"
                  style="height: 50px; width: 50px">
              </template>
            </el-table-column>
            <!--          <el-table-column label="国家" prop="country" align="center"></el-table-column>-->
            <el-table-column label="国家" prop="country" align="center">
              <template slot-scope="scope">
                <div v-for="(item, key, index) in dataCountry" :key="index">
                  <span v-if="scope.row.country == item.locale">{{ item.sc }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="所属公会" prop="guildId" align="center">
              <template slot-scope="scope">
                <div v-for="(item, key, index) in dataGuild" :key="index">
                  <span v-if="scope.row.guildId == item.id">{{ item.name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="主播类型" prop="percentageId" align="center">
              <template slot-scope="scope">
                <div v-for="(item, key, index) in dataScopeArr" :key="index">
                  <span v-if="scope.row.percentageId == item.id">{{ item.name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="在线状态" prop="net" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.net == 1" class="el-icon-success" style="color: #7AE552FF; font-size: 25px" />
                <span v-else-if="scope.row.net == 2" class="el-icon-remove" style="color: #E6A23C; font-size: 25px" />
                <span v-else class="el-icon-error" style="font-size: 25px" />
              </template>
            </el-table-column>
            <el-table-column label="视频通话时长" prop="callTime" align="center"></el-table-column>
            <el-table-column label="秒数" prop="seconds" align="center"></el-table-column>
            <el-table-column label="接通率" prop="callPassRate" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.callPassRate">
                  {{ scope.row.callPassRate + '%' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="好评率" prop="favorableRate" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.favorableRate">
                  {{ scope.row.favorableRate + '%' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="星级" prop="starLevel" align="center"></el-table-column>
            <el-table-column label="魅力值" prop="virtualScore" align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="530" fixed="right" v-if="$checkArr('bi:anchor:oper')">
              <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="relieve(scope.row)" v-if="$checkArr('bi:anchor:oper')">解约
                </el-button>
                <el-button type="success" size="mini" @click="bindTrainDig(scope.row)"
                  v-if="$checkArr('bi:anchor:oper')">绑定培训
                </el-button>
                <el-button type="danger" size="mini" @click="unbindTrainDig(scope.row)"
                  v-if="$checkArr('bi:anchor:oper')">解绑培训
                </el-button>
                <el-button type="danger" size="mini" @click="rePwd(scope.row)" v-if="$checkArr('bi:anchor:oper')">重置密码
                </el-button>
                <el-button type="primary" size="mini" @click="handlePercentage(scope.row)"
                  v-if="$checkArr('bi:anchor:oper')">修改
                </el-button>
                <el-button type="primary" size="mini" @click="handleStarLevel(scope.row)"
                  v-if="$checkArr('bi:anchor:oper')">设置等级
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination class="mt-15" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="pageObj.pageIndex" :page-sizes="page.sizes" :page-size.sync="pageObj.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="page.total">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>

    <el-dialog title="解绑培训师" :visible.sync="unbindTrainDigVisible">
      <el-form :model="form">
        <el-form-item label="培训师账号" :label-width="formLabelWidth">
          <el-input v-model="trainAnchorForm.trainLoginName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="unbindTrainDigVisible = false">取 消</el-button>
        <el-button type="primary" @click="unbindTrain()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="绑定培训师" :visible.sync="bindTrainDigVisible">
      <el-form :model="form">
        <el-form-item label="培训师账号" :label-width="formLabelWidth">
          <el-input v-model="trainAnchorForm.trainLoginName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="bindTrainDigVisible = false">取 消</el-button>
        <el-button type="primary" @click="bindTrain()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form label-width="90px">
        <el-form-item label="主播" prop="anchorId">
          <el-input v-model="trainAnchorForm.anchorId" placeholder="" style="width: 300px" disabled></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="percentageId">
          <el-select v-model="trainAnchorForm.percentageId" placeholder="" filterable style="width: 300px">
            <el-option v-for="(v, k) in dataScopeArr" :label="v.name" :value="v.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公会" prop="guildId">
          <el-select v-model="trainAnchorForm.guildId" placeholder="" filterable style="width: 300px">
            <el-option v-for="(v, k) in dataGuild" :label="v.name" :value="v.id" :key="'公会' + k"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="魅力值增减" prop="virtualScoreChange">
          <el-input-number v-model="virtualScoreChange"></el-input-number>
          <!-- <el-input v-model="trainAnchorForm.virtualScoreChange" placeholder="" style="width: 300px"></el-input> -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatePercentage()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改" :visible.sync="starVisible" width="30%" :before-close="handleClose">
      <el-form>
        <el-form-item label="主播" prop="anchorId">
          <el-input v-model="trainAnchorForm.anchorId" placeholder="" style="width: 300px" disabled></el-input>
        </el-form-item>
        <el-form-item label="星级" prop="starLevel">
          <el-select v-model="trainAnchorForm.starLevel" placeholder="" filterable style="width: 300px">
            <el-option label="1" :value="1"></el-option>
            <el-option label="2" :value="2"></el-option>
            <el-option label="3" :value="3"></el-option>
            <el-option label="4" :value="4"></el-option>
            <el-option label="5" :value="5"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="starVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateStarLevel()">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import { TM2UTC } from '@/components/js/date.js'
export default {
  mounted() {
    if (this.$checkArr("bi:anchor:view")) {
      this.anchorPageList("初始化");
      this.getPercentageList();
      this.getGuildList();
      this.countryCodeList();
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      virtualScoreChange: 0,
      starVisible: false,
      dialogVisible: false,
      activeName: "1",

      isDisabled: false,

      // 查询
      selectTime: null,
      ss: {
        anchorId: "",
        guildId: "",
        startTime: '',
        endTime: ''
      },
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20,
      },
      // 表格
      loading: false,
      tableData: [],

      trainAnchorForm: {},
      bindTrainDigVisible: false,
      unbindTrainDigVisible: false,
      dataScopeArr: [],
      dataGuild: [],
      dataCountry: [],
    };
  },
  methods: {
    // 查询用户列表--分页
    anchorPageList(type) {
      this.loading = true
      this.$api
        .findAnchorPage({
          form: { ...this.ss },
          ...this.pageObj
        })
        .then((res) => {
          this.activeName = "1";
          this.page.total = res.data.data.total;
          this.tableData = res.data.data.list;
          if (type == "初始化") {
          }
          this.loading = false
        });
    },
    // 搜索
    query() {
      this.getTimes()
      this.pageObj.pageIndex = 1
      this.anchorPageList()
    },
    getTimes() {
      this.ss.startTime = this.selectTime ? TM2UTC(this.selectTime[0]) : ''
      this.ss.endTime = this.selectTime ? TM2UTC(this.selectTime[1]) : ''
    },

    // 获取主播类型列表
    getPercentageList() {
      this.$api
        .getPercentageList()
        .then((res) => {
          this.dataScopeArr = res.data.data;
        });
    },

    // 获取工会列表
    getGuildList() {
      this.$api
        .getGuildList()
        .then((res) => {
          this.dataGuild = res.data.data;
        });
    },

    // 查询国家代码
    countryCodeList() {
      this.$api
        .regionList()
        .then((res) => {
          this.dataCountry = res.data.data;
        });
    },

    // 删除
    remove(v, falg) {
      this.$wTx(
        "该操作将" + (falg ? "永久" : "") + "删除当前代理条目，确认继续吗？",
        () => {
          this.$OL("正在删除...");
          this.$api[falg ? "anchorRelDel" : "anchorDel"]({ id: v.id }).then((res) => {
            this.back();
            this.$CL();
            this.$sMsg("删除成功");
          });
        },
        () => {
          this.$iMsg("已取消删除");
        }
      );
    },

    // 重置密码
    rePwd(v) {
      this.$wTx(
        "该操作将重置用户：" + v.name + "的登录密码，确认继续吗？",
        () => {
          this.$OL("正在重置密码...");
          this.$api.anchorResetPassword({ id: v.id }).then((res) => {
            this.$CL();
            this.$sMsg("密码重置成功");
            this.anchorPageList();
          });
        },
        () => {
          this.$iMsg("已取消重置密码操作");
        }
      );
    },


    //绑定主播弹出框
    bindTrainDig(v) {
      this.trainAnchorForm.anchorId = v.id;
      this.bindTrainDigVisible = true;
    },


    //解绑主播弹出框
    unbindTrainDig(v) {
      this.trainAnchorForm.anchorId = v.id;
      this.unbindTrainDigVisible = true;
    },


    //绑定主播弹出框
    bindTrain() {
      this.bindTrainDigVisible = false;
      this.$api.bindTrainWithLoginName({
        anchorId: this.trainAnchorForm.anchorId,
        trainLoginName: this.trainAnchorForm.trainLoginName
      }).then((res) => {
        this.$CL();
        this.$sMsg("绑定成功");
      });
    },

    //解绑主播弹出框
    unbindTrain() {
      this.unbindTrainDigVisible = false;
      this.$api.unbindTrainWithLoginName({
        anchorId: this.trainAnchorForm.anchorId,
        trainLoginName: this.trainAnchorForm.trainLoginName
      }).then((res) => {
        this.$CL();
        this.$sMsg("解绑成功");
      });
    },

    // 解约
    relieve(v) {
      this.$wTx(
        "该操作将解约用户：" + v.name + "，确认继续吗？",
        () => {
          this.$OL("正在解约用户...");
          this.$api.anchorRelieve({ id: v.id }).then((res) => {
            this.$CL();
            this.$sMsg("解约用户成功");
            this.anchorPageList();
          });
        },
        () => {
          this.$iMsg("已取消重置密码操作");
        }
      );
    },


    // 修改类型弹框
    handlePercentage(v) {
      this.dialogVisible = true;
      let obj = JSON.parse(JSON.stringify(v));
      this.trainAnchorForm = obj;
      // this.trainAnchorForm.virtualScoreChange = 0
    },

    // 修改类型
    updatePercentage(v) {
      this.$api.anchorModifyType(
        {
          anchorId: this.trainAnchorForm.id,
          percentageId: this.trainAnchorForm.percentageId,
          guildId: this.trainAnchorForm.guildId,
          virtualScoreChange: this.virtualScoreChange
        }
      ).then((res) => {
        this.$CL();
        this.$sMsg("修改类型成功");
        this.dialogVisible = false;
        this.anchorPageList();
      });
    },


    // 设置星级弹框
    handleStarLevel(v) {
      this.starVisible = true;
      let obj = JSON.parse(JSON.stringify(v));
      this.trainAnchorForm = obj;
    },

    // 设置星级
    updateStarLevel(v) {
      this.$api.updateStarLevel(
        {
          anchorId: this.trainAnchorForm.anchorId,
          starLevel: this.trainAnchorForm.starLevel
        }
      ).then((res) => {
        this.$CL();
        this.$sMsg("修改星级成功");
        this.starVisible = false;
        this.anchorPageList();
      });
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.anchorPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.anchorPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.anchorPageList();
    },

    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {
        });
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
