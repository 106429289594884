<template>
  <div class="card">
    <el-tabs v-model="activeName" style="height: 100%;">
      <el-tab-pane label="提成设置列表" name="1" style="height: 100%;">
        <div class="table110">
          <el-table :data="tableData" border height="100%" v-loading="loading">
            <el-table-column label="序号" type="index" width="50" align="center" fixed />
            <el-table-column label="类型" prop="name" align="center" />
            <el-table-column label="充值" prop="recharge" align="center" />
            <el-table-column label="视频聊天" prop="videoCall" align="center" />
            <el-table-column label="vip" prop="vip" align="center" />
            <el-table-column label="礼物" prop="gift" align="center" />
            <el-table-column label="翻译" prop="translate" align="center" />
            <el-table-column label="创建时间(CST/UTC)" prop="createTime" align="center" width="170">
              <template v-if="scope.row.createTime" slot-scope="scope">
                <div>{{ scope.row.createTime | TM2CST }}</div>
                <div>{{ scope.row.createTime | TM2UTC }}</div>
              </template>
            </el-table-column>
            <el-table-column label="创建人" prop="createBy" align="center" />
            <el-table-column label="修改时间(CST/UTC)" prop="updateTime" align="center" width="170">
              <template v-if="scope.row.updateTime" slot-scope="scope">
                <div>{{ scope.row.updateTime | TM2CST }}</div>
                <div>{{ scope.row.updateTime | TM2UTC }}</div>
              </template>
            </el-table-column>
            <el-table-column label="修改人" prop="updateBy" align="center" />
            <el-table-column
              label="操作"
              width="160px"
              align="center"
              v-if="$checkArr('percentage:update')"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="small"
                  @click="modify(scope.row)"
                  v-if="$checkArr('percentage:update')"
                  >修改</el-button
                >

                <el-button
                    type="danger"
                    size="small"
                    v-if="$checkArr('percentage:update')"
                    @click="remove(scope.row.id)"
                >删除
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          class="mt-15"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageObj.pageIndex"
          :page-sizes="page.sizes"
          :page-size.sync="pageObj.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </el-tab-pane>

      <el-tab-pane :label="formTitle" name="2" v-if="$checkArr('percentage:update')">
        <el-row>
          <el-col :span="12">
            <el-form
                ref="form"
                :model="formData"
                :rules="{
                name: [
                  {
                    required: true,
                    message: '该项目不能为空',
                    trigger: 'blur',
                  }
                ]
              }"
                label-width="150px"
                label-position="right"
            >
              <el-form-item label="类型" prop="name">
                <el-input v-model="formData.name" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="充值" prop="recharge">
                <el-input v-model="formData.recharge" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="视频聊天" prop="videoCall">
                <el-input v-model="formData.videoCall" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="vip" prop="vip">
                <el-input v-model="formData.vip" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="礼物" prop="gift">
                <el-input v-model="formData.gift" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="翻译" prop="translate">
                <el-input v-model="formData.translate" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-button
                    type="success"
                    @click="
                    $refs.form
                      .validate()
                      .then(() => {
                        save();
                      })
                      .catch(() => {
                        $wMsg('请填写完整信息');
                      })
                  "
                >保存
                </el-button
                >
                <el-button type="info" @click="back()">返回</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.$checkArr("percentage:pageList")) {
      this.userPageList("初始化");
    } else {
      this.$wMsg("无权限");
    }
  },
  data() {
    return {
      activeName: "1",
      
      page: {
        sizes: [10, 20, 30, 40],
        total: 0,
      },
      pageObj: {
        pageIndex: 1,
        pageSize: 20
      },

      // 表格
      loading: false,
      tableData: [],

      formData: {},
      formTitle: "添加",
    };
  },
  methods: {
    // 重置formData
    reData() {
      this.formData = {};
    },

    // 查询用户列表--分页
    userPageList(type) {
      this.loading = true
      this.$api.percentageList({
        ...this.pageObj
      }).then((res) => {
        this.page.total = res.data.data.total;
        this.tableData = res.data.data.list;
        if (type == "初始化") {
        }
        this.loading = false
      });
    },

    // 修改
    modify(v) {
      let obj = JSON.parse(JSON.stringify(v));
      this.formData = obj;
      this.activeName = "2";
      this.formTitle = "修改";
    },


    // 保存
    save() {
      // this.$OL("正在保存...");
      this.$api
        .userGetUserByUserName({
          userName: this.formData.loginName,
        })
        .then((res) => {
          if (res.data.data == null) {
            this.$api
              .addPercentage({
                id: this.formTitle == "添加" ? "" : this.formData.id,
                name:  this.formData.name,
                recharge: this.formData.recharge,
                videoCall: this.formData.videoCall,
                vip: this.formData.vip,
                gift: this.formData.gift,
                translate: this.formData.translate,
              })
              .then((ress) => {
                this.$CL();
                this.$sMsg("修改成功");
                this.back();
              });
          } else {
            this.$eMsg("该学/工号已存在！");
          }
        });
    },


    // 删除
    remove(v) {
      this.$wTx(
          "该操作将删除该设置，确认继续吗？",
          () => {
            this.$OL("正在删除中...");
            this.$api
                .deletePercentage({id: v})
                .then((res) => {
                  debugger;
                  this.$sMsg("删除成功");
                  this.back();
                  this.$CL();
                });
          },
          () => {
            this.$iMsg("已取消删除");
          }
      );
    },

    // 返回
    back() {
      this.reData();
      this.activeName = "1";
      this.formTitle = "添加";
      this.userPageList();
    },

    // 切换页码
    handleCurrentChange(index) {
      this.userPageList();
    },

    // 切换每页条目数
    handleSizeChange(size) {
      this.userPageList();
    },
  },
  watch: {
    activeName() {
      if (this.activeName == "1") {
        this.reData();
        this.formTitle = "添加";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .table110 {
    height: calc(100% - 110px);
    img {
      vertical-align: middle;
    }
  }
</style>
