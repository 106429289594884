<template>
  <div v-if="showFalg">
    <el-menu class="el-menu-vertical-demo el-menu-title" text-color="#ffffff" background-color="#050350"
      active-text-color="#ffffff" router unique-opened :default-active="activePage" @select="select">
      <template v-for="v in menuData">
        <template v-if="v.children">
          <el-submenu :key="v.id" :index="v.id">
            <template slot="title">
              <i :class="v.icon"></i>
              <el-badge type="success" :value="x1 + x2 + x3" class="badge"
                :hidden="v.id != '58adc6a15ca54d399be1620eb789ef97' || x1 + x2 + x3 == 0">
                <span>{{ v.name }}</span>
              </el-badge>
            </template>

            <template v-for="rv in v.children">
              <el-submenu v-if="rv.children" :key="rv.id" :index="rv.id">
                <template slot="title">
                  <i :class="rv.icon"></i>
                  <span>{{ rv.name }}</span>
                </template>

                <template>
                  <el-menu-item v-for="kv in rv.children" :key="kv.id" :index="kv.href">
                    <i :class="kv.icon"></i>
                    <span>{{ kv.name }}</span>
                  </el-menu-item>
                </template>
              </el-submenu>

              <el-menu-item v-else :index="rv.href">
                <i :class="rv.icon"></i>
                <el-badge :value="
                  hiddenJudge(rv).v == 1
                    ? x1
                    : hiddenJudge(rv).v == 2
                      ? x2
                      : hiddenJudge(rv).v == 3
                        ? x3
                        : 0
                " class="badge" :hidden="
                    hiddenJudge(rv).f ||
                    (hiddenJudge(rv).v == 1
                      ? x1 == 0
                      : hiddenJudge(rv).v == 2
                        ? x2 == 0
                        : hiddenJudge(rv).v == 3
                          ? x3 == 0
                          : true)
                  ">
                  <span>{{ rv.name }}</span>
                </el-badge>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>

        <template v-else>
          <el-menu-item :key="v.id" :index="v.href">
            <i :class="v.icon"></i>
            <el-badge type="success" :value="
              hiddenJudge(v).v == 1
                ? x1
                : hiddenJudge(v).v == 2
                  ? x2
                  : hiddenJudge(v).v == 3
                    ? x3
                    : 0
            " class="badge" :hidden="
  hiddenJudge(v).f ||
  (hiddenJudge(v).v == 1
    ? x1 == 0
    : hiddenJudge(v).v == 2
      ? x2 == 0
      : hiddenJudge(v).v == 3
        ? x3 == 0
        : true)
">
              <span>{{ v.name }}</span>
            </el-badge>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "myMenu",
  mounted() {
    // 获取菜单
    this.getMenuTreeOfCurrentUser();

    this.$bus.$off("更新数值提示").$on("更新数值提示", () => {
    });
    // 获取数值提示

    // 事件
    this.$bus.$off("刷新菜单").$on("刷新菜单", () => {
      this.refresh();
    });

    // 记录进入页面 方便在刷新页面上时重置菜单
    this.$route.path == "/home" ? (this.$store.state.pageState == 0 ? this.$store.commit("setPageState") : "") : "";
  },
  data() {
    return {
      showFalg: true, // 刷新菜单用
      menuData: [],
      x1: 0, //日常巡查
      x2: 0, //专项检查
      x3: 0, //日常自查
    };
  },
  created() {
    this.$store.commit('setActivePage')
  },
  computed: {
    activePage() {
      return this.$store.state.activePage
    }
  },
  methods: {
    select(val) {
      window.localStorage.setItem('activePage', val)
      this.$store.commit('setActivePage')
    },
    // 刷新菜单
    refresh() {
      this.getMenuTreeOfCurrentUser();
      this.showFalg = false;
      this.$nextTick(() => {
        this.showFalg = true;
      });
    },

    // 获取菜单
    getMenuTreeOfCurrentUser() {
      this.$api.getMenuTreeOfCurrentUser().then((res) => {
        this.menuData = res.data.data[0].children;
        this.$forceUpdate();
      });
    },

    // 判断当前菜单需要显示数值提示
    hiddenJudge(v) {
      if (
        v.id == "2ec9dd02fda5454784fddc4d222ae1b1" ||
        v.id == "2cc7e1645cae44efab74474b20e8f678" ||
        v.id == "b241aae423364345ba3e9b18148c4d88" ||
        v.id == "9d747c1745b3479eb2c7307f9054fdb5" ||
        v.id == "14d458da52d4488c91f8ea8e765cca41" ||
        v.id == "6aeb22f6f8534142aa91c9d335d7215d"
      ) {
        switch (v.id) {
          case "2ec9dd02fda5454784fddc4d222ae1b1":
            return {
              v: 1,
              f: false,
            };
            break;
          case "2cc7e1645cae44efab74474b20e8f678":
            return {
              v: 2,
              f: false,
            };
            break;
          case "b241aae423364345ba3e9b18148c4d88":
            return {
              v: 3,
              f: false,
            };
            break;

          case "9d747c1745b3479eb2c7307f9054fdb5":
            return {
              v: 1,
              f: false,
            };
            break;
          case "14d458da52d4488c91f8ea8e765cca41":
            return {
              v: 2,
              f: false,
            };
            break;
          case "6aeb22f6f8534142aa91c9d335d7215d":
            return {
              v: 3,
              f: false,
            };
            break;
        }
      } else {
        return {
          v: "",
          f: true,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-menu-vertical-demo {
  i {
    color: #ffffff !important;
  }

  .el-menu .el-menu-item {
    background-color: #050350 !important;
  }

  .el-menu-item:focus,
  .el-menu-item:hover,
  .el-submenu__title:hover,
  .el-submenu__title:focus {
    background-color: #2b2973 !important;
  }

  /*  .el-submenu__title:hover,
  .el-submenu__title:focus {
    background-color: #0d0b62 !important;
  }*/
  .el-menu-item.is-active {
    background-color: #0053ff !important;
    //background-color: #3b8bf3 !important;
  }
}

::v-deep .el-badge__content.is-fixed {
  top: 29px;
  right: -90px;
}
</style>

<style lang="scss" scoped>
//使用图片来替换
//before属性中的content文本是用来占位的,必须有
//可以设置字体大小来确定大小
//使用visibility: hidden;来隐藏文字
.el-icon-my-1 {
  background: url("../../assets/myMenu/1.png") center center/auto 100% no-repeat;
}

//如果直接使用字体图片
//直接在before属性设置对应的content就行
.el-icon-my-1:before {
  content: "\e6ca";
  font-size: 18px;
  visibility: hidden;
}

@mixin com {
  &:before {
    content: "\e6ca";
    font-size: 18px;
    visibility: hidden;
  }
}

.el-icon-my-2 {
  background: url("../../assets/myMenu/2.png") center center/auto 100% no-repeat;
  @include com;
}

.el-icon-my-3 {
  background: url("../../assets/myMenu/3.png") center center/auto 100% no-repeat;
  @include com;
}

.el-icon-my-4 {
  background: url("../../assets/myMenu/4.png") center center/auto 100% no-repeat;
  @include com;
}

.el-icon-my-5 {
  background: url("../../assets/myMenu/5.png") center center/auto 100% no-repeat;
  @include com;
}

.el-icon-my-6 {
  background: url("../../assets/myMenu/6.png") center center/auto 100% no-repeat;
  @include com;
}

.el-icon-my-7 {
  background: url("../../assets/myMenu/7.png") center center/auto 100% no-repeat;
  @include com;
}

.el-icon-my-8 {
  background: url("../../assets/myMenu/8.png") center center/auto 100% no-repeat;
  @include com;
}

.el-icon-my-9 {
  background: url("../../assets/myMenu/9.png") center center/auto 100% no-repeat;
  @include com;
}
</style>
