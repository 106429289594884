import {filesRequest, getRequest, postBody, postNotoken, postPath, postRequest,} from "../Tools/request/httpRequest";

// 登录接口集合
const login = {
  // 登录 /manage/login
  login: (params) => postNotoken("/sys/login", params),

  // 获取当前用户角色的层级 /sys/getLevelOfCurrentRole
  loginGetLevelOfCurrentRole: (params) =>
      getRequest("/sys/getLevelOfCurrentRole", params),

  // 退出登录 /sys/logout
  logout: (params) => getRequest("/sys/logout", params),

  // 获取当前用户树状菜单--菜单导航 /manage/getMenuTreeOfCurrentUser
  getMenuTreeOfCurrentUser: (params) =>
      getRequest("/sys/getMenuTreeOfCurrentUser", params),

  // 获取用户权限集合 /sys/getPermissions
  getPermissions: (params) =>
      getRequest("/sys/getPermissions", params),

  // 切换组织--获取当前用户机构列表(角色列表)  /sys/menu
  menu: (params) => getRequest("/sys/menu", params),

  // 切换组织--更新token(切换角色) /sys/switchOffice
  switchOffice: (params) => getRequest("/sys/switchOffice", params),
};

// 字典接口集合
const dict = {
  // 查询字典列表——分页 /sys/dict/pageList
  dictPageList: (params) => postBody("/sys/dict/pageList", params),

  // 删除字典记录--逻辑--单条 /sys/dict/del/{id}
  dictDel: (params) => postPath("/sys/dict/del", params),

  // 删除字典记录--物理--单条 /sys/dict/relDel/{id}
  dictRelDel: (params) => postPath("/sys/dict/relDel", params),

  // 修改字典记录 /sys/dict/update
  dictUpdate: (params) => postRequest("/sys/dict/update", params),

  // 获取菜单导航 /sys/dict/getMenuTree
  getOfficeTree: (params) =>
      postRequest("/sys/dict/getMenuTree", params),

  // 查询字典列表 /sys/dict/list
  dictList: (params) => postRequest("/sys/dict/list", params),
};

const menu = {
  // 获取所有菜单--树形结构 /sys/menu/getMenuTree
  menuGetMenuTree: (params) =>
      postRequest("/sys/menu/getMenuTree", params),

  // 获取子菜单 /sys/menu/getChildList
  menuGetChildList: (params) =>
      postRequest("/sys/menu/getChildList", params),

  // 逻辑删除菜单 /sys/menu/del/{id}
  menuDel: (params) => postPath("/sys/menu/del", params),

  // 物理删除菜单 /sys/menu/relDel/{id}
  menuRelDel: (params) => postPath("/sys/menu/relDel", params),

  // 修改菜单 /sys/menu/update
  menuUpDate: (params) => postRequest("/sys/menu/update", params),
};

const role = {
  // 删除角色记录--逻辑--单条 /sys/role/del/{id}
  roleDel: (params) => postPath("/sys/role/del", params),

  // 查询角色记录--详情 /sys/role/get/{id}
  roleGet: (params) => postPath("/sys/role/get", params),

  // 查询角色关联的菜单集合 /sys/role/getMenuIds/{roleId}
  roleGetMenuIds: (params) =>
      postPath("/sys/role/getMenuIds", params),

  // 查询角色列表 /sys/role/list
  roleList: (params) => postRequest("/sys/role/list", params),

  // 查询角色列表--分页 /sys/role/pageList
  roleGageList: (params) => postBody("/sys/role/pageList", params),

  // 删除角色记录--物理--单条 /sys/role/relDel/{id}
  roleRelDel: (params) => postPath("/sys/role/relDel", params),

  // 修改角色记录 /sys/role/update
  roleUpDate: (params) => postBody("/sys/role/update", params),
};

const office = {
  // 获取所有机构--树形结构 /sys/office/getTree
  officeGetTree: (params) =>
      postRequest("/sys/office/getTree", params),

  // 获取用户当前角色对应机构的所有子机构--树形结构 /sys/office/getTreeOfCurrentUser
  officeGetTreeOfCurrentUser: (params) =>
      postRequest("/sys/office/getTreeOfCurrentUser", params),

  // 获取子机构 /sys/office/getChildList
  officeGetChildList: (params) =>
      postRequest("/sys/office/getChildList", params),

  // 逻辑删除机构 /sys/office/del/{id}
  officeDel: (params) => postPath("/sys/office/del", params),

  // 物理删除机构  /sys/office/relDel/{id}
  officeRelDel: (params) => postPath("/sys/office/relDel", params),

  // 修改机构 /sys/office/update
  officeUpdate: (params) =>
      postRequest("/sys/office/update", params),
};

const user = {
  // 查询用户列表--分页 /sys/user/pageList
  userPageList: (params) => postBody("/sys/user/pageList", params),

  // 查询用户列表 /sys/user/list
  userList: (params) => postBody("/sys/user/list", params),

  // 删除用户记录--逻辑--单条 /sys/user/del/{id}
  userDel: (params) => postPath("/sys/user/del", params),

  // 删除用户记录--物理--单条 /sys/user/relDel/{id}
  userRelDel: (params) => postPath("/sys/user/relDel", params),

  // 重置密码 /sys/user/resetPassword/{id}
  userResetPassword: (params) =>
      postPath("/sys/user/resetPassword", params),

  // 修改密码 /laboratory/sys/user/modifyPwd
  userModifyPwd: (params) =>
      postRequest("/sys/user/modifyPwd", params),

  // 修改用户记录 /sys/user/update
  userUpdate: (params) => postBody("/sys/user/update", params),

  // 查询用户信息--详情 /sys/user/get/{id}
  userGet: (params) => postPath("/sys/user/get", params),

  // 根据学工号查询用户是否存在 /sys/user/getUserByUserName
  userGetUserByUserName: (params) =>
      getRequest("/sys/user/getUserByUserName", params),
};

const officeUser = {
  // 查询机构用户列表--分页 /sys/officeUser/pageListOfOffice
  officeUserPageListOfOffice: (params) =>
      postBody("/sys/officeUser/pageListOfOffice", params),

  // 删除机构用户 /sys/officeUser/del
  officeUserDel: (params) =>
      postRequest("/sys/officeUser/del", params),

  // 机构用户修改 /sys/officeUser/update
  officeUserUpdate: (params) =>
      postBody("/sys/officeUser/update", params),

  // 用户管理--添加用户--角色选项列表（就是获取角色列表） /sys/officeUser/getRolesOfOfficeLevel/{officeId}
  officeUserGetRolesOfOfficeLevel: (params) =>
      postPath("/sys/officeUser/getRolesOfOfficeLevel", params),

  // 账户管理-分配角色，根据登录账号获取该层级下的角色列表
  getRoleByLoginUserOffice: (params) =>
      getRequest("/sys/officeUser/getRoleByLoginUserOffice", params),
};

const file = {
  // 统一上传图片 /sys/fileUpload/uploadImg/{subPath}
  fileUploadImg: (params) =>
      filesRequest("/sys/fileUpload/uploadImg/1", params),
};


const frontPage = {
  // 查询指标列表 /sys/frontPage/statis
  frontPageStatis: (params) =>
      postRequest("/sys/frontPage/statis", params),

  // 首页柱状图统计 /sys/frontPage/chartStatis
  frontPageChartStatis: (params) =>
      postRequest("/sys/frontPage/chartStatis", params),

  // 首页我的活动 /sys/frontPage/activesOfCUser
  frontPageActivesOfCUser: (params) =>
      postRequest("/sys/frontPage/activesOfCUser", params),

  // 首页我的任务 /sys/frontPage/tassksOfCUser
  frontPageTassksOfCUser: (params) =>
      postRequest("/sys/frontPage/tassksOfCUser", params),
};


const web = {
  // 查询用户列表--分页 /user/pageList
  webUserList: (params) => postBody("/bi/user/pageList", params),
  // 修改用户记录 /user/update
  webUserUpdate: (params) => postBody("/bi/user/update", params),
  // 重置密码 /resetPassword/{id}
  webUserResetPassword: (params) => postBody("/bi/user/resetPassword", params),
  // 查询充值列表--分页 /order/pageList
  orderList: (params) => postBody("/bi/order/pageList", params),
  // 查询聊天信息列表--分页 /talkRecord/pageList
  talkRecord: (params) => postBody("/bi/talkRecord/pageList", params),
  // 删除用户消息记录 /talkRecord/deleteTalkRecord
  deleteTalkRecord: (params) => postPath("/bi/talkRecord/deleteTalkRecord", params),
  // 删除用户头像
  deleteAvatar: (params) => postPath("/bi/user/deleteUserAvatar", params),
  // 查询主播申请列表--分页 /user/pageList
  anchorApplyList: (params) => postBody("/bi/anchorApply/pageList", params),
  // 后台管理审核主播申请列表--分页 /user/pageList
  anchorApplyListHistory: (params) => postBody("/bi/anchorApply/historyPageList", params),
  // 审核主播
  examineAnchor: (params) => postBody("/bi/anchorApply/examineAnchor", params),
  // 查询提成设置
  percentageList: (params) => postBody("/bi/percentage/pageList", params),
  //查询提成设置
  getPercentage: (params) => postPath("/bi/percentage/percentageList", params),
  // 消费列表--分页 /costInfo/pageList
  costList: (params) => postBody("/bi/costInfo/pageList", params),
  // 根绝登录用户查询用户列表--分页 /user/rolePageList
  rolePageList: (params) => postBody("/bi/user/rolePageList", params),
  // 提成设置添加/修改
  addPercentage: (params) => postBody("/bi/percentage/save", params),
  // 消费列表--分页 /costInfo/pageList
  videoCallList: (params) => postBody("/bi/costInfo/videoCallPageList", params),
  // 同步用户缓存
  synCache: (params) => postBody("/bi/user/synCache", params),
  // 修改性别
  updateGender: (params) => postBody("/bi/user/updateGender", params),
  // 修改角色
  updateRole: (params) => postBody("/bi/user/updateRole", params),
  // 绑定主播
  bindAnchor: (params) => postBody("/bi/user/bindAnchor", params),
  // 修改金币
  updateGold: (params) => postBody("/bi/user/updateGold", params),
  // 修改语音包
  updateTickCount: (params) => postBody("/bi/user/updateTickCount", params),
  // 修改免费语音包
  updateFreeTickCount: (params) => postBody("/bi/user/updateFreeTickCount", params),
  // 修改付费翻译包
  updateFormalTransCount: (params) => postBody("/bi/user/updateFormalTransCount", params),
  // 修改免费翻译包
  updateFreeTransCount: (params) => postBody("/bi/user/updateFreeTransCount", params),
  // 修改手机号
  updatePhone: (params) => postBody("/bi/user/updatePhone", params),
  // 查询操作日志--分页
  sysAdminLogList: (params) => postBody("/bi/log/pageList", params),
  // 查询动态列表--分页
  momentList: (params) => postBody("/bi/moment/pageList", params),
  // 删除用户动态内容
  deleteMoment: (params) => postPath("/bi/moment/deleteMoment", params),
  // 查询举报列表--分页
  reportInfoList: (params) => postBody("/bi/report/pageList", params),
  // 查询频道列表--分页
  agoraList: (params) => postBody("/bi/agora/pageList", params),
  // 删除频道
  deleteAgora: (params) => postPath("/bi/agora/deleteAgora", params),
  // 订单统计
  statistics: (params) => getRequest("/bi/order/statistics", params),
  //订单列表中的订单金额统计
  orderTotalInfo: (params) => postBody("/bi/order/orderTotal", params),
  // 消费列表--分页 /costInfo/pageList
  downloadCost: (params) => getRequest("/bi/costInfo/downloadCost", params),
  // 国家列表
  regionList: (params) => postBody("/bi/user/regionList", params),
  // 修改国家
  updateCountry: (params) => postBody("/bi/user/updateCountry", params),
  // 修改会员
  updateVip: (params) => postBody("/bi/user/updateVip", params),
  // 修改会员到期时间
  updateExpireTime: (params) => postBody("/bi/user/updateExpireTime", params),
  // 删除提成设置
  deletePercentage: (params) => postPath("/bi/percentage/deletePercentage", params),
  // 修改昵称
  updateName: (params) => postBody("/bi/user/updateName", params),
  // 主播查询充值列表--分页 /order/pageList
  orderListByAnchor: (params) => postBody("/bi/order/pageListByAnchor", params),
  //计算主播的收益和提成
  orderTotalByRole: (params) => postBody("/bi/order/orderTotalByRole", params),
  // 修改生日
  updateBirth: (params) => postBody("/bi/user/updateBirth", params),
  //根据id查询聊天内容
  getTalkRecordById: (params) => postPath("/bi/talkRecord/getTalkRecordById", params),
  //设置星级
  updateStarLevel: (params) => postRequest("/bi/anchor/updateStarLevel", params),
  //统计消费列表的支出金额
  costTotalInfo: (params) => postBody("/bi/costInfo/costTotal", params),
  // 主播角色消费列表--分页
  costListByAnchor: (params) => postBody("/bi/costInfo/pageListByAnchor", params),
  // 主播角色消费统计
  costTotalByAnchor: (params) => postBody("/bi/costInfo/costTotalByAnchor", params),
};

const agent = {
  // 删除组织记录--逻辑--单条 /sys/role/del/{id}
  agentDel: (params) => postPath("/bi/agent/del", params),
  // 查询组织记录--详情 /sys/role/get/{id}
  agentGet: (params) => postPath("/bi/agent/get", params),
  // 查询组织列表--分页
  findAgentPage: (params) => postBody("/bi/agent/findAgentPage", params),
  // 查询组织列表--分页
  findGuildPage: (params) => postBody("/bi/agent/findGuildPage", params),
  // 删除组织记录--物理--单条 /sys/role/relDel/{id}
  agentRelDel: (params) => postPath("/bi/agent/relDel", params),
  // 修改组织记录
  agentSave: (params) => postBody("/bi/agent/save", params),
  // 修改角色记录
  agentUpdate: (params) => postBody("/bi/agent/update", params),
  //机构重置密码
  agentResetPassword: (params) => postPath("/bi/agent/resetPassword", params),
};

const guild = {
  // 删除组织记录--逻辑--单条 /sys/role/del/{id}
  guildDel: (params) => postPath("/bi/guild/del", params),
  // 查询组织记录--详情 /sys/role/get/{id}
  guildGet: (params) => postPath("/bi/guild/get", params),
  // 查询组织列表--分页
  findGuildPage: (params) => postBody("/bi/guild/findGuildPage", params),
  // 删除组织记录--物理--单条 /sys/role/relDel/{id}
  guildRelDel: (params) => postPath("/bi/guild/relDel", params),
  // 修改组织记录
  guildSave: (params) => postBody("/bi/guild/save", params),
  // 修改角色记录
  guildUpdate: (params) => postBody("/bi/guild/update", params),
  //机构重置密码
  guildResetPassword: (params) => postPath("/bi/guild/resetPassword", params),
  //机构重置密码
  getAgentList: (params) => postPath("/bi/guild/getAgentList", params),
};


const anchor = {
  // 删除组织记录--逻辑--单条 /sys/role/del/{id}
  anchorDel: (params) => postPath("/bi/anchor/del", params),
  // 查询组织记录--详情 /sys/role/get/{id}
  anchorGet: (params) => postPath("/bi/anchor/get", params),
  // 查询组织列表--分页
  findAnchorPage: (params) => postBody("/bi/anchor/findAnchorPage", params),
  // 删除组织记录--物理--单条 /sys/role/relDel/{id}
  anchorRelDel: (params) => postPath("/bi/anchor/relDel", params),
  // 修改组织记录
  anchorAdd: (params) => postBody("/bi/anchor/add", params),
  //机构重置密码
  anchorResetPassword: (params) => postPath("/bi/anchor/resetPassword", params),
  //获取主播类型列表
  getPercentageList: (params) => postPath("/bi/percentage/percentageList", params),
  //解约
  anchorRelieve: (params) => postPath("/bi/anchor/relieve", params),
  //修改主播类型
  anchorModifyType: (params) => postBody("/bi/anchor/modifyType", params),
  //绑定培训师
  bindTrain: (params) => postRequest("/bi/anchor/bindTrain", params),
  //绑定培训师
  bindTrainWithLoginName: (params) => postRequest("/bi/anchor/bindTrainWithLoginName", params),
  //解绑培训师
  unbindTrainWithLoginName: (params) => postRequest("/bi/anchor/unBindTrainWithLoginName", params),
  //获取工会列表
  getGuildList: (params) => postPath("/bi/guild/guildList", params),
  // 主播收益汇总--分页
  findAnchorSummaryPage: (params) => postBody("/bi/anchorSummary/pageList", params),
};


// ip黑名单
const blackIp = {
  pageBlackIp: (params) => postBody("/bi/blackIp/pageList", params),

  blackIpDel: (params) => postPath("/bi/blackIp/del", params),

  blackIpUpdate: (params) => postRequest("/bi/blackIp/update", params),
  // 查询拉黑列表--分页
  blackList: (params) => postBody("/bi/blackList/pageList", params),
};

// 礼物
const gift = {
  pageGift: (params) => postBody("/bi/gift/pageList", params),

  giftDel: (params) => postPath("/bi/gift/del", params),

  giftUpdate: (params) => postBody("/bi/gift/update", params),
};

// 礼物
const sysMsg = {
  pageSysMsg: (params) => postBody("/bi/sysMsg/pageList", params),

  sysMsgDel: (params) => postPath("/bi/sysMsg/del", params),

  sysMsgUpdate: (params) => postBody("/bi/sysMsg/update", params),
};


// 视频
const video = {
  pageVideo: (params) => postBody("/bi/video/pageList", params),

  videoDel: (params) => postPath("/bi/video/del", params),

  videoUpdate: (params) => postBody("/bi/video/update", params),
};

const smsSetting = {
  //设置区域优先短信发送代理
  regionAgentSet: (params) => getRequest("/bi/sms/setAgentQueueOfRegion", params),
  //查询发送代理
  regionAgentGet: (params) => getRequest("/bi/sms/getAgentQueueOfRegion", params),
  //查询发送代理
  regionAgentClear: (params) => getRequest("/bi/sms/clearSmsAgentQueue", params),
  //查询发送代理
  regionAgentReal: (params) => getRequest("/bi/sms/querySmsAgentQueue", params),
  //删除代理
  removeSmsAgent: (params) => getRequest("/bi/sms/removeSmsAgent", params),

};

export const api = {
  ...login,
  ...dict,
  ...menu,
  ...role,
  ...office,
  ...user,
  ...officeUser,
  ...file,
  ...frontPage,
  ...web,
  ...agent,
  ...guild,
  ...smsSetting,
  ...anchor,
  ...blackIp,
  ...video,
  ...sysMsg,
  ...gift
};
