export function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    }
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + ''
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
        }
    }
    return fmt
}
// export function TM2UTC(val) {
//     if(!val) {
//         return ''
//     }
//     const date = new Date(val)
//   const yyyy = date.getFullYear()
//   const MM = date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
//   const dd = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
//   const hh = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
//   const mm = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
//   const ss = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()
//     return yyyy + '-' + MM + '-' + dd + ' ' + hh + ':' + mm + ':' + ss
// }
export function TM2UTC(val) {
    if(!val) {
        return ''
    }
    const date = new Date(val)
    const yyyy = date.getUTCFullYear()
    const MM = date.getUTCMonth() > 8 ? date.getUTCMonth() + 1 : '0' + (date.getUTCMonth() + 1)
    const dd = date.getUTCDate() > 9 ? date.getUTCDate() : '0' + date.getUTCDate()
    const hh = date.getUTCHours() > 9 ? date.getUTCHours() : '0' + date.getUTCHours()
    const mm = date.getUTCMinutes() > 9 ? date.getUTCMinutes() : '0' + date.getUTCMinutes()
    const ss = date.getUTCSeconds() > 9 ? date.getUTCSeconds() : '0' + date.getUTCSeconds()
    return yyyy + '-' + MM + '-' + dd + ' ' + hh + ':' + mm + ':' + ss
}

function padLeftZero(str) {
    return ('00' + str).substr(str.length)
}